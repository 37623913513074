import React from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import { getProductList } from '../queries/page';
import ProductsSkeleton from '../components/products-skeleton';
import ProductCol from '../components/product-col';
import { transformProductList } from '../queries/transform';

const SectionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 40px 0px 0px 0px;
  color: #134E8F;
`;

const SubSectionTitle = styled.h1`
  font-size: 16px !important;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 0px 20px 0px;
  color: #909090;
`;

const SubcategoryPage = ({id, locale}) => {
  const {data, isFetching, error} = useQuery(
    ['getProductList', id],
    () => getProductList(id),
    {select: transformProductList}
  )

  if (error) return <p><FormattedMessage id='error' /></p>;

  return (
    <Layout locale={locale}>
      <Wrapper style={{ minHeight: '50vh' }}>
        <SectionTitle>
          {(!data || !data.length)
            ? <FormattedMessage id='category' />
            : locale === 'en' && data[0].category.engName ? data[0].category.engName : data[0].category.name}
        </SectionTitle>
        <SubSectionTitle>
          {(!data || !data.length)
            ? ''
            : locale === 'en' && data[0].subcategory.engName ? data[0].subcategory.engName : data[0].subcategory.name}
        </SubSectionTitle>
        <Grid fluid>
          {!isFetching ? <Row>
            {data && data.length ? data.map(product => (
              <ProductCol key={product._id}>
                <ProductCard locale={locale} data={product} />
              </ProductCol>
            )) : <p><FormattedMessage id='noData' /></p>}
          </Row> : <ProductsSkeleton large />}
        </Grid>
      </Wrapper>
    </Layout>
  );
}

export default connect(state => ({
  // data: state.page.product,
  data: state.page.productSubcategory,
  loading: state.page.fetchProductSubcategory,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getProductList,
})(SubcategoryPage);
