import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const Wrapper = styled.div`
  position: fixed;
  right: 10px;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Button = styled.a`
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 20px;
  background: white;
  border: 2px solid #134E8F;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  color: #134E8F;
  transition: all 0.3s;
  :hover {
    background: #134E8F;
    color: white;
  }
  ::after {
    display: none;
  }
`;

const Social = ({ data }) => (
  <Wrapper>
    {data && data.map(item => (
      <Button key={item._id} target='__blank' href={item.url}>
        <Icon type={item.icon} />
      </Button>
    ))}
  </Wrapper>
);

export default Social;
