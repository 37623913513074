import React, { Component } from 'react';
import {
  Input,
  Button,
  Table,
  // Avatar,
  Divider,
  Form,
  Modal,
  Select,
  message,
  Tabs,
} from 'antd';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  getProductCategoryList,
  getProductSubcategoryList,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
  createProductSubcategory,
  updateProductSubcategory,
  deleteProductSubcategory,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
// import { API_URL } from '../../utils/service';

class ProductCategory extends Component {
  state = {
    categorySelectedRowKeys: [],
    showCategoryModal: false,
    categoryModalType: 'Tạo mới',
    subcategorySelectedRowKeys: [],
    showSubcategoryModal: false,
    subcategoryModalType: 'Tạo mới',
    showSubcategoryMedia: false,
  }

  componentDidMount() {
    this.props.getProductCategoryList();
    this.props.getProductSubcategoryList();
  }

  onCategorySelectChange = (categorySelectedRowKeys) => {
    this.setState({ categorySelectedRowKeys });
  }

  onSubcategorySelectChange = (subcategorySelectedRowKeys) => {
    this.setState({ subcategorySelectedRowKeys });
  }

  showCategoryModal = categoryModalType => this.setState({
    showCategoryModal: true,
    categoryModalType,
  })

  hideCategoryModal = () => {
    this.setState({ showCategoryModal: false });
    this.props.form.resetFields();
  }

  handleSubmitCategory = (e) => {
    e.preventDefault();
    const { categoryModalType } = this.state;
    const { form, createProductCategory, updateProductCategory, deleteProductCategory } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (categoryModalType === 'Tạo mới') {
          createProductCategory(values);
        } else if (categoryModalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateProductCategory(id, values);
        } else if (categoryModalType === 'Xoá') {
          deleteProductCategory(values._id);
        }
        this.setState({ showCategoryModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEditCategory = record => {
    this.props.form.setFieldsValue(record);
    this.showCategoryModal('Chỉnh sửa');
  }

  handleDeleteCategory = record => {
    this.props.form.setFieldsValue(record);
    this.showCategoryModal('Xoá');
  }

  showSubcategoryModal = subcategoryModalType => this.setState({
    showSubcategoryModal: true,
    subcategoryModalType,
  })

  hideSubcategoryModal = () => {
    this.setState({ showSubcategoryModal: false });
    this.props.form.resetFields();
  }

  handleSubmitSubcategory = (e) => {
    e.preventDefault();
    const { subcategoryModalType } = this.state;
    const { form, createProductSubcategory, updateProductSubcategory, deleteProductSubcategory } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (subcategoryModalType === 'Tạo mới') {
          createProductSubcategory(values);
        } else if (subcategoryModalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateProductSubcategory(id, values);
        } else if (subcategoryModalType === 'Xoá') {
          deleteProductSubcategory(values._id);
        }
        this.setState({ showSubcategoryModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEditSubcategory = record => {
    this.props.form.setFieldsValue({
      ...record,
      category: record.category._id,
    });
    this.showSubcategoryModal('Chỉnh sửa');
  }

  handleDeleteSubcategory = record => {
    this.props.form.setFieldsValue({
      ...record,
      category: record.category._id,
    });
    this.showSubcategoryModal('Xoá');
  }

  render() {
    const { categories, subcategories, form: { getFieldDecorator, setFieldsValue } } = this.props;
    console.log(categories)
    const {
      categorySelectedRowKeys,
      showCategoryModal,
      categoryModalType,
      subcategorySelectedRowKeys,
      showSubcategoryModal,
      subcategoryModalType,
      showSubcategoryMedia,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Layout path='/admin/product-category'>
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 0 }}>
          <Tabs.TabPane tab="Danh mục cha" key="1">
            <Modal
              title={`${categoryModalType} danh mục cha`}
              visible={showCategoryModal}
              onOk={this.handleSubmitCategory}
              onCancel={this.hideCategoryModal}
              footer={[
                <Button key="back" onClick={this.hideCategoryModal}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmitCategory}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmitCategory}>
                {getFieldDecorator('_id')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Tên danh mục"
                >
                  {getFieldDecorator('name', {
                    rules: [{
                      required: true, message: 'Vui lòng nhập tên danh mục!',
                    }],
                  })(
                    <Input disabled={categoryModalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Tên danh mục (Tiếng Anh)"
                >
                  {getFieldDecorator('engName')(
                    <Input disabled={categoryModalType === 'Xoá'} />
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Paper
              title='Danh mục cha'
              extra={<div>
                <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
                <Button onClick={() => this.showCategoryModal('Tạo mới')} type='primary'>Tạo mới</Button>
              </div>}
            >
              <Table
                bordered
                rowKey={record => record._id}
                rowSelection={{
                  categorySelectedRowKeys,
                  onChange: this.onCategorySelectChange,
                }}
                columns={[{
                //   title: 'Ảnh',
                //   dataIndex: 'featuredImage',
                //   key: 'featuredImage',
                //   width: 100,
                //   render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/${data}`} />,
                // }, {
                  title: 'Tên danh mục',
                  dataIndex: 'name',
                  key: 'name',
                  render: (text, record) => <a target='__blank' href={`/category/${record._id}`}>{text}</a>,
                }, {
                  title: 'Người tạo',
                  dataIndex: 'createdBy.fullname',
                  key: 'createdBy',
                  render: data => <a>{data}</a>,
                }, {
                  title: 'Hành động',
                  key: 'action',
                  width: 185,
                  render: (record) => (
                    <span>
                      <a role='button' onClick={() => this.handleEditCategory(record)}>Sửa</a>
                      <Divider type="vertical" />
                      <a role='button' onClick={() => this.handleDeleteCategory(record)}>Xoá</a>
                      <Divider type="vertical" />
                      <CopyToClipboard text={`/category/${record._id}`} onCopy={() => message.success('Copy URL thành công!')}>
                        <a>Copy URL</a>
                      </CopyToClipboard>
                    </span>
                  ),
                }]}
                dataSource={categories}
              />
            </Paper>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Danh mục con" key="2">
            <Media
              visible={showSubcategoryMedia}
              onCancel={() => this.setState({ showSubcategoryMedia: false })}
              onSubmit={file => {
                setFieldsValue({ featuredImage: file.filename });
                this.setState({ showSubcategoryMedia: false });
              }}
            />
            <Modal
              title={`${subcategoryModalType} danh mục con`}
              visible={showSubcategoryModal}
              onOk={this.handleSubmitSubcategory}
              onCancel={this.hideSubcategoryModal}
              footer={[
                <Button key="back" onClick={this.hideSubcategoryModal}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmitSubcategory}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmitSubcategory}>
                {getFieldDecorator('_id')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Tên danh mục"
                >
                  {getFieldDecorator('name', {
                    rules: [{
                      required: true, message: 'Vui lòng nhập tên danh mục!',
                    }],
                  })(
                    <Input disabled={subcategoryModalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Tên danh mục (Tiếng Anh)"
                >
                  {getFieldDecorator('engName')(
                    <Input disabled={subcategoryModalType === 'Xoá'} />
                  )}
                </Form.Item>
                {/* <Form.Item
                  {...formItemLayout}
                  label='Hình ảnh'
                >
                  {getFieldDecorator('featuredImage')(
                    <Input onClick={() => this.setState({ showSubcategoryMedia: true })} disabled={subcategoryModalType === 'Xoá'} />
                  )}
                </Form.Item> */}
                <Form.Item
                  {...formItemLayout}
                  label='Danh mục cha'
                >
                  {getFieldDecorator('category', {
                    rules: [{
                      required: showSubcategoryModal, message: 'Vui lòng chọn danh mục cha!',
                    }],
                  })(
                    <Select>
                      {categories.map(item => (
                        <Select.Option key={item._id} value={item._id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Paper
              title='Danh mục con'
              extra={<div>
                <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
                <Button onClick={() => this.showSubcategoryModal('Tạo mới')} type='primary'>Tạo mới</Button>
              </div>}
            >
              <Table
                bordered
                rowKey={record => record._id}
                rowSelection={{
                  subcategorySelectedRowKeys,
                  onChange: this.onSubcategorySelectChange,
                }}
                columns={[{
                //   title: 'Ảnh',
                //   dataIndex: 'featuredImage',
                //   key: 'featuredImage',
                //   width: 100,
                //   render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/${data}`} />,
                // }, {
                  title: 'Tên',
                  dataIndex: 'name',
                  key: 'name',
                  render: (text, record) => <a target='__blank' href={`/subcategory/${record._id}`}>{text}</a>,
                }, {
                  title: 'Danh mục cha',
                  dataIndex: 'category.name',
                  key: 'category',
                }, {
                  title: 'Hành động',
                  key: 'action',
                  width: 185,
                  render: (record) => (
                    <span>
                      <a role='button' onClick={() => this.handleEditSubcategory(record)}>Sửa</a>
                      <Divider type="vertical" />
                      <a role='button' onClick={() => this.handleDeleteSubcategory(record)}>Xoá</a>
                      <Divider type="vertical" />
                      <CopyToClipboard text={`/subcategory/${record._id}`} onCopy={() => message.success('Copy URL thành công!')}>
                        <a>Copy URL</a>
                      </CopyToClipboard>
                    </span>
                  ),
                }]}
                dataSource={subcategories}
              />
            </Paper>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

export default connect(state => ({
  categories: state.product.categoryList,
  subcategories: state.product.subcategoryList,
}), {
  getProductCategoryList,
  getProductSubcategoryList,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
  createProductSubcategory,
  updateProductSubcategory,
  deleteProductSubcategory,
})(Form.create()(ProductCategory));
