import React, { Component, Fragment } from 'react';
import {
  Input, Button, Table, Divider, Form, Modal,
} from 'antd';
import { connect } from 'react-redux';

import {
  getTopMenuList,
  createTopMenu,
  updateTopMenu,
  deleteTopMenu,
} from '../../../actions';
import { Paper } from '../../../layouts/admin';

class TopMenu extends Component {
  state = {
    showModal: false,
    modalType: 'Tạo mới',
    showMedia: false,
  }

  componentDidMount() {
    this.props.getTopMenuList();
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createTopMenu, updateTopMenu, deleteTopMenu } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (modalType === 'Tạo mới') {
          createTopMenu(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateTopMenu(id, values);
        } else if (modalType === 'Xoá') {
          deleteTopMenu(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Xoá');
  }

  render() {
    const { list, form: { getFieldDecorator } } = this.props;
    const {
      showModal,
      modalType,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Modal
          title={`${modalType} trình đơn`}
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tên hiển thị"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tên hiển thị!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên hiển thị (Tiếng Anh)"
            >
              {getFieldDecorator('engTitle')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết (URL)"
            >
              {getFieldDecorator('url', {
                rules: [{
                  required: true, message: 'Vui lòng nhập liên kết!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: 'calc(100vh - 230px)' }}
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            columns={[{
              title: 'Tên hiển thị',
              dataIndex: 'title',
              key: 'title',
              render: (text, record) => <a href={record.url} target='__blank'>{text}</a>,
            }, {
              title: 'Liên kết URL',
              dataIndex: 'url',
              key: 'url',
            }, {
              title: 'Hành động',
              key: 'action',
              width: 120,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(state => ({
  list: state.home.topMenuList,
}), {
  getTopMenuList,
  createTopMenu,
  updateTopMenu,
  deleteTopMenu,
})(Form.create()(TopMenu));
