import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const Inner = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 0px 15px;
`;

const Wrapper = ({ children, wrapperStyle, ...props }) => (
  <Container style={wrapperStyle}>
    <Inner {...props}>
      {children}
    </Inner>
  </Container>
);

export default Wrapper;
