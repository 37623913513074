import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_CONTACT_LIST_REQUEST = 'get-contact-list-request';
export const GET_CONTACT_LIST_SUCCESS = 'get-contact-list-success';
export const GET_CONTACT_LIST_FAILURE = 'get-contact-list-failure';
export const CREATE_CONTACT_REQUEST = 'create-contact-request';
export const CREATE_CONTACT_SUCCESS = 'create-contact-success';
export const CREATE_CONTACT_FAILURE = 'create-contact-failure';
export const UPDATE_CONTACT_REQUEST = 'update-contact-request';
export const UPDATE_CONTACT_SUCCESS = 'update-contact-success';
export const UPDATE_CONTACT_FAILURE = 'update-contact-failure';
export const DELETE_CONTACT_REQUEST = 'delete-contact-request';
export const DELETE_CONTACT_SUCCESS = 'delete-contact-success';
export const DELETE_CONTACT_FAILURE = 'delete-contact-failure';

export function getContactList() {
  return async dispatch => {
    dispatch({ type: GET_CONTACT_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getContactList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_CONTACT_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_CONTACT_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_CONTACT_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createContact(data) {
  return async dispatch => {
    dispatch({ type: CREATE_CONTACT_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createContact,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_CONTACT_SUCCESS, payload: result.data });
        message.success('Tạo thông tin liên hệ thành công');
      } else {
        dispatch({ type: CREATE_CONTACT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_CONTACT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateContact(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_CONTACT_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateContact + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_CONTACT_SUCCESS, payload: result.data });
        message.success('Cập nhật thông tin liên hệ thành công');
      } else {
        dispatch({ type: UPDATE_CONTACT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_CONTACT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteContact(id) {
  return async dispatch => {
    dispatch({ type: DELETE_CONTACT_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteContact + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_CONTACT_SUCCESS, payload: result.data });
        message.success('Xoá thông tin liên hệ thành công');
      } else {
        dispatch({ type: DELETE_CONTACT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_CONTACT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
