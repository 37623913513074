import {
  // GET_ARTICLE_CATEGORY_LIST_FAILURE,
  // GET_ARTICLE_CATEGORY_LIST_REQUEST,
  GET_ARTICLE_CATEGORY_LIST_SUCCESS,
  // CREATE_ARTICLE_CATEGORY_FAILURE,
  // CREATE_ARTICLE_CATEGORY_REQUEST,
  CREATE_ARTICLE_CATEGORY_SUCCESS,
  // UPDATE_ARTICLE_CATEGORY_FAILURE,
  // UPDATE_ARTICLE_CATEGORY_REQUEST,
  UPDATE_ARTICLE_CATEGORY_SUCCESS,
  // DELETE_ARTICLE_CATEGORY_FAILURE,
  // DELETE_ARTICLE_CATEGORY_REQUEST,
  DELETE_ARTICLE_CATEGORY_SUCCESS,
  // GET_ARTICLE_LIST_FAILURE,
  // GET_ARTICLE_LIST_REQUEST,
  GET_ARTICLE_LIST_SUCCESS,
  // CREATE_ARTICLE_FAILURE,
  // CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  // UPDATE_ARTICLE_FAILURE,
  // UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  // DELETE_ARTICLE_FAILURE,
  // DELETE_ARTICLE_REQUEST,
  DELETE_ARTICLE_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  categoryList: [],
};

const articleReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.articles,
      };
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.payload._id),
      };
    case GET_ARTICLE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
      };
    case CREATE_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: [
          action.payload,
          ...state.categoryList,
        ],
      };
    case UPDATE_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: state.categoryList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: state.categoryList.filter(item => item._id !== action.payload._id),
      };
    default:
      return state;
  }
};

export default articleReducers;