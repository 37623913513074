import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_HOME_SECTION_BANNER_LIST_REQUEST = 'get-home-section-banner-list-request';
export const GET_HOME_SECTION_BANNER_LIST_SUCCESS = 'get-home-section-banner-list-success';
export const GET_HOME_SECTION_BANNER_LIST_FAILURE = 'get-home-section-banner-list-failure';
export const UPDATE_HOME_SECTION_BANNER_REQUEST = 'update-home-section-banner-request';
export const UPDATE_HOME_SECTION_BANNER_SUCCESS = 'update-home-section-banner-success';
export const UPDATE_HOME_SECTION_BANNER_FAILURE = 'update-home-section-banner-failure';
export const GET_BANNER_LIST_REQUEST = 'get-banner-list-request';
export const GET_BANNER_LIST_SUCCESS = 'get-banner-list-success';
export const GET_BANNER_LIST_FAILURE = 'get-banner-list-failure';
export const CREATE_BANNER_REQUEST = 'create-banner-request';
export const CREATE_BANNER_SUCCESS = 'create-banner-success';
export const CREATE_BANNER_FAILURE = 'create-banner-failure';
export const UPDATE_BANNER_REQUEST = 'update-banner-request';
export const UPDATE_BANNER_SUCCESS = 'update-banner-success';
export const UPDATE_BANNER_FAILURE = 'update-banner-failure';
export const DELETE_BANNER_REQUEST = 'delete-banner-request';
export const DELETE_BANNER_SUCCESS = 'delete-banner-success';
export const DELETE_BANNER_FAILURE = 'delete-banner-failure';

export function getHomeSectionBannerList() {
  return async dispatch => {
    dispatch({ type: GET_HOME_SECTION_BANNER_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getHomeSectionBannerList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_HOME_SECTION_BANNER_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_HOME_SECTION_BANNER_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_HOME_SECTION_BANNER_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateHomeSectionBanner(data) {
  return async dispatch => {
    dispatch({ type: UPDATE_HOME_SECTION_BANNER_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.updateHomeSectionBanner,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_HOME_SECTION_BANNER_SUCCESS });
        dispatch(getHomeSectionBannerList());
        message.success('Cập nhật banner thành công');
      } else {
        dispatch({ type: UPDATE_HOME_SECTION_BANNER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_HOME_SECTION_BANNER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getBannerList() {
  return async dispatch => {
    dispatch({ type: GET_BANNER_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getBannerList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_BANNER_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_BANNER_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_BANNER_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createBanner(data) {
  return async dispatch => {
    dispatch({ type: CREATE_BANNER_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createBanner,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_BANNER_SUCCESS, payload: result.data });
        message.success('Tạo banner thành công');
      } else {
        dispatch({ type: CREATE_BANNER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_BANNER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateBanner(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_BANNER_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateBanner + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_BANNER_SUCCESS, payload: result.data });
        message.success('Cập nhật banner thành công');
      } else {
        dispatch({ type: UPDATE_BANNER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_BANNER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteBanner(id) {
  return async dispatch => {
    dispatch({ type: DELETE_BANNER_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteBanner + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_BANNER_SUCCESS, payload: result.data });
        message.success('Xoá banner thành công');
      } else {
        dispatch({ type: DELETE_BANNER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_BANNER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
