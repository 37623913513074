import { message } from 'antd';
import { query, ENDPOINTS } from '../utils/service';

export const LOGIN_SUCCESS = 'login-success';
export const LOGIN_FAILURE = 'login-failure';
export const LOGIN_REQUEST = 'login-request';
export const REGISTER_SUCCESS = 'register-success';
export const REGISTER_FAILURE = 'register-failure';
export const REGISTER_REQUEST = 'register-request';
export const LOGOUT = 'logout';
export const GET_USER_LIST_SUCCESS = 'get-user-list-success';
export const GET_USER_LIST_FAILURE = 'get-user-list-failure';
export const GET_USER_LIST_REQUEST = 'get-user-list-request';
export const CREATE_USER_SUCCESS = 'create-user-success';
export const CREATE_USER_FAILURE = 'create-user-failure';
export const CREATE_USER_REQUEST = 'create-user-request';
export const UPDATE_USER_SUCCESS = 'update-user-success';
export const UPDATE_USER_FAILURE = 'update-user-failure';
export const UPDATE_USER_REQUEST = 'update-user-request';
export const DELETE_USER_SUCCESS = 'delete-user-success';
export const DELETE_USER_FAILURE = 'delete-user-failure';
export const DELETE_USER_REQUEST = 'delete-user-request';

export function login(data) {
  return async dispatch => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.login,
      });
      if (result.status === 200) {
        dispatch({ type: LOGIN_SUCCESS, payload: result.data });
        message.success('Đăng nhập thành công');
      } else {
        dispatch({ type: LOGIN_FAILURE });
        message.error('Đăng nhập thất bại');
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      message.error('Đăng nhập thất bại');
    }
  };
}

export function getUserList() {
  return async dispatch => {
    dispatch({ type: GET_USER_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getUserList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_USER_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_USER_LIST_FAILURE });
      }
    } catch (error) {
      dispatch({ type: GET_USER_LIST_FAILURE });
    }
  };
}

export function createUser(data) {
  return async dispatch => {
    dispatch({ type: CREATE_USER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.createUser,
        data,
        method: 'POST',
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_USER_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: CREATE_USER_FAILURE });
      }
    } catch (error) {
      dispatch({ type: CREATE_USER_FAILURE });
    }
  };
}

export function registerUser(data, callback) {
  return async dispatch => {
    dispatch({ type: REGISTER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.registerUser,
        data,
        method: 'POST',
      });
      if (result.status === 201) {
        dispatch({ type: REGISTER_SUCCESS, payload: result.data });
        message.success('Đăng ký thành công, vui lòng đăng nhập để tiếp tục!');
        callback();
      } else {
        dispatch({ type: REGISTER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: REGISTER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateUser(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.updateUser + id,
        data,
        method: 'PATCH',
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: result.data });
        message.success('Cập nhật thành công');
      } else {
        dispatch({ type: UPDATE_USER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_USER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteUser(id) {
  return async dispatch => {
    dispatch({ type: DELETE_USER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.deleteUser + id,
        method: 'DELETE',
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_USER_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: DELETE_USER_FAILURE });
      }
    } catch (error) {
      dispatch({ type: DELETE_USER_FAILURE });
    }
  };
}

export function logout() {
  message.success('Đăng xuất thành công');
  return {
    type: LOGOUT,
  };
}
