import React, { Component, Fragment } from 'react';
import {
  Input, Button, Table, Divider, Form, Modal, Icon,
} from 'antd';
import { connect } from 'react-redux';

import {
  getFooterMenuList,
  createFooterMenu,
  updateFooterMenu,
  deleteFooterMenu,
} from '../../../actions';
import { Paper } from '../../../layouts/admin';

class FooterMenu extends Component {
  state = {
    showModal: false,
    modalType: 'Tạo mới',
    showLinkModal: false,
    linkModalType: 'Tạo mới',
    showMedia: false,
    links: [],
    selectedLink: {},
  }

  componentDidMount() {
    this.props.getFooterMenuList();
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false, links: [], selectedLink: {} });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType, links } = this.state;
    const { form, createFooterMenu, updateFooterMenu, deleteFooterMenu } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      values.links = links; // eslint-disable-line
      if (!err) {
        if (modalType === 'Tạo mới') {
          createFooterMenu(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateFooterMenu(id, values);
        } else if (modalType === 'Xoá') {
          deleteFooterMenu(values._id);
        }
        this.setState({ showModal: false, links: [] });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ links: record.links });
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ links: record.links });
    this.showModal('Xoá');
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ links: record.links });
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ links: record.links });
    this.showModal('Xoá');
  }

  handleSubmitChildren = () => {
    const { linkModalType, links, selectedLink } = this.state;

    if (linkModalType === 'Tạo mới') {
      this.setState({
        links: [...links, selectedLink],
        selectedLink: {},
        showLinkModal: false,
      });
    } else if (linkModalType === 'Chỉnh sửa') {
      this.setState({
        links: links.map(c => (c.name === selectedLink.name ? selectedLink : c)),
        selectedLink: {},
        showLinkModal: false,
      });
    } else {
      this.setState({
        links: links.filter(c => c.name !== selectedLink.name),
        selectedLink: {},
        showLinkModal: false,
      });
    }
  }

  render() {
    const { list, form: { getFieldDecorator } } = this.props;
    const {
      showModal,
      modalType,
      showLinkModal,
      selectedLink,
      linkModalType,
      links,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Modal
          title={`${modalType} liên kết`}
          visible={showLinkModal}
          centered
          okOk={this.handleSubmitChildren}
          onCancel={() => this.setState({ selectedLink: {}, showLinkModal: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ selectedLink: {}, showLinkModal: false })}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmitChildren}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmitChildren}>
            <Form.Item {...formItemLayout} label='Tên hiển thị'>
              <Input
                disabled={linkModalType === 'Xoá'}
                value={selectedLink.name}
                onChange={e => this.setState({ selectedLink: { ...selectedLink, name: e.target.value } })}
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label='Tên hiển thị (Tiếng Anh)'>
              <Input
                disabled={linkModalType === 'Xoá'}
                value={selectedLink.engName}
                onChange={e => this.setState({ selectedLink: { ...selectedLink, engName: e.target.value } })}
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label='URL liên kết'>
              <Input
                disabled={linkModalType === 'Xoá'}
                value={selectedLink.url}
                onChange={e => this.setState({ selectedLink: { ...selectedLink, url: e.target.value } })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={`${modalType} trình đơn`}
          visible={showModal}
          centered
          onOk={this.handleSubmit}
          width='50%'
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tiêu đề!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề (Tiếng Anh)"
            >
              {getFieldDecorator('engTitle')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết"
            >
              <Table
                columns={[
                  {
                    title: 'Tên hiển thị',
                    dataIndex: 'name',
                    key: 'name',
                  }, {
                    title: 'URL liên kết',
                    dataIndex: 'url',
                    key: 'url',
                  }, {
                    title: 'Hành động',
                    key: 'action',
                    render: (text, record) => modalType !== 'Xoá' && (
                      <span>
                        <a
                          role='button'
                          onClick={() => this.setState({ showLinkModal: true, selectedLink: record, linkModalType: 'Chỉnh sửa' })}
                        >Sửa</a>
                        <Divider type="vertical" />
                        <a
                          role='button'
                          onClick={() => this.setState({ showLinkModal: true, selectedLink: record, linkModalType: 'Xoá' })}
                        >Xoá</a>
                      </span>
                    ),
                  },
                ]}
                dataSource={links}
                pagination={false}
                size='small'
                bordered={false}
              />
              {modalType !== 'Xoá' && <Button type="dashed" onClick={() => this.setState({ showLinkModal: true, linkModalType: 'Tạo mới' })}>
                <Icon type="plus" /> Thêm liên kết
              </Button>}
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: 'calc(100vh - 230px)' }}
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            columns={[{
              title: 'Tên hiển thị',
              dataIndex: 'title',
              key: 'title',
              render: (text, record) => <a href={record.url} target='__blank'>{text}</a>,
            }, {
              title: 'Liên kết',
              dataIndex: 'links',
              key: 'links',
              render: (text, record) => record.links && <span>{record.links.length} liên kết</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 120,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(state => ({
  list: state.home.footerMenuList,
}), {
  getFooterMenuList,
  createFooterMenu,
  updateFooterMenu,
  deleteFooterMenu,
})(Form.create()(FooterMenu));
