import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_FOOTERMENU_LIST_REQUEST = 'get-footermenu-list-request';
export const GET_FOOTERMENU_LIST_SUCCESS = 'get-footermenu-list-success';
export const GET_FOOTERMENU_LIST_FAILURE = 'get-footermenu-list-failure';
export const CREATE_FOOTERMENU_REQUEST = 'create-footermenu-request';
export const CREATE_FOOTERMENU_SUCCESS = 'create-footermenu-success';
export const CREATE_FOOTERMENU_FAILURE = 'create-footermenu-failure';
export const UPDATE_FOOTERMENU_REQUEST = 'update-footermenu-request';
export const UPDATE_FOOTERMENU_SUCCESS = 'update-footermenu-success';
export const UPDATE_FOOTERMENU_FAILURE = 'update-footermenu-failure';
export const DELETE_FOOTERMENU_REQUEST = 'delete-footermenu-request';
export const DELETE_FOOTERMENU_SUCCESS = 'delete-footermenu-success';
export const DELETE_FOOTERMENU_FAILURE = 'delete-footermenu-failure';

export const GET_SOCIAL_LINK_LIST_REQUEST = 'get-social-link-list-request';
export const GET_SOCIAL_LINK_LIST_SUCCESS = 'get-social-link-list-success';
export const GET_SOCIAL_LINK_LIST_FAILURE = 'get-social-link-list-failure';
export const CREATE_SOCIAL_LINK_REQUEST = 'create-social-link-request';
export const CREATE_SOCIAL_LINK_SUCCESS = 'create-social-link-success';
export const CREATE_SOCIAL_LINK_FAILURE = 'create-social-link-failure';
export const UPDATE_SOCIAL_LINK_REQUEST = 'update-social-link-request';
export const UPDATE_SOCIAL_LINK_SUCCESS = 'update-social-link-success';
export const UPDATE_SOCIAL_LINK_FAILURE = 'update-social-link-failure';
export const DELETE_SOCIAL_LINK_REQUEST = 'delete-social-link-request';
export const DELETE_SOCIAL_LINK_SUCCESS = 'delete-social-link-success';
export const DELETE_SOCIAL_LINK_FAILURE = 'delete-social-link-failure';

export const GET_TOPMENU_LIST_REQUEST = 'get-topmenu-list-request';
export const GET_TOPMENU_LIST_SUCCESS = 'get-topmenu-list-success';
export const GET_TOPMENU_LIST_FAILURE = 'get-topmenu-list-failure';
export const CREATE_TOPMENU_REQUEST = 'create-topmenu-request';
export const CREATE_TOPMENU_SUCCESS = 'create-topmenu-success';
export const CREATE_TOPMENU_FAILURE = 'create-topmenu-failure';
export const UPDATE_TOPMENU_REQUEST = 'update-topmenu-request';
export const UPDATE_TOPMENU_SUCCESS = 'update-topmenu-success';
export const UPDATE_TOPMENU_FAILURE = 'update-topmenu-failure';
export const DELETE_TOPMENU_REQUEST = 'delete-topmenu-request';
export const DELETE_TOPMENU_SUCCESS = 'delete-topmenu-success';
export const DELETE_TOPMENU_FAILURE = 'delete-topmenu-failure';

export const GET_PRODUCTMENU_LIST_REQUEST = 'get-productmenu-list-request';
export const GET_PRODUCTMENU_LIST_SUCCESS = 'get-productmenu-list-success';
export const GET_PRODUCTMENU_LIST_FAILURE = 'get-productmenu-list-failure';
export const CREATE_PRODUCTMENU_REQUEST = 'create-productmenu-request';
export const CREATE_PRODUCTMENU_SUCCESS = 'create-productmenu-success';
export const CREATE_PRODUCTMENU_FAILURE = 'create-productmenu-failure';
export const UPDATE_PRODUCTMENU_REQUEST = 'update-productmenu-request';
export const UPDATE_PRODUCTMENU_SUCCESS = 'update-productmenu-success';
export const UPDATE_PRODUCTMENU_FAILURE = 'update-productmenu-failure';
export const DELETE_PRODUCTMENU_REQUEST = 'delete-productmenu-request';
export const DELETE_PRODUCTMENU_SUCCESS = 'delete-productmenu-success';
export const DELETE_PRODUCTMENU_FAILURE = 'delete-productmenu-failure';

export const GET_LOGO_LIST_REQUEST = 'get-logo-list-request';
export const GET_LOGO_LIST_SUCCESS = 'get-logo-list-success';
export const GET_LOGO_LIST_FAILURE = 'get-logo-list-failure';
export const CREATE_LOGO_REQUEST = 'create-logo-request';
export const CREATE_LOGO_SUCCESS = 'create-logo-success';
export const CREATE_LOGO_FAILURE = 'create-logo-failure';
export const UPDATE_LOGO_REQUEST = 'update-logo-request';
export const UPDATE_LOGO_SUCCESS = 'update-logo-success';
export const UPDATE_LOGO_FAILURE = 'update-logo-failure';
export const DELETE_LOGO_REQUEST = 'delete-logo-request';
export const DELETE_LOGO_SUCCESS = 'delete-logo-success';
export const DELETE_LOGO_FAILURE = 'delete-logo-failure';

export const GET_REVIEW_LIST_REQUEST = 'get-review-list-request';
export const GET_REVIEW_LIST_SUCCESS = 'get-review-list-success';
export const GET_REVIEW_LIST_FAILURE = 'get-review-list-failure';
export const CREATE_REVIEW_REQUEST = 'create-review-request';
export const CREATE_REVIEW_SUCCESS = 'create-review-success';
export const CREATE_REVIEW_FAILURE = 'create-review-failure';
export const UPDATE_REVIEW_REQUEST = 'update-review-request';
export const UPDATE_REVIEW_SUCCESS = 'update-review-success';
export const UPDATE_REVIEW_FAILURE = 'update-review-failure';
export const DELETE_REVIEW_REQUEST = 'delete-review-request';
export const DELETE_REVIEW_SUCCESS = 'delete-review-success';
export const DELETE_REVIEW_FAILURE = 'delete-review-failure';

export const GET_YOUTUBE_REQUEST = 'get-youtube-request';
export const GET_YOUTUBE_SUCCESS = 'get-youtube-success';
export const GET_YOUTUBE_FAILURE = 'get-youtube-failure';
export const CREATE_YOUTUBE_REQUEST = 'create-youtube-request';
export const CREATE_YOUTUBE_SUCCESS = 'create-youtube-success';
export const CREATE_YOUTUBE_FAILURE = 'create-youtube-failure';

export function getYoutube() {
  return async dispatch => {
    dispatch({ type: GET_YOUTUBE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getYoutube,
      });
      if (result.status === 200) {
        dispatch({ type: GET_YOUTUBE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_YOUTUBE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_YOUTUBE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createYoutube(data) {
  return async dispatch => {
    dispatch({ type: CREATE_YOUTUBE_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createYoutube,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_YOUTUBE_SUCCESS, payload: result.data });
        message.success('Đổi video thành công');
      } else {
        dispatch({ type: CREATE_YOUTUBE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_YOUTUBE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getTopMenuList() {
  return async dispatch => {
    dispatch({ type: GET_TOPMENU_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getTopMenuList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_TOPMENU_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_TOPMENU_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_TOPMENU_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createTopMenu(data) {
  return async dispatch => {
    dispatch({ type: CREATE_TOPMENU_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createTopMenu,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_TOPMENU_SUCCESS, payload: result.data });
        message.success('Tạo trình đơn thành công');
      } else {
        dispatch({ type: CREATE_TOPMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_TOPMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateTopMenu(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_TOPMENU_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateTopMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_TOPMENU_SUCCESS, payload: result.data });
        message.success('Cập nhật trình đơn thành công');
      } else {
        dispatch({ type: UPDATE_TOPMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_TOPMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteTopMenu(id) {
  return async dispatch => {
    dispatch({ type: DELETE_TOPMENU_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteTopMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_TOPMENU_SUCCESS, payload: result.data });
        message.success('Xoá trình đơn thành công');
      } else {
        dispatch({ type: DELETE_TOPMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_TOPMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getSocialLinkList() {
  return async dispatch => {
    dispatch({ type: GET_SOCIAL_LINK_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getSocialLinkList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_SOCIAL_LINK_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_SOCIAL_LINK_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_SOCIAL_LINK_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createSocialLink(data) {
  return async dispatch => {
    dispatch({ type: CREATE_SOCIAL_LINK_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createSocialLink,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_SOCIAL_LINK_SUCCESS, payload: result.data });
        message.success('Tạo liên kết thành công');
      } else {
        dispatch({ type: CREATE_SOCIAL_LINK_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_SOCIAL_LINK_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateSocialLink(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_SOCIAL_LINK_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateSocialLink + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_SOCIAL_LINK_SUCCESS, payload: result.data });
        message.success('Cập nhật liên kết thành công');
      } else {
        dispatch({ type: UPDATE_SOCIAL_LINK_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_SOCIAL_LINK_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteSocialLink(id) {
  return async dispatch => {
    dispatch({ type: DELETE_SOCIAL_LINK_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteSocialLink + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_SOCIAL_LINK_SUCCESS, payload: result.data });
        message.success('Xoá liên kết thành công');
      } else {
        dispatch({ type: DELETE_SOCIAL_LINK_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_SOCIAL_LINK_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getFooterMenuList() {
  return async dispatch => {
    dispatch({ type: GET_FOOTERMENU_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getFooterMenuList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_FOOTERMENU_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_FOOTERMENU_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_FOOTERMENU_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createFooterMenu(data) {
  return async dispatch => {
    dispatch({ type: CREATE_FOOTERMENU_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createFooterMenu,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_FOOTERMENU_SUCCESS, payload: result.data });
        message.success('Tạo trình đơn thành công');
      } else {
        dispatch({ type: CREATE_FOOTERMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_FOOTERMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateFooterMenu(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_FOOTERMENU_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateFooterMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_FOOTERMENU_SUCCESS, payload: result.data });
        message.success('Cập nhật trình đơn thành công');
      } else {
        dispatch({ type: UPDATE_FOOTERMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_FOOTERMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteFooterMenu(id) {
  return async dispatch => {
    dispatch({ type: DELETE_FOOTERMENU_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteFooterMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_FOOTERMENU_SUCCESS, payload: result.data });
        message.success('Xoá trình đơn thành công');
      } else {
        dispatch({ type: DELETE_FOOTERMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_FOOTERMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductMenuList() {
  return async dispatch => {
    dispatch({ type: GET_PRODUCTMENU_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductMenuList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCTMENU_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCTMENU_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCTMENU_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createProductMenu(data) {
  return async dispatch => {
    dispatch({ type: CREATE_PRODUCTMENU_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createProductMenu,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_PRODUCTMENU_SUCCESS, payload: result.data });
        message.success('Tạo trình đơn thành công');
      } else {
        dispatch({ type: CREATE_PRODUCTMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_PRODUCTMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateProductMenu(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_PRODUCTMENU_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateProductMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_PRODUCTMENU_SUCCESS, payload: result.data });
        message.success('Cập nhật trình đơn thành công');
      } else {
        dispatch({ type: UPDATE_PRODUCTMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_PRODUCTMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteProductMenu(id) {
  return async dispatch => {
    dispatch({ type: DELETE_PRODUCTMENU_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteProductMenu + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_PRODUCTMENU_SUCCESS, payload: result.data });
        message.success('Xoá trình đơn thành công');
      } else {
        dispatch({ type: DELETE_PRODUCTMENU_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_PRODUCTMENU_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getLogoList() {
  return async dispatch => {
    dispatch({ type: GET_LOGO_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getLogoList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_LOGO_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_LOGO_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_LOGO_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createLogo(data) {
  return async dispatch => {
    dispatch({ type: CREATE_LOGO_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createLogo,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_LOGO_SUCCESS, payload: result.data });
        message.success('Tạo thành công');
      } else {
        dispatch({ type: CREATE_LOGO_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_LOGO_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateLogo(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_LOGO_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateLogo + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_LOGO_SUCCESS, payload: result.data });
        message.success('Cập nhật thành công');
      } else {
        dispatch({ type: UPDATE_LOGO_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_LOGO_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteLogo(id) {
  return async dispatch => {
    dispatch({ type: DELETE_LOGO_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteLogo + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_LOGO_SUCCESS, payload: result.data });
        message.success('Xoá thành công');
      } else {
        dispatch({ type: DELETE_LOGO_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_LOGO_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getReviewList() {
  return async dispatch => {
    dispatch({ type: GET_REVIEW_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getReviewList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_REVIEW_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_REVIEW_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_REVIEW_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createReview(data) {
  return async dispatch => {
    dispatch({ type: CREATE_REVIEW_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createReview,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_REVIEW_SUCCESS, payload: result.data });
        message.success('Tạo thành công');
      } else {
        dispatch({ type: CREATE_REVIEW_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_REVIEW_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateReview(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_REVIEW_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateReview + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_REVIEW_SUCCESS, payload: result.data });
        message.success('Cập nhật thành công');
      } else {
        dispatch({ type: UPDATE_REVIEW_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_REVIEW_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteReview(id) {
  return async dispatch => {
    dispatch({ type: DELETE_REVIEW_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteReview + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_REVIEW_SUCCESS, payload: result.data });
        message.success('Xoá thành công');
      } else {
        dispatch({ type: DELETE_REVIEW_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_REVIEW_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
