import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'react-flexbox-grid';

const Wrapper = styled.div`
  min-height: 350px;
  width: 100%;
  margin-bottom: 15px;
  a {
    display: block;
    ::after {
      display: none;
    }
  }
`;

export const ArticleImage = styled.div`
  height: 240px;
  ::after {
    display: none;
  }
  cursor: pointer;
`;

const ArticleInfo = styled.div`
  padding: 10px;
`;

export const ArticleName = styled.div`
  height: 16px;
  margin-bottom: 7px;
  ::after {
    display: none;
  }
  :hover {
    color: #134E8F;
  }
`;

export const ArticleMeta = styled.div`
  font-size: 12px;
  height: 16px;
  font-weight: 500;
  color: #282828;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const ArticleSkeleton = ({ small }) => {
  const arr = small ? [0, 1, 2, 3] : [0, 1, 2, 3, 4, 5, 6];
  return (
    <Row>
      {arr.map(id => (
        <Col key={id} xs={12} md={6} xl={4}>
          <Wrapper>
            <ArticleImage>
              <ContentLoader
                speed={2}
                primaryColor="#f3f3f3"
                style={{ width: '100%', height: '100%' }}
                secondaryColor="#ecebeb"
              >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
              </ContentLoader>
            </ArticleImage>
            <ArticleInfo>
              <ArticleName>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '70%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </ArticleName>
              <ArticleName>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '50%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </ArticleName>
              <ArticleMeta>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '100%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </ArticleMeta>
            </ArticleInfo>
          </Wrapper>
        </Col>
      ))}
    </Row>
  );
};

export default ArticleSkeleton;
