import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import ProductsSkeleton from '../components/products-skeleton';
import { getProductPage } from '../actions';
import { media } from '../utils/media';
import ProductCol from '../components/product-col';

const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
`;

const SectionSubtitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  padding: 10px 0px 20px 0px;
  color: #606060;
  ${media.phablet`
    font-size: 18px;
  `};
`;

const MoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

class ProductPage extends Component {
  componentDidMount() {
    this.props.getProductPage();
  }

  render() {
    const { data, loading, error, locale } = this.props;

    if (error) return <p><FormattedMessage id='error' /></p>;

    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '100vh' }}>
        {!loading && data.sections ? data.sections.map(section => (
            <Fragment key={section.key}>
              <SectionTitle>{section.label}</SectionTitle>
              {section.items ? section.items.map(item => (
                <Fragment key={item.key}>
                  <SectionSubtitle>{item.label}</SectionSubtitle>
                  <Row>
                    {
                      item.products.map(product => (
                        <ProductCol key={product._id}>
                          <ProductCard locale={locale} data={product} />
                        </ProductCol>
                      ))
                    }
                  </Row>
                  {item.limit === item.products.length && (
                    <MoreWrapper>
                      <Button type='primary' onClick={() => navigate(`/section/${item.key.replace('.', '-')}`)}><FormattedMessage id='more' /></Button>
                    </MoreWrapper>
                  )}
                </Fragment>
              )) : (
                <Fragment>
                  <Row>
                    {
                      section.products.map(product => (
                        <ProductCol key={product.name}>
                          <ProductCard locale={locale} data={product} />
                        </ProductCol>
                      ))
                    }
                  </Row>
                  {section.limit === section.products.length && (
                    <MoreWrapper>
                      <Button type='primary' onClick={() => navigate(`/section/${section.key.replace('.', '-')}`)}><FormattedMessage id='more' /></Button>
                    </MoreWrapper>
                  )}
                </Fragment>
              )}
            </Fragment>
          )) : <ProductsSkeleton large />}
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.product,
  loading: state.page.loading,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getProductPage,
})(ProductPage);
