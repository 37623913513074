import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Wrapper from './wrapper';
import SlideArrow from './slide-arrow';
import { media } from '../utils/media';

import thienha from '../images/thienha.jpg';
import hometex from '../images/hometex.jpg';
import champa from '../images/champa.jpg';
import honrom from '../images/honrom.jpg';
import vinhthanh from '../images/vinhthanh.png';
import hoangnguyen from '../images/hoangnguyen.png';

const firstSub = [
  { id: 'hometex', image: hometex, url: '/' },
  { id: 'champa', image: champa, url: 'http://champaresort.com.vn/' },
  // { id: 'hoangthien', image: hoangthien, url: '/' },
  { id: 'vinhthanh', image: vinhthanh, url: '/' },
  { id: 'hoangnguyen', image: hoangnguyen, url: '/' },
  { id: 'honrom', image: honrom, url: 'http://honrom.vn/' },
];

const secondSub = [
  { id: 'hoangnguyen', image: hoangnguyen, url: '/' },
  { id: 'honrom', image: honrom, url: 'http://honrom.vn/' },
  { id: 'hometex', image: hometex, url: '/' },
  { id: 'champa', image: champa, url: 'http://champaresort.com.vn/' },
  { id: 'vinhthanh', image: vinhthanh, url: '/' },
  // { id: 'hoangthien', image: hoangthien, url: '/' },
];

const subStyle = {
  hoangthien: { height: 50, margin: '15px 0px' },
  // honrom: { margin: '0px 20px' },
  vinhthanh: { height: 28, margin: '27px 0px 20px 20px' },
  hoangnguyen: { height: 45, margin: '16px 10px 10px 0px' },
  champa: { margin: '32px 5px 0px' },
};

const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

const Item = styled.a`
  height: 70px;
  ${media.thone`
    height: 60px;
  `};
  ::after {
    display: none;
  }
`;

const Center = styled.a`
  height: 80px;
  width: 150px;
  padding: 10px;
  ${media.thone`
    height: 70px;
    padding: 8px;
  `};
  ::after {
    display: none;
  }
`;

const Image = styled.div`
  background-image: url(${props => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  margin: 30px 0px;
  ${media.thone`
    margin: 25px 0px 0px 25px;
  `};
`;

class Subsidiary extends Component {
  state = {
    nav1: null,
    nav2: null,
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <Wrapper>
        <Container>
          <Grid fluid>
            <Row>
              <Col lg={5} sm={4} xs={4} style={{ padding: '0 0 0 20px' }}>
                <Slider
                  dots={false}
                  slidesToShow={3}
                  infinite
                  asNavFor={this.state.nav2}
                  ref={slider => (this.slider1 = slider)}
                  autoplay
                  autoplaySpeed={4000}
                  nextArrow={null}
                  swipeToSlide
                  arrows
                  prevArrow={<SlideArrow type='prev' />}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {firstSub.map((item) => (
                    <Item key={item.id} target='__blank' href={item.url}>
                      <Image image={item.image} style={subStyle[item.id]} />
                    </Item>
                  ))}
                </Slider>
              </Col>
              <Col lg={2} sm={3} xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Center><Image image={thienha} style={{ height: 50, margin: '0px 0px' }} /></Center>
              </Col>
              <Col lg={5} sm={4} xs={4} style={{ padding: '0 20px 0 0' }}>
                <Slider
                  dots={false}
                  slidesToShow={3}
                  infinite
                  autoplay
                  autoplaySpeed={4000}
                  swipeToSlide
                  prevArrow={null}
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  arrows
                  nextArrow={<SlideArrow type='next' />}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {secondSub.map(item => (
                    <Item key={item.id} target='__blank' href={item.url}>
                      <Image image={item.image} style={subStyle[item.id]} />
                    </Item>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Grid>
        </Container>
      </Wrapper>
    );
  }
}

export default Subsidiary;
