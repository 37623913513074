import React from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js'
import { PostHogProvider} from 'posthog-js/react'

import App from './App';

posthog.init('phc_U8XA2bJQWm7uUPTPZ0AlR97YtCCmWicmzDfbuAw2jST', { api_host: 'https://us.i.posthog.com' })

ReactDOM.render(
  <PostHogProvider client={posthog}>
    <App />
  </PostHogProvider>
, document.getElementById('root'));