import React, { Component } from 'react';
import styled from 'styled-components';
import { Location, navigate } from '@reach/router';
import { MdShoppingCart, MdSearch } from 'react-icons/md';
import { Popover, List, Avatar, Button, Icon, Menu as AntMenu, Input } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Wrapper from './wrapper';
import logo from '../images/hometex.jpg';
import { CDN_URL } from '../utils/service';
import { removeFromCart, changeSearchText, searchProduct } from '../actions';
import { media } from '../utils/media';
import Link from '../components/link';

const NavItem = styled.div`
  display: inline-flex;
`;

const Navigation = styled.div`
  display: inline;
  ${media.desktop`
    display: none;
  `};
`;

const LogoWrapper = styled(Link)`
  display: inline-block;
  height: 55px;
  margin-right: 10px;
  ${media.tablet`
    height: 48px;
  `};
  cursor: pointer;
  ::after {
    display: none;
  }
  img {
    height: calc(100% - 25px);
    margin: 10px 10px 15px;
    width: auto;
    ${media.tablet`
      height: calc(100% - 25px);
      margin: 16px 10px 15px;
    `};
  }
`;

const Category = styled.div`
  position: relative;
  display: inline-block;
`;

const Title = styled(Link)`
  margin-left: 5px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  color: #04852F !important;
  ${Category}:hover & {
    color: #1D412F !important;
  }
  ::after {
    display: none;
  }
`;

const PopoverWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0,20px);
  background-color: white;
  left: 0px;
  border: 1px solid #097A29;
  width: auto;
  ${Category}:hover & {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0,0px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
`;

const PopoverContent = styled.div`
  padding: 15px;
  min-width: 200px;
  li {
    list-style: none;
    font-size: 14px;
    padding: 6px 0px;
    a {
      color: black;
      transition: all 0.3s;
      ::after {
        background-color: #04852F !important;
      }
      :hover {
        margin-left: 10px;
        color: #04852F !important;
      }
    }
  }
`;

const RightItem = styled.div`
  float: right;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
`;

const SearchField = styled.input`
  color: #282828;
  background-color: #fff;
  font-size: 12px;
  border-radius: 20px;
  border: 2px solid #04852F;
  padding: 0px 15px;
  margin-left: 15px;
  height: 36px;
  min-width: 150px;
  transition: all 0.3s;
  ${media.tablet`
    height: 33px;
  `};
  ${media.phablet`
    display: none;
  `};
  :hover, :active {
    border: 2px solid #1D412F;
    min-width: 180px;
  }
`;

const CartButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  border: 2px solid #04852F;
  border-radius: 18px;
  height: 36px;
  margin-left: 15px;
  width: 36px;
  ${media.tablet`
    height: 33px;
    width: 33px;
  `};
  color: #282828;
  display: flex;
  justify-items: center;
  position: relative;
  transition: all 0.3s;
  align-items: center;
  :hover, :active {
    border: 2px solid #1D412F;
  }
  svg {
    margin-left: 6px;
    color: #D5201B;
  }
`;

const SearchButton = styled(CartButton)`
  display: none;
  margin-left: 20px;
  ${media.phablet`
    display: flex;
  `};
`;

const DropdownButton = styled(CartButton)`
  display: none;
  margin-left: 0px;
  top: 8px;
  font-size: 16px;
  border: none;
  svg {
    color: #04852F;
  }
  ${media.desktop`
    display: inline-flex;
  `};
  :hover, :active {
    border: none;
  }
`;

const Badge = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  top: -4px;
  right: -4px;
  background-color: #D5201B;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const Divider = styled.span`
  color: #ccc;
  margin-left: 5px;
  line-height: 60px;
`;

class Menu extends Component {
  state = { showMenu: false, showMobileSearch: false }

  handleSubmitSearch = (e, location) => {
    e && e.preventDefault();
    if (location.includes('/search')) {
      this.props.searchProduct(this.props.searchText);
    } else {
      navigate('/search');
    }
  }

  render() {
    const { data, cartItems, removeFromCart, intl, locale, searchText, changeSearchText } = this.props; // eslint-disable-line
    const { showMenu } = this.state;
    let totalPayment = 0;
    cartItems.forEach(item => totalPayment += item.quantity * item.price); // eslint-disable-line
    return (
      <Location>
        {({ location }) => (location.pathname.includes('/story/') ? null : (
          <div style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.09)', zIndex: 20 }}>
            <Wrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <LogoWrapper to='/'>
                    <img src={logo} alt='LOGO' />
                  </LogoWrapper>
                  <Popover
                    visible={showMenu}
                    content={(
                      <div style={{ width: '300px' }}>
                        {data && <AntMenu mode="inline">
                          {data.map(item => (
                            <AntMenu.SubMenu
                              key={item._id}
                              title={<span onClick={() => navigate(item.url)}>{locale === 'en' && item.engTitle ? item.engTitle : item.title}</span>}
                            >
                              {item.children && item.children.map(child =>
                                (<AntMenu.Item key={child._id} onClick={() => { navigate(child.url); this.setState({ showMenu: false }) }}>
                                  <span>
                                    {locale === 'en' && child.engTitle ? child.engTitle : child.title}
                                  </span>
                                </AntMenu.Item>))
                              }
                            </AntMenu.SubMenu>
                          ))}
                        </AntMenu>}
                      </div>
                    )}
                    title={intl.formatMessage({ id: 'header.productMenu' })}
                    trigger="click"
                    placement="bottom"
                  >
                    <DropdownButton onClick={() => this.setState(state => ({ showMenu: !state.showMenu }))}>
                      <Icon type="down" />
                    </DropdownButton>
                  </Popover>
                  <Navigation>
                    {data && data.map((item, idx) => (
                      <NavItem key={item._id}>
                        <Category>
                          <Title to={item.url}>
                            {locale === 'en' && item.engTitle ? item.engTitle : item.title}
                          </Title>
                          <PopoverWrapper>
                            <PopoverContent>
                              {item.children && item.children.map(i =>
                                <li key={i._id}><Link to={i.url}>{locale === 'en' && i.engTitle ? i.engTitle : i.title}</Link></li>)}
                            </PopoverContent>
                          </PopoverWrapper>
                        </Category>
                        {idx !== data.length - 1 && <Divider>{' | '}</Divider>}
                      </NavItem>
                    ))}
                  </Navigation>
                </div>
                <div>
                  <RightItem>
                    <Popover
                      content={(
                        <div style={{ minWidth: 300 }}>
                          <List
                            itemLayout="horizontal"
                            dataSource={cartItems}
                            renderItem={(item, idx) => (
                              <List.Item>
                                <List.Item.Meta
                                  avatar={<Avatar
                                    size={50}
                                    shape='square'
                                    src={`${CDN_URL}/${item.product.images.find(i => i.color === item.color)
                                      ? item.product.images.find(i => i.color === item.color).image
                                      : item.product.images[0].image}`}
                                  />}
                                  title={<Link to={`/detail-product/${item.product._id}`}>{item.product.name} ({item.color})</Link>}
                                  description={<span>x{item.quantity} - {item.price * item.quantity} VNĐ</span>}
                                />
                                <Button shape='circle' type='danger' onClick={() => removeFromCart(idx)}><Icon type='delete' /></Button>
                              </List.Item>
                            )}
                          />
                          <Button block onClick={() => navigate('/checkout')} disabled={totalPayment === 0}>
                            {intl.formatMessage({ id: 'header.cartSubmit' })}&nbsp;-&nbsp;<strong>{totalPayment.toLocaleString()}₫</strong>
                          </Button>
                        </div>
                      )}
                      title={intl.formatMessage({ id: 'header.cart' })}
                      trigger="click"
                      placement="bottomRight"
                    >
                      <CartButton>
                        <MdShoppingCart />
                        <Badge>{cartItems.length}</Badge>
                      </CartButton>
                    </Popover>
                  </RightItem>
                  <RightItem>
                    <form onSubmit={e => this.handleSubmitSearch(e, location.pathname)}>
                      <SearchField
                        value={searchText}
                        autoFocus
                        placeholder={intl.formatMessage({ id: 'header.findProduct' })}
                        onChange={e => changeSearchText(e.target.value)}
                      />
                    </form>
                  </RightItem>
                  <RightItem>
                  <Popover
                      content={(
                        <div style={{ minWidth: 300 }}>
                          <Input.Search
                            value={searchText}
                            autoFocus
                            placeholder={intl.formatMessage({ id: 'header.findProduct' })}
                            onChange={e => changeSearchText(e.target.value)}
                            enterButton
                            onSearch={() => this.handleSubmitSearch(null, location.pathname)}
                          />
                        </div>
                      )}
                      trigger="click"
                      placement="bottomRight"
                    >
                      <SearchButton>
                        <MdSearch />
                      </SearchButton>
                    </Popover>
                  </RightItem>
                </div>
              </div>
            </Wrapper>
          </div>
        ))}
      </Location>
    );
  }
}

export default connect(state => ({
  cartItems: state.cart.list,
  searchText: state.product.searchText,
}), {
  removeFromCart,
  changeSearchText,
  searchProduct,
})(injectIntl(Menu));
