import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: white;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
`;

const Loading = () => (
  <Wrapper>
    <Spin size="large" />
  </Wrapper>
);

export default Loading;
