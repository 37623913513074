import React, { Component } from 'react';
// import AceEditor from 'react-ace';
import pretty from 'pretty';
import { query, ENDPOINTS, CDN_URL } from '../utils/service';

const maxRows = 5;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push(`newtable_${r}_${c}`);
  }
}
const defaultColors = ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"];

class Editor extends Component {
  constructor(props) {
    super(props);
    if (document) {
      this.quill = require('react-quill').default;
      this.aceEditor = require('react-ace').default;
      require('brace/mode/html');
      require('brace/theme/textmate');
      require('react-quill/dist/quill.snow.css');
      // Register table functionality for Quill
      const Quill = require('react-quill').Quill;
      const quillTable = require('quill-table');
      const ImageUploader = require('quill-image-uploader');
      Quill.register(quillTable.TableCell);
      Quill.register(quillTable.TableRow);
      Quill.register(quillTable.Table);
      Quill.register(quillTable.Contain);
      Quill.register('modules/table', quillTable.TableModule);
      //  Image uploader
      Quill.register('modules/imageUploader', ImageUploader.default);
      // Support more font
      const Font = Quill.import('formats/font');
      Font.whitelist = [
        'Arial',
        'Arial_Black',
        'Comic_Sans_MS',
        'Courier_New',
        'Helvetica',
        'Impact',
        'Tahoma',
        'Times_New_Roman',
        'Verdana',
        'Source_Sans_Pro',
      ];
      Quill.register(Font, true);

      // Modular
      this.modules = {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, false] }, { font: Font.whitelist }],
            [{ color: defaultColors }, { background: defaultColors }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [{ table: tableOptions }, { table: 'append-row' }, { table: 'append-col' }],
            ['clean', 'editHtml'],
          ],
          handlers: {
            editHtml: async () => {
              const prettier = await pretty(this.props.value);
              this.props.onChange(prettier);
              this.setState(state => ({ showRaw: !state.showRaw }));
            },
          },
        },
        imageUploader: {
          upload: file => new Promise(async (resolve, reject) => {
            try {
              const data = new FormData();
              data.append('file', file);
              const result = await query({
                method: 'POST',
                endpoint: ENDPOINTS.uploadArticleImage,
                headers: { 'content-type': 'multipart/form-data' },
                data,
              });
              resolve(`${CDN_URL}/${result.data}`);
            } catch (error) {
              reject(error);
            }
          }),
        },
        table: true,
      };

      this.state = { showRaw: false };
    }
  }

  render() {
    const ReactQuill = this.quill;
    const AceEditor = this.aceEditor;
    if (ReactQuill) {
      return (
        <div className={this.state.showRaw ? 'showRaw' : ''}>
          <ReactQuill
            modules={this.modules}
            theme="snow"
            {...this.props}
          />
          <AceEditor
            mode="html"
            className='raw-editor'
            theme="textmate"
            name="html-editor"
            onChange={this.props.onChange}
            fontSize={14}
            showPrintMargin
            showGutter
            highlightActiveLine
            value={this.props.value}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
        </div>
      );
    } return <textarea {...this.props} />;
  }
}

export default Editor;
