import React from 'react';
import styled from 'styled-components';
import { MdNavigateNext } from 'react-icons/md';

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 10px;
`;

const Container = styled.div`
  color: #bccbe5;
  display: inline-flex;
`;

const Item = styled.a`
  margin: 10px 10px;
  color: ${props => (props.active ? '#282828' : 'inherite')};
  font-size: 14px;
  cursor: ${props => (props.active ? 'unset' : 'pointer')};
  ::after {
    display: ${props => (props.active ? 'none' : 'noset')};
  }
`;

const Breadcrumb = ({ data }) => (
  <Wrapper>
    <Container>
      {data.map((item, idx) => (
        <div key={idx}>
          <Item href='#' active={idx === data.length - 1}>{item}</Item>
          {idx !== data.length - 1 && <MdNavigateNext />}
        </div>
      ))}
    </Container>
  </Wrapper>
);

export default Breadcrumb;
