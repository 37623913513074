import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import Layout from '../../layouts/layout';
import UserInfo from './user-info';
import ManageOrder from './manage-order';

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  min-height: 70vh;
  background: white;
  padding: 25px 15px;
`;

const TabPane = Tabs.TabPane;

const Dashboard = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  })

  const isXS = width <= 575;
  return (
    <Layout locale='vi'>
      <Wrapper>
        <Tabs
          defaultActiveKey="1"
          tabPosition={isXS ? 'top' : 'left'}
          style={{ zIndex: 0 }}
          tabBarStyle={{ width: isXS ? '100%' : 180 }}
        >
          <TabPane tab="Thông Tin Tài Khoản" key="1">
            <UserInfo />
          </TabPane>
          <TabPane tab="Quản Lý Đơn Hàng" key="2">
            <ManageOrder />
          </TabPane>
          {/* <TabPane tab="Địa Chỉ Của Tôi" key="3">Content of tab 3</TabPane>
          <TabPane tab="Thẻ Của Tôi" key="4">Content of tab 4</TabPane> */}
        </Tabs>
      </Wrapper>
    </Layout>
  );
}

export default Dashboard;
