import React from 'react'
import { Col } from 'react-flexbox-grid';

const ProductCol = (props) => (
  <Col
    xs={6}
    sm={4}
    md={4}
    lg={2}
    xl={2}
    style={{ marginBottom: 8, paddingLeft: 4, paddingRight: 4 }}
    {...props}
  />
)

export default ProductCol
