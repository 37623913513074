import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Wrapper from './wrapper';
import SlideArrow from './slide-arrow';
import { CDN_URL } from '../utils/service';

const Item = styled.a`
  height: 200px;
  padding: 10px 15px;
  ::after {
    display: none;
  }
`;

const Image = styled.div`
  background-image: url(${props => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
`;

const Certificate = ({ data }) => (
  <Wrapper>
    <Slider
      dots={false}
      slidesToShow={6}
      infinite
      autoplay
      swipeToSlide
      nextArrow={<SlideArrow type='next' />}
      prevArrow={<SlideArrow type='prev' />}
      responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
          },
        },
      ]}
    >
      {data.map((item) => (
        <Item key={item.featuredImage} href={item.link}>
          <Image image={`"${CDN_URL}/THUMB-${item.featuredImage}"`} />
        </Item>
      ))}
    </Slider>
  </Wrapper>
);

export default Certificate;
