import React, { Component } from 'react';
import {
  Input, Button,
} from 'antd';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';

import { Paper } from '../../../layouts/admin';
import { getYoutube, createYoutube } from '../../../actions';

export function youtubeParser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/; // eslint-disable-line
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

class Youtube extends Component {
  state = { inputValue: '' }

  componentDidMount() {
    this.props.getYoutube();
  }

  handleSubmit = () => {
    this.props.createYoutube({ url: this.state.inputValue });
    this.setState({ inputValue: '' });
  }

  render() {
    const { youtube = {} } = this.props;
    return (
      <Paper
        style={{ minHeight: 'calc(100vh - 230px)' }}
      >
        {youtube && youtube.url && <YouTube opts={{ width: '500px', height: '330px' }} videoId={youtubeParser(youtube.url)} />}
        <div style={{ marginTop: 10 }}>
          <Input
            value={this.state.inputValue}
            onChange={e => this.setState({ inputValue: e.target.value })}
            placeholder={youtube && youtube.url ? youtube.url : 'https://www.youtube.com/watch?v=7DOafkAY0HY'}
            style={{ display: 'inline-block', width: 400, marginRight: 10 }}
          />
          <Button type='primary' style={{ display: 'inline-block' }} onClick={this.handleSubmit}>Cập nhật</Button>
        </div>
      </Paper>
    );
  }
}

export default connect(state => ({
  youtube: state.home.youtube,
}), {
  getYoutube,
  createYoutube,
})(Youtube);
