import { combineReducers } from 'redux';

import userReducer from './user';
import settingReducer from './setting';
import productReducer from './product';
import uploadReducer from './upload';
import articleReducer from './article';
import distributionReducer from './distribution';
import homeReducer from './home';
import pageReducer from './page';
import storyReducer from './story';
import cartReducer from './cart';
import contactFormReducer from './contact-form';
import contactReducer from './contact';
import orderReducer from './order';
import layoutReducer from './layout';
import bannerReducer from './banner';

export default combineReducers({
  user: userReducer,
  setting: settingReducer,
  product: productReducer,
  upload: uploadReducer,
  article: articleReducer,
  distribution: distributionReducer,
  home: homeReducer,
  page: pageReducer,
  story: storyReducer,
  cart: cartReducer,
  contactForm: contactFormReducer,
  contact: contactReducer,
  order: orderReducer,
  layout: layoutReducer,
  banner: bannerReducer,
});
