import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import ProductsSkeleton from '../components/products-skeleton';
import { getProductBySection } from '../actions';
import ProductCol from '../components/product-col';

const SectionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
`;

class SectionPage extends Component {
  componentDidMount() {
    this.props.id && this.props.getProductBySection(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id && nextProps.id !== this.props.id) {
      this.props.getProductBySection(nextProps.id);
    }
  }

  render() {
    const { data, loading, error, locale, section } = this.props;

    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '50vh' }}>
          <SectionTitle>
            {(!section)
              ? <FormattedMessage id='category' />
              : section.label}
          </SectionTitle>
          {error && <p><FormattedMessage id='error' /></p>}
          <Grid fluid>
            {!loading ? <Row>
              {data && data.length ? data.map(product => (
                <ProductCol key={product._id}>
                  <ProductCard locale={locale} data={product} />
                </ProductCol>
              )) : <p><FormattedMessage id='noData' /></p>}
            </Row> : <ProductsSkeleton large />}
          </Grid>
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  // data: state.page.product,
  data: state.page.productCategory,
  loading: state.page.fetchProductCategory,
  section: state.page.section,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getProductBySection,
})(SectionPage);
