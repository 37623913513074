import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import { searchProduct } from '../actions';

class SearchPage extends Component {
  componentDidMount() {
    this.props.searchProduct(this.props.searchText);
  }

  render() {
    const { locale, data, searching } = this.props;
    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '100vh', paddingTop: 20 }}>
          <h2 style={{ margin: '20px 0px' }}>Kết quả tìm kiếm</h2>
          <Row>
            {!searching // eslint-disable-line
              ? data && data.length > 0 ? data.map(product => (
                <Col key={product._id} xs={6} md={4} xl={3} style={{ marginBottom: 15 }}>
                  <ProductCard locale={locale} data={product} />
                </Col>
              )) : <p style={{ marginLeft: 20 }}>Không tìm thấy sản phẩm</p>
              : <p style={{ marginLeft: 20 }}>Đang tìm kiếm sản phẩm...</p>
            }
          </Row>
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.product.searchResult,
  searchText: state.product.searchText,
  searching: state.product.searching,
  locale: state.setting.locale,
}), {
  searchProduct,
})(SearchPage);
