import React from 'react';
import { Row } from 'react-flexbox-grid';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { Card } from 'antd';

import { media } from '../utils/media';
import ProductCol from './product-col';

const ProductImage = styled.div`
  height: 205px;
  ${media.desktop`
    height: 200px;
  `};
  ${media.tablet`
    height: 235px;
  `};
  ${media.phablet`
    height: 182px;
  `};
  ${media.phone`
    height: 163px;
  `};
  ${media.tiny`
    height: 135px;
  `};
  ::after {
    display: none;
  }
  cursor: pointer;
`;

const ProductName = styled.div`
  height: 14px;
  margin-bottom: 10px;
`;

const ProductDescription = styled.div`
  height: 14px;
  width: 60%;
`;

const ProductsSkeleton = ({ large }) => {
  const arr = large ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4];
  return (
    <Row>
      {arr.map(item => (
        <ProductCol key={item}>
          <Card
            hoverable
            cover={(
              <ProductImage>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '100%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
                </ContentLoader>
              </ProductImage>
            )}
            bodyStyle={{ padding: 14 }}
          >
            <Card.Meta
              title={<ProductName>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '100%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </ProductName>}
              description={<ProductDescription>
                <ContentLoader
                  speed={2}
                  primaryColor="#f3f3f3"
                  style={{ width: '100%', height: '100%' }}
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </ProductDescription>}
            />
          </Card>
        </ProductCol>
      ))}
    </Row>
  );
};

export default ProductsSkeleton;
