import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { Tooltip, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import StoreMap from '../components/store-map';
import provinces from '../dist/provinces.json';
import districts from '../dist/districts.json';
import { getDistributionList } from '../actions';

const Option = Select.Option;

const provinceData = Object.keys(provinces)
  .map(key => ({
    value: provinces[key].name_with_type,
    code: provinces[key].code,
  }))
  .sort(item => (item.value === 'Thành phố Hồ Chí Minh' ? -1 : 1));

const districtData = Object.keys(districts)
  .map(item => ({
    value: districts[item].name_with_type,
    parent: districts[item].parent_code,
  }));

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 15px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  color: #134E8F;
`;

const FormWrapper = styled.div`
  padding: 20px 0px;
  p {
    font-size: 14px;
    color: #838383;
    text-align: center;
    padding-top: 10px;
    padding: 20px 0 0;
  }
  strong {
    color: #444c59;
  }
`;

const Result = styled.div`
  li {
    color: #134E8F;
    :hover {
      color: #0b3a70;
    }
    margin-bottom: 5px;
    cursor: pointer;
    list-style-type: square;
  }
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'distribution' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class StoreMapPage extends Component {
  state = {
    fields: {
      province: '',
      district: '',
    },
    selected: null,
  }

  componentDidMount() {
    this.props.getDistributionList();
  }

  handleCascader = values => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        province: values[0],
        district: values[1],
      },
    });
  }

  render() {
    const { loading, list, locale } = this.props;
    const { fields, selected } = this.state;

    const data = list.filter(item => {
      if (fields.district) {
        return item.location.district === fields.district;
      }
      if (fields.province) {
        return item.location.province === fields.province;
      }
      return false;
    });

    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper>
            <Row>
              <Col xs={12} md={5} xl={3}>
                <SectionTitle><FormattedMessage id='distribution' /></SectionTitle>
                <FormWrapper>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Tỉnh, thành phố"
                    optionFilterProp="children"
                    onChange={province => this.setState({ fields: { province, district: '' } })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {provinceData.map(item => <Option key={item.value} value={item.value}>{item.value}</Option>)}
                  </Select>
                  <Select
                    showSearch
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="Huyện, thị xã"
                    optionFilterProp="children"
                    onChange={district => this.setState(state => ({ fields: { ...state.fields, district } }))}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {districtData
                      .filter(item => (!fields.province.length ? false : item.parent === provinceData.find(i => i.value === fields.province).code))
                      .map(item => <Option key={item.value + item.parent} value={item.value}>{item.value}</Option>)}
                  </Select>
                </FormWrapper>
                <Result>
                  {(!fields.district && !fields.province) // eslint-disable-line
                    ? <p><FormattedMessage id='distribution.hint' /></p>
                    : loading // eslint-disable-line
                      ? <p><FormattedMessage id='distribution.loading' /></p>
                      : data.length > 0
                        ? (
                          <ul style={{ marginLeft: 15, maxHeight: 450, overflow: 'scroll' }}>
                            {data.map(item => (
                              <Tooltip
                                placement="topLeft"
                                key={item._id}
                                title={`${item.location.address}, ${item.location.district}, ${item.location.province}, Việt Nam`}
                              >
                                <li onClick={() => this.setState({ selected: item })}>{item.name}</li>
                              </Tooltip>
                            ))}
                          </ul>
                        )
                        : <p><FormattedMessage id='distribution.notFound' /></p>
                  }
                </Result>
              </Col>
              <Col xs={12} md={7} xl={9}>
                <StoreMap data={data} selected={selected} />
              </Col>
            </Row>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  loading: state.distribution.requesting,
  list: state.distribution.list,
  locale: state.setting.locale,
}), {
  getDistributionList,
})(StoreMapPage);
