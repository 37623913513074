/* eslint-disable react/no-array-index-key */

import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { message, Steps, Icon, Button, Radio, Modal } from 'antd';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import Link from '../components/link';
import { CDN_URL } from '../utils/service';
import { createOrder, cleanCart } from '../actions';

const getImage = image => `${CDN_URL}/${image}`;

const Step = Steps.Step;
const RadioGroup = Radio.Group;

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
`;

const SectionTitle = styled.h1`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px 0px;
  color: #134E8F;
`;

const FormWrapper = styled.div`
  padding: 0px 0px 10px;
  p {
    font-size: 14px;
    color: #838383;
    text-align: center;
    padding-top: 10px;
    padding: 20px 0 0;
  }
  strong {
    color: #444c59;
  }
`;

const FormElement = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
  margin-bottom: 20px;
  float: left;
  ::before {
    clear: both;
    width: 100%;
    content: '';
    display: table;
  }
  ::after {
    clear: both;
    width: 100%;
    content: '';
    display: table;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 0 0;
    box-sizing: border-box;
    outline: 0!important;
    appearance: none;
    color: #282828;
    font-size: 14px;
    border-radius: 2px;
    padding: 17px 10px 5px;
    border: 1px solid #90A3C2;
  }
  .placeholder {
    pointer-events: none;
    color: #90A3C2;
    position: absolute;
    top: 0;
    max-width: 100%;
    z-index: 1;
    transition: .3s;
    transform-origin: 0 0;
    font-size: 14px;
    left: 10px;
    line-height: 46px;
    font-weight: 400;
  }
  input:focus ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  input:valid ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    background: 0 0;
    box-sizing: border-box;
    outline: 0!important;
    appearance: none;
    color: #282828;
    font-size: 14px;
    border-radius: 2px;
    padding: 17px 10px 5px;
    border: 1px solid #90A3C2;
  }
  textarea:focus ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  textarea:valid ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
`;

const StepContent = styled.div`
  min-height: 28vh;
`;

const CartDataWrapper = styled.div`
  border: 1px solid #90A2C2;
  border-radius: 2px;
  min-height: 28vh;
`;

const CartDataContainer = styled.div`
  padding: 0px 15px 15px;
`;

export const CartItem = styled.div`
  /* border-bottom: 1px solid #90A2C2; */
  padding: 10px 0px;
  display: flex;
`;

export const CartImage = styled.img`
  width: 24%;
  height: 100%;
`;

export const CartContent = styled.div`
  width: 76%;
  padding-left: 15px;
`;

const steps = [{
  title: 'Đăng nhập',
  icon: <Icon type="user" />,
}, {
  title: 'Xác nhận',
  icon: <Icon type="solution" />,
}, {
  title: 'Thanh toán',
  icon: <Icon type="wallet" />,
}, {
  title: 'Hoàn tất',
  icon: <Icon type="smile-o" />,
}];

class CheckoutPage extends Component {
  state = {
    current: 1,
    fields: {
      fullname: '',
      phone: '',
      email: '',
      address: '',
    },
  }

  componentDidMount() {
    const {
      // isLogged,
      userInfo,
    } = this.props;
    // if (!isLogged) {
    //   message.info('Bạn sẽ được chuyển hướng để đăng nhập');
    //   setTimeout(() => navigate('/user'), 3000);
    // } else {
      this.setState({
        fields: {
          fullname: userInfo.fullname || '',
          phone: userInfo.phone || '',
          email: userInfo.email || '',
          address: userInfo.address || '',
        },
      });
    // }
  }

  next() {
    const {
      // isLogged,
      cartData
    } = this.props;
    const { fields, current } = this.state;
    let totalPayment = 0;
    cartData.forEach(item => totalPayment += item.quantity * item.price); // eslint-disable-line
    const nextCurrent = current + 1;
    // if (nextCurrent === 1 && !isLogged) {
    //   message.error('Vui lòng đăng nhập để tiếp tục');
    // } else
    if (nextCurrent === 2) {
      if (cartData.length === 0) {
        message.error('Bạn chưa có sản phẩm nào trong giỏ hàng');
      } else if ((!fields.fullname.length || !fields.phone.length || !fields.email.length || !fields.address.length)) {
        message.error('Vui lòng điền đầy đủ thông tin');
      } else {
        this.setState({ current: nextCurrent });
      }
    } else if (nextCurrent === 3) {
      Modal.confirm({
        title: 'Xác nhận hoàn tất đặt hàng?',
        centered: true,
        content: `Tổng đơn hàng: ${totalPayment.toLocaleString()}₫. Phương thức thanh toán: COD.`,
        onOk: () => {
          this.handleSubmit();
        },
      });
    } else {
      this.setState({ current: nextCurrent });
    }
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  handleChange = (type, value) => this.setState(state => ({
    fields: {
      ...state.fields,
      [type]: value,
    },
  }))

  handleSubmit = () => {
    const { userInfo, cartData, createOrder, cleanCart } = this.props; // eslint-disable-line
    const { fields } = this.state;
    let totalPayment = 0;
    cartData.forEach(item => totalPayment += item.quantity * item.price); // eslint-disable-line
    createOrder({
      data: cartData.map(item => ({ ...item, product: item.product._id })),
      method: 'cod',
      receiver: fields,
      customer: userInfo._id,
      totalPayment,
    }, {
      success: () => {
        message.success('Thành công!');
        cleanCart();
        this.setState({ current: 3 });
      },
      failure: () => {
        message.error('Có lỗi xảy ra!');
      },
    });
  }

  renderContent = current => {
    const { userInfo } = this.props;
    const { fields } = this.state;
    switch (current) {
      case 0:
        if (userInfo.fullname) {
          return (
            <p style={{ marginTop: 20 }}>Bạn đã đăng nhập với tên {userInfo.fullname}. <a>Đăng xuất</a>?</p>
          );
        }
        return null
      case 1:
        return (
          <div>
            <SectionTitle>Thông tin đặt hàng</SectionTitle>
            <FormWrapper>
              <form method="post">
                <Row style={{ width: '100%' }}>
                  <Col lg={12} sm={12}>
                    <FormElement>
                      <input
                        value={fields.fullname}
                        onChange={e => this.handleChange('fullname', e.target.value)}
                        required
                      />
                      <div className='placeholder'>Họ và tên</div>
                    </FormElement>
                  </Col>
                  <Col lg={6} sm={12}>
                    <FormElement>
                      <input
                        value={fields.phone}
                        onChange={e => this.handleChange('phone', e.target.value)}
                        required
                      />
                      <div className='placeholder'>Số điện thoại</div>
                    </FormElement>
                  </Col>
                  <Col lg={6} sm={12}>
                    <FormElement>
                      <input
                        value={fields.email}
                        onChange={e => this.handleChange('email', e.target.value)}
                        required
                      />
                      <div className='placeholder'>Email</div>
                    </FormElement>
                  </Col>
                  <Col lg={12} sm={12}>
                    <FormElement>
                      <input
                        value={fields.address}
                        onChange={e => this.handleChange('address', e.target.value)}
                        required
                      />
                      <div className='placeholder'>Địa chỉ nhận hàng</div>
                    </FormElement>
                  </Col>
                </Row>
              </form>
            </FormWrapper>
          </div>
        );
      case 2:
        return (
          <div>
            <SectionTitle>Chọn phương thức thanh toán</SectionTitle>
            <RadioGroup value={1}>
              <Radio style={{ display: 'block', height: 30, lineHeight: '30px' }} value={1}>
                Thanh toán khi nhận hàng (COD)
              </Radio>
              <Radio style={{ display: 'block', height: 30, lineHeight: '30px' }} disabled value={2}>
                Chuyển khoản ngân hàng - Đang bảo trì
              </Radio>
              <Radio style={{ display: 'block', height: 30, lineHeight: '30px' }} disabled value={3}>
                Thẻ quốc tế Visa/MasterCard - Đang bảo trì
              </Radio>
            </RadioGroup>
          </div>
        );
      case 3:
        return (
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ marginTop: 70 }}>Đặt hàng thành công!</h3>
            <p style={{ marginBottom: 30 }}>Nhân viên của chúng tôi sẽ liên hệ với bạn để xác nhận.</p>
            <Link to='/product'>Tiếp tục mua hàng</Link>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      locale,
      // isLogged,
      cartData
    } = this.props;
    const { current } = this.state;

    let totalPayment = 0;
    cartData.forEach(item => totalPayment += item.quantity * item.price); // eslint-disable-line

    // if (!isLogged) {
    //   return (
    //     <Layout locale={locale}>
    //       <Container>
    //         <Breadcrumb data={['Trang chủ', 'Đặt hàng']} />
    //         <Wrapper>
    //           <p style={{ margin: '50px auto', textAlign: 'center' }}>Bạn sẽ được chuyển hướng để đăng nhập</p>
    //         </Wrapper>
    //       </Container>
    //     </Layout>
    //   );
    // }

    return (
      <Layout locale={locale}>
        <Container>
          <Breadcrumb data={['Trang chủ', 'Đặt hàng']} />
          <Wrapper>
            <Grid fluid>
              <Row>
                <Col md={12} xl={4}>
                  <CartDataWrapper>
                    <SectionTitle>Đơn hàng</SectionTitle>
                    <CartDataContainer>
                      {!cartData.length
                        ? <p>Không có gì trong giỏ hàng</p>
                        : (
                          <div>
                            <ul>
                              {cartData.map((item, idx) => (
                                <CartItem key={item.product._id + idx}>
                                  <CartImage
                                    src={getImage(item.product.images.find(i => i.color === item.color)
                                      ? item.product.images.find(i => i.color === item.color).image
                                      : item.product.images[0].image)}
                                  />
                                  <CartContent>
                                    <p style={{ margin: 5, fontWeight: 600 }}>{item.product.name} ({item.color})</p>
                                    <p style={{ margin: 5, fontSize: 14 }}>Số lượng: {item.quantity}</p>
                                    <p style={{ margin: 5, fontSize: 14 }}>Giá: {item.price.toLocaleString()}₫</p>
                                  </CartContent>
                                </CartItem>
                              ))}
                            </ul>
                            <p>Thanh toán: <strong style={{ fontSize: 18 }}>{totalPayment.toLocaleString()}₫</strong></p>
                          </div>
                        )
                      }
                    </CartDataContainer>
                  </CartDataWrapper>
                </Col>
                <Col md={12} xl={8}>
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} icon={item.icon} />
                    ))}
                  </Steps>
                  <StepContent>
                    {this.renderContent(current)}
                  </StepContent>
                  <div className="steps-action">
                    {
                      current > 0 && current < 3
                      && (
                        <Button onClick={() => this.prev()}>
                          Quay lại
                        </Button>
                      )
                    }
                    {
                      current < steps.length - 1
                      && <Button style={{ marginLeft: 8 }} type="primary" onClick={() => this.next()}>Tiếp tục</Button>
                    }
                    {
                      current === steps.length - 1
                      && <Button style={{ marginLeft: 8 }} type="primary" onClick={() => navigate('/')}>Hoàn tất</Button>
                    }
                  </div>
                </Col>
              </Row>
            </Grid>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  isLogged: state.user.isLogged,
  userInfo: state.user.userInfo || {},
  cartData: state.cart.list,
  locale: state.setting.locale,
}), {
  createOrder,
  cleanCart,
})(CheckoutPage);
