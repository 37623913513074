import React from 'react';
import { Link } from '@reach/router';
import { FormattedMessage } from 'react-intl';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';

const NotFoundPage = ({ locale }) => {

  return (
    <Layout locale={locale}>
      <Wrapper style={{ textAlign: 'center', paddingTop: '20vh', minHeight: '50vh' }}>
        <h1><FormattedMessage id='404.title' /></h1>
        <p style={{ marginTop: 30 }}>
          <FormattedMessage id='404.description1' /> <Link to='/'><FormattedMessage id='404.here' /></Link> <FormattedMessage id='404.description2' />
        </p>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
