import React, { Component } from 'react';
import {
  Input,
  Button,
  Table,
  // Avatar,
  Divider,
  Form,
  Modal,
} from 'antd';
import { connect } from 'react-redux';

import {
  getArticleCategoryList,
  createArticleCategory,
  updateArticleCategory,
  deleteArticleCategory,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
// import { API_URL } from '../../utils/service';

class ArticleCategory extends Component {
  state = {
    showMedia: false,
    showModal: false,
    modalType: 'Tạo mới',
    selectedRowKeys: [],
  }

  componentDidMount() {
    this.props.getArticleCategoryList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createArticleCategory, updateArticleCategory, deleteArticleCategory } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (modalType === 'Tạo mới') {
          createArticleCategory(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateArticleCategory(id, values);
        } else if (modalType === 'Xoá') {
          deleteArticleCategory(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Xoá');
  }

  render() {
    const { categories, form: { getFieldDecorator, setFieldsValue } } = this.props;
    const { showMedia, showModal, modalType, selectedRowKeys } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Layout path='/admin/article-category'>
        <Media
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={file => {
            setFieldsValue({ featuredImage: file.filename });
            this.setState({ showMedia: false });
          }}
        />
        <Modal
          title={`${modalType} danh mục`}
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tên danh mục"
            >
              {getFieldDecorator('name', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tên danh mục!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            {/* <Form.Item
              {...formItemLayout}
              label='Hình ảnh'
            >
              {getFieldDecorator('featuredImage', {
                rules: [{ required: true, message: 'Vui lòng chọn hình danh mục!' }],
              })(
                <Input onClick={() => this.setState({ showMedia: true })} disabled={modalType === 'Xoá'} />
              )}
            </Form.Item> */}
          </Form>
        </Modal>
        <Paper
          title='Danh mục danh mục'
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
            //   title: 'Ảnh',
            //   dataIndex: 'featuredImage',
            //   key: 'featuredImage',
            //   width: 100,
            //   render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/${data}`} />,
            // }, {
              title: 'Tên danh mục',
              dataIndex: 'name',
              key: 'name',
            }, {
              title: 'Người tạo',
              dataIndex: 'createdBy.fullname',
              key: 'createdBy',
              render: data => <a>{data}</a>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 110,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={categories}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  categories: state.article.categoryList,
}), {
  getArticleCategoryList,
  createArticleCategory,
  updateArticleCategory,
  deleteArticleCategory,
})(Form.create()(ArticleCategory));
