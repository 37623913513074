import React, { Component } from 'react';
import {
  Input,
  Button,
  Table,
  Divider,
  Form,
  Modal,
  Select,
  message,
  Tabs,
  Avatar,
  Tag,
} from 'antd';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  getHomeSectionBannerList,
  updateHomeSectionBanner,
  getBannerList,
  createBanner,
  updateBanner,
  deleteBanner,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
import { CDN_URL } from '../../utils/service';

class SectionBanner extends Component {
  state = {
    bannerSelectedRowKeys: [],
    showBannerModal: false,
    bannerModalType: 'Tạo mới',
    showBannerMedia: false,
    sectionSelectedRowKeys: [],
    showSectionModal: false,
  }

  componentDidMount() {
    this.props.getHomeSectionBannerList();
    this.props.getBannerList();
  }

  onSectionSelectChange = (sectionSelectedRowKeys) => {
    this.setState({ sectionSelectedRowKeys });
  }

  showSectionModal = () => this.setState({
    showSectionModal: true,
  })

  hideSectionModal = () => {
    this.setState({ showSectionModal: false });
    this.props.form.resetFields();
  }

  handleSubmitSection = (e) => {
    e.preventDefault();
    const { form, updateHomeSectionBanner } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        console.log(values)
        updateHomeSectionBanner({
          homeSectionKey: values.key,
          banner: values.banner,
          bannerLeft: values.bannerLeft,
        });
        this.setState({ showSectionModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  onBannerSelectChange = (bannerSelectedRowKeys) => {
    this.setState({ bannerSelectedRowKeys });
  }

  showBannerModal = bannerModalType => this.setState({
    showBannerModal: true,
    bannerModalType,
  })

  hideBannerModal = () => {
    this.setState({ showBannerModal: false });
    this.props.form.resetFields();
  }

  handleSubmitBanner = (e) => {
    e.preventDefault();
    const { bannerModalType } = this.state;
    const { form, createBanner, updateBanner, deleteBanner } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (bannerModalType === 'Tạo mới') {
          createBanner(values);
        } else if (bannerModalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateBanner(id, values);
        } else if (bannerModalType === 'Xoá') {
          deleteBanner(values._id);
        }
        this.setState({ showBannerModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEditBanner = record => {
    this.props.form.setFieldsValue(record);
    this.showBannerModal('Chỉnh sửa');
  }

  handleEditSection = record => {
    this.props.form.setFieldsValue({
      ...record,
      banner: record.banner ? record.banner._id : null,
      bannerLeft: record.bannerLeft ? record.bannerLeft._id : null,
    });
    this.showSectionModal();
  }

  handleDeleteBanner = record => {
    this.props.form.setFieldsValue(record);
    this.showBannerModal('Xoá');
  }

  render() {
    const { homeSections, banners, form: { getFieldDecorator, setFieldsValue } } = this.props;
    const {
      bannerSelectedRowKeys,
      showBannerModal,
      bannerModalType,
      showBannerMedia,
      sectionSelectedRowKeys,
      showSectionModal,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Layout path='/admin/config-banner'>
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 0 }}>
          <Tabs.TabPane tab="Danh mục trang chủ" key="1">
            <Modal
              title={`Cập nhật banner`}
              visible={showSectionModal}
              onOk={this.handleSubmitSection}
              onCancel={this.hideSectionModal}
              footer={[
                <Button key="back" onClick={this.hideSectionModal}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmitSection}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmitSection}>
                {getFieldDecorator('key')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Banner ngang"
                >
                  {getFieldDecorator('banner')(
                    <Select>
                      <Select.Option value={null}>
                        Không chọn
                      </Select.Option>
                      {banners.filter(i => i.type === 1).map(banner => (
                        <Select.Option value={banner._id}>
                          {banner.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Banner dọc"
                >
                  {getFieldDecorator('bannerLeft')(
                    <Select>
                      <Select.Option value={null}>
                        Không chọn
                      </Select.Option>
                      {banners.filter(i => i.type === 2).map(banner => (
                        <Select.Option value={banner._id}>
                          {banner.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Paper
              title='Danh mục trang chủ'
            >
              <Table
                bordered
                rowKey={record => record.key}
                rowSelection={{
                  sectionSelectedRowKeys,
                  onChange: this.onSectionSelectChange,
                }}
                columns={[{
                  title: 'Mục',
                  dataIndex: 'label',
                  key: 'label',
                }, {
                  title: 'Banner ngang',
                  dataIndex: 'banner',
                  key: 'banner',
                  render: record => record && record.name,
                }, {
                  title: 'Banner dọc',
                  dataIndex: 'bannerLeft',
                  key: 'bannerLeft',
                  render: record => record && record.name,
                }, {
                  title: 'Hành động',
                  key: 'action',
                  width: 185,
                  render: (record) => !record.items && (
                    <span>
                      <a role='button' onClick={() => this.handleEditSection(record)}>Sửa</a>
                    </span>
                  ),
                }]}
                dataSource={homeSections}
                defaultExpandAllRows
                childrenColumnName='items'
              />
            </Paper>
          </Tabs.TabPane>


          <Tabs.TabPane tab="Quản lý banner" key="2">
            <Media
              visible={showBannerMedia}
              onCancel={() => this.setState({ showBannerMedia: false })}
              onSubmit={file => {
                setFieldsValue({ featuredImage: file.filename });
                this.setState({ showBannerMedia: false });
              }}
            />
            <Modal
              title={`${bannerModalType} banner`}
              visible={showBannerModal}
              onOk={this.handleSubmitBanner}
              onCancel={this.hideBannerModal}
              footer={[
                <Button key="back" onClick={this.hideBannerModal}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmitBanner}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmitBanner}>
                {getFieldDecorator('_id')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Tên banner"
                >
                  {getFieldDecorator('name', {
                  })(
                    <Input disabled={bannerModalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Hình ảnh'
                >
                  {getFieldDecorator('featuredImage')(
                    <Input onClick={() => this.setState({ showBannerMedia: true })} disabled={bannerModalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="URL"
                >
                  {getFieldDecorator('link')(
                    <Input disabled={bannerModalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Loại banner"
                >
                  {getFieldDecorator('type')(
                    <Select disabled={bannerModalType === 'Xoá'} defaultValue={1}>
                      <Select.Option value={1}>
                        Banner ngang (2400px x 292px)
                      </Select.Option>
                      <Select.Option value={2}>
                        Banner dọc (615px x 1844px)
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Paper
              title='Banner'
              extra={<div>
                <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
                <Button onClick={() => this.showBannerModal('Tạo mới')} type='primary'>Tạo mới</Button>
              </div>}
            >
              <Table
                bordered
                rowKey={record => record._id}
                rowSelection={{
                  bannerSelectedRowKeys,
                  onChange: this.onBannerSelectChange,
                }}
                columns={[{
                  title: 'Ảnh',
                  dataIndex: 'featuredImage',
                  key: 'featuredImage',
                  width: 100,
                  render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/${data}`} />,
                }, {
                  title: 'Tên',
                  dataIndex: 'name',
                  key: 'name',
                }, {
                  title: 'URL',
                  dataIndex: 'link',
                  key: 'link',
                }, {
                  title: 'Loại',
                  dataIndex: 'type',
                  key: 'type',
                  render: data => data === 1 ? <Tag>BANNER NGANG</Tag> : <Tag>BANNER DỌC</Tag>,
                }, {
                  title: 'Hành động',
                  key: 'action',
                  width: 185,
                  render: (record) => (
                    <span>
                      <a role='button' onClick={() => this.handleEditBanner(record)}>Sửa</a>
                      <Divider type="vertical" />
                      <a role='button' onClick={() => this.handleDeleteBanner(record)}>Xoá</a>
                      <Divider type="vertical" />
                      <CopyToClipboard text={`/banner/${record._id}`} onCopy={() => message.success('Copy URL thành công!')}>
                        <a>Copy URL</a>
                      </CopyToClipboard>
                    </span>
                  ),
                }]}
                dataSource={banners}
              />
            </Paper>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

export default connect(state => ({
  banners: state.banner.list,
  homeSections: state.banner.homeSections,
}), {
  getHomeSectionBannerList,
  updateHomeSectionBanner,
  getBannerList,
  createBanner,
  updateBanner,
  deleteBanner,
})(Form.create()(SectionBanner));
