import {
  // GET_CONTACT_LIST_FAILURE,
  // GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  // CREATE_CONTACT_FAILURE,
  // CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  // UPDATE_CONTACT_FAILURE,
  // UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  // DELETE_CONTACT_FAILURE,
  // DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  list: [],
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.contacts,
      };
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          action.payload,
        ],
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.payload._id),
      };
    default:
      return state;
  }
};

export default reducers;