import {
  GET_STORY_LIST_FAILURE,
  GET_STORY_LIST_REQUEST,
  GET_STORY_LIST_SUCCESS,
  // CREATE_STORY_FAILURE,
  // CREATE_STORY_REQUEST,
  CREATE_STORY_SUCCESS,
  // UPDATE_STORY_FAILURE,
  // UPDATE_STORY_REQUEST,
  UPDATE_STORY_SUCCESS,
  // DELETE_STORY_FAILURE,
  // DELETE_STORY_REQUEST,
  DELETE_STORY_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  loading: false,
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_STORY_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.stories,
        loading: false,
      };
    case CREATE_STORY_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          action.payload,
        ],
      };
    case UPDATE_STORY_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_STORY_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.payload._id),
      };
    default:
      return state;
  }
};

export default reducers;