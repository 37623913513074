import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { connect } from 'react-redux';

import icon from '../images/marker.png';

const defaultLocation = { lat: 16.2231377, lng: 105.744098 };

const MyMapComponent = withScriptjs(withGoogleMap(({ markerData, defaultList, selected, ...props }) => (
  <GoogleMap
    // defaultZoom={markerData.length > 0 ? 13 : 5.7}
    zoom={markerData.length > 0 ? 13 : 5.7}
    defaultCenter={markerData.length > 0 ? markerData[0].coordinate : defaultLocation}
    center={selected // eslint-disable-line
      ? selected.coordinate
      : markerData.length > 0
        ? markerData[0].coordinate
        : defaultLocation}
    {...props}
  >
    {markerData.length > 0
      ? markerData.map(item => (
        <Marker key={item._id} icon={icon} position={item.coordinate} />
      ))
      : defaultList.map(item => (
        <Marker key={item._id} icon={icon} position={item.coordinate} />
      ))
    }
  </GoogleMap>
)));

class StoreMap extends Component {
  render() {
    const { data, selected, defaultList } = this.props;

    return (
      <MyMapComponent
        isMarkerShown
        defaultList={defaultList}
        markerData={data}
        selected={selected}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9wFUzb5FpEhwmo-3LRQWgPDI5NPIZNTk&callback=initMap"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '600px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    );
  }
}

export default connect(state => ({
  defaultList: state.distribution.list,
}))(StoreMap);
