import { GET_LAYOUT_REQUEST, GET_LAYOUT_FAILURE, GET_LAYOUT_SUCCESS } from '../actions';

const INITIAL_STATE = {
  loading: false,
  initLayout: false,
  topMenus: [],
  productMenus: [],
  contacts: [],
  footerLinks: [],
  socialLinks: [],
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LAYOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LAYOUT_SUCCESS:
      return {
        ...state,
        topMenus: action.payload.topMenus,
        productMenus: action.payload.productMenus,
        footerLinks: action.payload.footerLinks,
        socialLinks: action.payload.socialLinks,
        contacts: action.payload.contacts,
        loading: false,
        initLayout: true,
      };
    case GET_LAYOUT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducers;