import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

const PrivateRoute = ({ component: Component, isLogged, userInfo, basePath, ...rest }) => {
  let isAuthorized = true;
  if (!isLogged || (basePath === 'admin' && userInfo.role !== 2)) {
    isAuthorized = false;
  }

  if (!isAuthorized) {
    navigate(`../${basePath}`);
    return null;
  }

  return <Component path={basePath} {...rest} />;
};

export default connect(state => ({
  isLogged: state.user.isLogged,
  userInfo: state.user.userInfo,
}))(PrivateRoute);
