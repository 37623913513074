import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';

import { CDN_URL } from '../utils/service';
import { media } from '../utils/media';

const getImage = image => `"${CDN_URL}/THUMB-${image}"`;

const ProductImage = styled.div`
  background-image: url(${props => props.images.length > 1 && getImage(props.images[0].image)});
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.2);
  height: 193px;
  ${media.desktop`
    height: 250px;
  `};
  ${media.tablet`
    height: 230px;
  `};
  ${media.phablet`
    height: 185px;
  `};
  ${media.phone`
    height: 167px;
  `};
  ${media.tiny`
    height: 119px;
  `};
  :hover {
    background-image: url(${props => props.images.length === 0 ? null : (props.images.length >= 2
    ? getImage(props.images[1].image)
    : getImage(props.images[0].image))});
  }
  ::after {
    display: none;
  }
  cursor: pointer;
`;

const ProductPrice = styled.div`
  font-size: 20px;
  font-weight: 700;
  ${media.phablet`
    font-size: 18px;
  `};
  ${media.phone`
    font-size: 16px;
  `};
  color: rgb(220, 36, 52);
  span {
    font-weight: 400;
    font-size: 12px;
    color: #7d7d7d;
    ${media.phablet`
      display: none;
    `};
  }
`;

const ProductPrice2 = styled.div`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  ${media.phablet`
    font-size: 15px;
    flex-direction: column;
    align-items: flex-start;
  `};
  ${media.phone`
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start;
  `};
  color: rgb(220, 36, 52);
  span {
    font-weight: 400;
    font-size: 13px;
    color: #7d7d7d;
  }
`;

const OriginalPrice = styled.span`
  font-size: 13px !important;
  font-weight: 500 !important;
  ${media.phablet`
    font-size: 12px !important;
  `};
  ${media.phone`
    font-size: 11px !important;
  `};
  color: #282828 !important;
  text-decoration: line-through;
  // margin-left: 3px;
`;

const Discount = (props) => (
  <div
    style={{
      position: 'absolute',
      left: 0,
      bottom: 0,
      fontSize: 12,
      color: 'white',
      background: 'rgb(220, 36, 52)',
      padding: '2px 8px',
      borderTopRightRadius: 5,
      textTransform: 'uppercase',
    }}
  >
    Giảm {props.value}%
  </div>
);

const OutOfStock = () => (
  <div
    style={{
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize: 12,
      color: 'white',
      background: 'rgb(220, 36, 52)',
      padding: '2px 8px',
      borderBottomLeftRadius: 5,
      textTransform: 'uppercase',
    }}
  >
    Hết hàng
  </div>
);

const ProductName = styled.div`
  font-size: 14px;
  white-space: initial;
  height: 30px;
  line-height: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0px;
  ${media.phablet`
    font-size: 13px;
  `};
  ${media.phone`
    font-size: 12px;
  `};
`;

const SHOW_SIZES = ['5c3efa39c8171444aa1f1ad3', '5c3efa1dc8171444aa1f1ad2'];

const ProductCard = ({ data, locale }) => {
  let smallestSize = data.sizes[0] || {};
  data.sizes.forEach(item => {
    if (item.price < smallestSize.price) {
      smallestSize = item;
    }
  });
  return (
    <Card
      onClick={() => navigate(`/detail-product/${data._id}`)}
      hoverable
      cover={(
        <ProductImage alt={data.name} images={data.images}>
          {smallestSize.salePrice && (
            <Discount value={Math.round(100 - ((smallestSize.salePrice * 100) / smallestSize.price))} />
          )}
          {data.isOutOfStock && (
            <OutOfStock />
          )}
        </ProductImage>
      )}
      bodyStyle={{ padding: 10 }}
    >
      <Card.Meta
        title={<ProductName>{locale === 'en' && data.engName ? data.engName : data.name}</ProductName>}
        description={data.category && SHOW_SIZES.includes(typeof data.category === 'string' ? data.category : data.category._id)
          ? (
            <div>
              <ProductPrice2>
                <span>{data.sizes[0].name} </span>
                {data.sizes[0].salePrice
                  ? <div><OriginalPrice style={{color: '#7d7d7d'}}>{`${data.sizes[0].price.toLocaleString()}₫`}</OriginalPrice> {data.sizes[0].salePrice.toLocaleString()}₫</div>
                  : <div>{data.sizes[0].price.toLocaleString()}₫</div>}
              </ProductPrice2>
              {data.sizes.length > 1 ? <ProductPrice2>
                <span>{data.sizes[data.sizes.length - 1].name} </span>
                {data.sizes[data.sizes.length - 1].salePrice
                  ? <div><OriginalPrice style={{color: '#7d7d7d'}}>{`${data.sizes[data.sizes.length - 1].price.toLocaleString()}₫`}</OriginalPrice> {data.sizes[data.sizes.length - 1].salePrice.toLocaleString()}₫</div>
                  : <div>{data.sizes[data.sizes.length - 1].price.toLocaleString()}₫</div>}
              </ProductPrice2> : <div style={{height: 48}} />}
            </div>
          ) : (
            <div>
              {(smallestSize && smallestSize.salePrice)
                ? <ProductPrice>
                    <span><FormattedMessage id='product.fromPrice' /> </span><OriginalPrice>{`${smallestSize.price.toLocaleString()}₫`}</OriginalPrice> <span><FormattedMessage id='product.leftPrice' /> </span>{`${smallestSize.salePrice.toLocaleString()}₫`}
                  </ProductPrice>
                : <ProductPrice>{`${smallestSize.price.toLocaleString()}₫`}</ProductPrice>}
            </div>
          )
        }
      />
    </Card>
  );
};

export default ProductCard;
