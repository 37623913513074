import React, { Component } from 'react';
import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import { Button } from 'antd';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import { getBestSellerPage } from '../actions';
import ProductsSkeleton from '../components/products-skeleton';
import ProductCol from '../components/product-col';

const SectionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
`;

const MoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

class BestSellers extends Component {
  componentDidMount() {
    this.props.getBestSellerPage();
  }

  render() {
    const { data, loading, error, locale } = this.props;

    if (error) return <p><FormattedMessage id='error' /></p>;

    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '70vh' }}>
          <SectionTitle><FormattedMessage id='product.bestSeller' /></SectionTitle>
          {!loading ? <Row>
            {data && data.map(product => (
              <ProductCol key={product._id}>
                <ProductCard locale={locale} data={product} />
              </ProductCol>
            ))}
          </Row> : <ProductsSkeleton />}
          {!loading && <MoreWrapper>
            <Button type='primary' onClick={() => navigate('/product')}><FormattedMessage id='all' /></Button>
          </MoreWrapper>}
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.bestSellerProducts,
  loading: state.page.loading,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getBestSellerPage,
})(BestSellers);
