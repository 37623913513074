import React, { Component } from 'react';
import {
  Input, Button, Table, Avatar, Divider, Form, Modal, Select, Tabs,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getArticleList,
  getArticleCategoryList,
  createArticle,
  updateArticle,
  deleteArticle,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
import Editor from '../../components/editor';
import { query, ENDPOINTS, CDN_URL } from '../../utils/service';

class Article extends Component {
  state = {
    showMedia: false,
    showModal: false,
    modalType: 'Tạo mới',
    selectedRowKeys: [],
    editorContent: '',
    editorContentEng: '',
  }

  componentDidMount() {
    this.props.getArticleList();
    this.props.getArticleCategoryList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false, editorContent: '', editorContentEng: '' });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType, editorContent, editorContentEng } = this.state;
    const { form, createArticle, updateArticle, deleteArticle } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        values.content = editorContent // eslint-disable-line
        values.engContent = editorContentEng // eslint-disable-line
        if (modalType === 'Tạo mới') {
          createArticle(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateArticle(id, values);
        } else if (modalType === 'Xoá') {
          deleteArticle(values._id);
        }
        this.setState({ showModal: false, editorContent: '' });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = async record => {
    this.showModal('Chỉnh sửa');
    this.props.form.setFieldsValue({
      ...record,
      category: record.category._id,
    });
    this.getDetailContent(record._id);
  }

  handleDelete = async record => {
    this.showModal('Xoá');
    this.props.form.setFieldsValue({
      ...record,
      category: record.category._id,
    });
    this.getDetailContent(record._id);
  }

  getDetailContent = async id => {
    this.setState({ editorContent: 'Loading...', editorContentEng: 'Loading...' });
    const { data } = await query({
      endpoint: ENDPOINTS.getArticleDetail + id,
    });
    this.setState({ editorContent: data.article.content, editorContentEng: data.article.engContent });
  }

  render() {
    const { articles, categories, form: { getFieldDecorator, setFieldsValue } } = this.props;
    const { showMedia, showModal, modalType, selectedRowKeys, editorContent, editorContentEng } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <Layout path='/admin/article'>
        <Media
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={file => {
            setFieldsValue({ featuredImage: file.filename });
            this.setState({ showMedia: false });
          }}
        />
        <Modal
          width='70%'
          title={`${modalType} bài viết`}
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tên bài viết!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề (Tiếng Anh)"
            >
              {getFieldDecorator('engTitle')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Hình ảnh'
            >
              {getFieldDecorator('featuredImage', {
                rules: [{ required: true, message: 'Vui lòng chọn hình bài viết!' }],
              })(
                <Input onClick={() => this.setState({ showMedia: true })} disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Danh mục'
            >
              {getFieldDecorator('category', {
                rules: [{
                  required: true, message: 'Vui lòng chọn danh mục!',
                }],
              })(
                <Select>
                  {categories.map(item => (
                    <Select.Option key={item._id} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Nội dung'
            >
              <Tabs defaultActiveKey='1'>
                <Tabs.TabPane tab="Tiếng Việt (*)" key="1">
                  <Editor
                    value={editorContent || ''}
                    onChange={(content) => this.setState({ editorContent: content })}
                    readOnly={modalType === 'Xoá'}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tiếng Anh" key="2">
                  <Editor
                    value={editorContentEng || ''}
                    onChange={(content) => this.setState({ editorContentEng: content })}
                    readOnly={modalType === 'Xoá'}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          title='Danh sách bài viết'
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Ảnh',
              dataIndex: 'featuredImage',
              key: 'featuredImage',
              width: 100,
              render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/THUMB-${data}`} />,
            }, {
              title: 'Tiêu đề',
              key: 'title',
              render: record => <a target='__blank' href={`/detail-article/${record._id}`}>{record.title}</a>
            }, {
              title: 'Danh mục',
              dataIndex: 'category.name',
              key: 'category',
            }, {
              title: 'Người đăng',
              dataIndex: 'createdBy.fullname',
              key: 'createdBy',
              render: data => <a>{data}</a>,
            }, {
              title: 'Ngày đăng',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: data => <span>{moment(data).fromNow()}</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 110,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={articles}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  articles: state.article.list,
  categories: state.article.categoryList,
}), {
  getArticleList,
  getArticleCategoryList,
  createArticle,
  updateArticle,
  deleteArticle,
})(Form.create()(Article));
