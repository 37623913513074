import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import { Button } from 'antd';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import { getFeaturedPage } from '../actions';
import ProductsSkeleton from '../components/products-skeleton';

const SectionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
`;

const MoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

class NewProduct extends Component {
  componentDidMount() {
    this.props.getFeaturedPage();
  }

  render() {
    const { data, loading, error, locale } = this.props;

    if (error) return <p><FormattedMessage id='error' /></p>;

    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '70vh' }}>
          <SectionTitle><FormattedMessage id='product.new' /></SectionTitle>
          {!loading ? <Row>
            {data && data.map(product => (
              <Col key={product._id} xs={6} md={4} xl={3} style={{ marginBottom: 15 }}>
                <ProductCard locale={locale} data={product} />
              </Col>
            ))}
          </Row> : <ProductsSkeleton large />}
          {!loading && <MoreWrapper>
            <Button type='primary' onClick={() => navigate('/product')}><FormattedMessage id='all' /></Button>
          </MoreWrapper>}
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.featuredProducts,
  loading: state.page.loading,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getFeaturedPage,
})(NewProduct);
