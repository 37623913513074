import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_HOME_SECTIONS_REQUEST = 'get-home-sections-request';
export const GET_HOME_SECTIONS_SUCCESS = 'get-home-sections-success';
export const GET_HOME_SECTIONS_FAILURE = 'get-home-sections-failure';

export const GET_PRODUCT_CATEGORY_LIST_REQUEST = 'get-product-category-list-request';
export const GET_PRODUCT_CATEGORY_LIST_SUCCESS = 'get-product-category-list-success';
export const GET_PRODUCT_CATEGORY_LIST_FAILURE = 'get-product-category-list-failure';
export const SEARCH_ADMIN_PRODUCT_REQUEST = 'search-admin-product-request';
export const SEARCH_ADMIN_PRODUCT_SUCCESS = 'search-admin-product-success';
export const SEARCH_ADMIN_PRODUCT_FAILURE = 'search-admin-product-failure';
export const CREATE_PRODUCT_CATEGORY_REQUEST = 'create-product-category-request';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'create-product-category-success';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'create-product-category-failure';
export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'update-product-category-request';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'update-product-category-success';
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'update-product-category-failure';
export const DELETE_PRODUCT_CATEGORY_REQUEST = 'delete-product-category-request';
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'delete-product-category-success';
export const DELETE_PRODUCT_CATEGORY_FAILURE = 'delete-product-category-failure';

export const GET_PRODUCT_SUBCATEGORY_LIST_REQUEST = 'get-product-subcategory-list-request';
export const GET_PRODUCT_SUBCATEGORY_LIST_SUCCESS = 'get-product-subcategory-list-success';
export const GET_PRODUCT_SUBCATEGORY_LIST_FAILURE = 'get-product-subcategory-list-failure';
export const CREATE_PRODUCT_SUBCATEGORY_REQUEST = 'create-product-subcategory-request';
export const CREATE_PRODUCT_SUBCATEGORY_SUCCESS = 'create-product-subcategory-success';
export const CREATE_PRODUCT_SUBCATEGORY_FAILURE = 'create-product-subcategory-failure';
export const UPDATE_PRODUCT_SUBCATEGORY_REQUEST = 'update-product-subcategory-request';
export const UPDATE_PRODUCT_SUBCATEGORY_SUCCESS = 'update-product-subcategory-success';
export const UPDATE_PRODUCT_SUBCATEGORY_FAILURE = 'update-product-subcategory-failure';
export const DELETE_PRODUCT_SUBCATEGORY_REQUEST = 'delete-product-subcategory-request';
export const DELETE_PRODUCT_SUBCATEGORY_SUCCESS = 'delete-product-subcategory-success';
export const DELETE_PRODUCT_SUBCATEGORY_FAILURE = 'delete-product-subcategory-failure';

export const GET_PRODUCT_LIST_REQUEST = 'get-product-list-request';
export const GET_PRODUCT_LIST_SUCCESS = 'get-product-list-success';
export const GET_PRODUCT_LIST_FAILURE = 'get-product-list-failure';
export const CREATE_PRODUCT_REQUEST = 'create-product-request';
export const CREATE_PRODUCT_SUCCESS = 'create-product-success';
export const CREATE_PRODUCT_FAILURE = 'create-product-failure';
export const UPDATE_PRODUCT_REQUEST = 'update-product-request';
export const UPDATE_PRODUCT_SUCCESS = 'update-product-success';
export const UPDATE_PRODUCT_FAILURE = 'update-product-failure';
export const DELETE_PRODUCT_REQUEST = 'delete-product-request';
export const DELETE_PRODUCT_SUCCESS = 'delete-product-success';
export const DELETE_PRODUCT_FAILURE = 'delete-product-failure';
export const SEARCH_PRODUCT_REQUEST = 'search-product-request';
export const SEARCH_PRODUCT_SUCCESS = 'search-product-success';
export const SEARCH_PRODUCT_FAILURE = 'search-product-failure';
export const PRIORITIZE_PRODUCT_REQUEST = 'prioritize-product-request';
export const PRIORITIZE_PRODUCT_SUCCESS = 'prioritize-product-success';
export const PRIORITIZE_PRODUCT_FAILURE = 'prioritize-product-failure';
export const CHANGE_SEARCH_TEXT = 'change-search-text';

export function changeSearchText(value) {
  return {
    type: CHANGE_SEARCH_TEXT,
    payload: value,
  };
}

export function getProductCategoryList() {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_CATEGORY_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductCategoryList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_CATEGORY_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_CATEGORY_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_CATEGORY_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getHomeSections() {
  return async dispatch => {
    dispatch({ type: GET_HOME_SECTIONS_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getHomeSections,
      });
      if (result.status === 200) {
        dispatch({ type: GET_HOME_SECTIONS_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_HOME_SECTIONS_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_HOME_SECTIONS_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createProductCategory(data) {
  return async dispatch => {
    dispatch({ type: CREATE_PRODUCT_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createProductCategory,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_PRODUCT_CATEGORY_SUCCESS, payload: result.data });
        message.success('Tạo danh mục thành công');
      } else {
        dispatch({ type: CREATE_PRODUCT_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_PRODUCT_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateProductCategory(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_PRODUCT_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateProductCategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_PRODUCT_CATEGORY_SUCCESS, payload: result.data });
        message.success('Cập nhật danh mục thành công');
      } else {
        dispatch({ type: UPDATE_PRODUCT_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_PRODUCT_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteProductCategory(id) {
  return async dispatch => {
    dispatch({ type: DELETE_PRODUCT_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteProductCategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_PRODUCT_CATEGORY_SUCCESS, payload: result.data });
        message.success('Xoá danh mục thành công');
      } else {
        dispatch({ type: DELETE_PRODUCT_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_PRODUCT_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductSubcategoryList() {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_SUBCATEGORY_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductSubcategoryList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_SUBCATEGORY_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_SUBCATEGORY_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_SUBCATEGORY_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createProductSubcategory(data) {
  return async dispatch => {
    dispatch({ type: CREATE_PRODUCT_SUBCATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createProductSubcategory,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_PRODUCT_SUBCATEGORY_SUCCESS, payload: result.data });
        message.success('Tạo danh mục thành công');
      } else {
        dispatch({ type: CREATE_PRODUCT_SUBCATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_PRODUCT_SUBCATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateProductSubcategory(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_PRODUCT_SUBCATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateProductSubcategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_PRODUCT_SUBCATEGORY_SUCCESS, payload: result.data });
        message.success('Cập nhật danh mục thành công');
      } else {
        dispatch({ type: UPDATE_PRODUCT_SUBCATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_PRODUCT_SUBCATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteProductSubcategory(id) {
  return async dispatch => {
    dispatch({ type: DELETE_PRODUCT_SUBCATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteProductSubcategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_PRODUCT_SUBCATEGORY_SUCCESS, payload: result.data });
        message.success('Xoá danh mục thành công');
      } else {
        dispatch({ type: DELETE_PRODUCT_SUBCATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_PRODUCT_SUBCATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductList({ skip, filters, sorter }) {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: {
          limit: 10,
          skip: skip * 10 || 0,
          sorter,
          ...filters,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function searchProduct(search) {
  return async dispatch => {
    dispatch({ type: SEARCH_PRODUCT_REQUEST });
    if (!search || !search.length) {
      dispatch({ type: SEARCH_PRODUCT_FAILURE });
    } else {
      try {
        const result = await query({
          endpoint: ENDPOINTS.getProductList,
          params: { search },
        });
        if (result.status === 200) {
          dispatch({ type: SEARCH_PRODUCT_SUCCESS, payload: result.data });
        } else {
          dispatch({ type: SEARCH_PRODUCT_FAILURE });
          message.error('Có lỗi xảy ra');
        }
      } catch (error) {
        dispatch({ type: SEARCH_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    }
  };
}

export function searchAdminProduct({ search, skip, filters, sorter }) {
  return async dispatch => {
    dispatch({ type: SEARCH_ADMIN_PRODUCT_REQUEST });
    if (!search || !search.length) {
      dispatch({ type: SEARCH_ADMIN_PRODUCT_FAILURE });
    } else {
      try {
        const result = await query({
          endpoint: ENDPOINTS.getProductList,
          params: {
            search,
            limit: 10,
            skip: skip * 10 || 0,
            sorter,
            ...filters,
          },
        });
        if (result.status === 200) {
          dispatch({ type: SEARCH_ADMIN_PRODUCT_SUCCESS, payload: result.data });
        } else {
          dispatch({ type: SEARCH_ADMIN_PRODUCT_FAILURE });
          message.error('Có lỗi xảy ra');
        }
      } catch (error) {
        dispatch({ type: SEARCH_ADMIN_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    }
  };
}

export function createProduct(data, callback) {
  return async dispatch => {
    dispatch({ type: CREATE_PRODUCT_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createProduct,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: result.data });
        message.success('Tạo sản phẩm thành công');
        callback();
      } else {
        dispatch({ type: CREATE_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_PRODUCT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateProduct(id, data, callback) {
  return async dispatch => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateProduct + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: result.data });
        message.success('Cập nhật sản phẩm thành công');
        callback();
      } else {
        dispatch({ type: UPDATE_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
        console.log(result);
      }
    } catch (error) {
      dispatch({ type: UPDATE_PRODUCT_FAILURE });
      message.error('Có lỗi xảy ra');
      console.log(error);
    }
  };
}

export function prioritizeProduct(id, callback) {
  return async dispatch => {
    dispatch({ type: PRIORITIZE_PRODUCT_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        endpoint: `${ENDPOINTS.updateProduct + id}/top`,
      });
      if (result.status === 200) {
        dispatch({ type: PRIORITIZE_PRODUCT_SUCCESS });
        message.success('Ưu tiên sản phẩm thành công');
        callback();
      } else {
        dispatch({ type: PRIORITIZE_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: PRIORITIZE_PRODUCT_FAILURE });
      message.error('Có lỗi xảy ra');
      console.log(error);
    }
  };
}

export function deleteProduct(id, callback) {
  return async dispatch => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteProduct + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: result.data });
        message.success('Xoá sản phẩm thành công');
        callback();
      } else {
        dispatch({ type: DELETE_PRODUCT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_PRODUCT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
