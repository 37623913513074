import React, { Component } from 'react';
import {
  Input, Button, Table, Avatar, Divider, Form, Modal, Tabs, Icon,
} from 'antd';
import { connect } from 'react-redux';

import {
  getLogoList,
  createLogo,
  updateLogo,
  deleteLogo,
} from '../../../actions';
import { Paper } from '../../../layouts/admin';
import Media from '../../../components/media';
import { CDN_URL } from '../../../utils/service';

class Banner extends Component {
  state = {
    showModal: false,
    modalType: 'Tạo mới',
    showMedia: false,
  }

  componentDidMount() {
    this.props.getLogoList();
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  showModalMobile = modalType => this.setState({
    showModalMobile: true,
    modalType,
  })

  hideModalMobile = () => {
    this.setState({ showModalMobile: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createLogo, updateLogo, deleteLogo } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        values.type = 2; // eslint-disable-line
        if (modalType === 'Tạo mới') {
          createLogo(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateLogo(id, values);
        } else if (modalType === 'Xoá') {
          deleteLogo(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleSubmitMobile = e => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createLogo, updateLogo, deleteLogo } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        values.type = 5; // eslint-disable-line
        if (modalType === 'Tạo mới') {
          createLogo(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateLogo(id, values);
        } else if (modalType === 'Xoá') {
          deleteLogo(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Xoá');
  }

  handleEditMobile = record => {
    this.props.form.setFieldsValue(record);
    this.showModalMobile('Chỉnh sửa');
  }

  handleDeleteMobile = record => {
    this.props.form.setFieldsValue(record);
    this.showModalMobile('Xoá');
  }

  render() {
    const { list, listMobile, form: { getFieldDecorator, setFieldsValue } } = this.props;
    const {
      showModal,
      showModalMobile,
      modalType,
      showMedia,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (

      <Paper
        style={{ minHeight: 'calc(100vh - 230px)' }}
        // extra={<div>
        //   <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
        //   <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
        // </div>}
      >
        <Media
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={file => {
            setFieldsValue({ featuredImage: file.filename });
            this.setState({ showMedia: false });
          }}
        />
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane tab={<span><Icon type="desktop" />Desktop</span>} key='1'>
            <Modal
              title={`${modalType} banner`}
              visible={showModal}
              onOk={this.handleSubmit}
              onCancel={this.hideModal}
              footer={[
                <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmit}>
                {getFieldDecorator('_id')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Liên kết"
                >
                  {getFieldDecorator('link', {
                    rules: [{
                      required: true, message: 'Vui lòng nhập liên kết!',
                    }],
                  })(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Hình ảnh'
                >
                  {getFieldDecorator('featuredImage', {
                    rules: [{ required: true, message: 'Vui lòng chọn hình!' }],
                  })(
                    <Input onClick={() => this.setState({ showMedia: true })} disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Tiêu đề phụ'
                >
                  {getFieldDecorator('subTitle')(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Tiêu đề chính'
                >
                  {getFieldDecorator('title')(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Chi tiết'
                >
                  {getFieldDecorator('description')(
                    <Input.TextArea disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Table
              bordered
              rowKey={record => record._id}
              columns={[{
                title: 'Ảnh',
                dataIndex: 'featuredImage',
                key: 'featuredImage',
                width: 100,
                render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/THUMB-${data}`} />,
              }, {
                title: 'Đường dẫn',
                dataIndex: 'link',
                key: 'link',
                render: text => <a href={text} target='__blank'>{text}</a>,
              }, {
                title: 'Tiêu đề',
                dataIndex: 'title',
                key: 'title',
              }, {
                title: 'Hành động',
                key: 'action',
                width: 110,
                render: (record) => (
                  <span>
                    <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                    <Divider type="vertical" />
                    <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                  </span>
                ),
              }]}
              dataSource={list}
            />
            <Button style={{ marginTop: 15 }} onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span><Icon type="mobile" />Mobile</span>} key='2'>
            <Modal
              title={`${modalType} banner mobile`}
              visible={showModalMobile}
              onOk={this.handleSubmitMobile}
              onCancel={this.hideModalMobile}
              footer={[
                <Button key="back" onClick={this.hideModalMobile}>Huỷ</Button>,
                <Button key="submit" type="primary" onClick={this.handleSubmitMobile}>Xác nhận</Button>,
              ]}
            >
              <Form onSubmit={this.handleSubmit}>
                {getFieldDecorator('_id')(
                  <Input type="hidden" />
                )}
                <Form.Item
                  {...formItemLayout}
                  label="Liên kết"
                >
                  {getFieldDecorator('link', {
                    rules: [{
                      required: true, message: 'Vui lòng nhập liên kết!',
                    }],
                  })(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Hình ảnh'
                >
                  {getFieldDecorator('featuredImage', {
                    rules: [{ required: true, message: 'Vui lòng chọn hình!' }],
                  })(
                    <Input onClick={() => this.setState({ showMedia: true })} disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Tiêu đề phụ'
                >
                  {getFieldDecorator('subTitle')(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Tiêu đề chính'
                >
                  {getFieldDecorator('title')(
                    <Input disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='Chi tiết'
                >
                  {getFieldDecorator('description')(
                    <Input.TextArea disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
              </Form>
            </Modal>
            <Table
              bordered
              rowKey={record => record._id}
              columns={[{
                title: 'Ảnh',
                dataIndex: 'featuredImage',
                key: 'featuredImage',
                width: 100,
                render: data => <Avatar shape="square" size={64} icon="picture" src={`${CDN_URL}/THUMB-${data}`} />,
              }, {
                title: 'Đường dẫn',
                dataIndex: 'link',
                key: 'link',
                render: text => <a href={text} target='__blank'>{text}</a>,
              }, {
                title: 'Tiêu đề',
                dataIndex: 'title',
                key: 'title',
              }, {
                title: 'Hành động',
                key: 'action',
                width: 110,
                render: (record) => (
                  <span>
                    <a role='button' onClick={() => this.handleEditMobile(record)}>Sửa</a>
                    <Divider type="vertical" />
                    <a role='button' onClick={() => this.handleDeleteMobile(record)}>Xoá</a>
                  </span>
                ),
              }]}
              dataSource={listMobile}
            />
            <Button style={{ marginTop: 15 }} onClick={() => this.showModalMobile('Tạo mới')} type='primary'>Tạo mới</Button>
          </Tabs.TabPane>
        </Tabs>
      </Paper>

    );
  }
}

export default connect(state => ({
  list: state.home.bannerList,
  listMobile: state.home.bannerMobileList,
}), {
  getLogoList,
  createLogo,
  updateLogo,
  deleteLogo,
})(Form.create()(Banner));
