import React, { Fragment } from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import ProductsSkeleton from './products-skeleton';

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
`;

const InfoWrapper = styled.div`
  min-height: 400px;
  padding-left: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #90A3C2;
  margin: 10px 0px;
  opacity: 0.3;
`;

const Content = styled.div`
  font-size: 14px;
`;

const ContentTitle = styled.h3`
  margin-bottom: 15px;
  span {
    font-size: 18px;
    color: #134E8F;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    color: #90A3C2;
    margin: 0px;
    display: inline;
    font-weight: 400;
    margin-left: 10px;
  }
`;

const DetailProductSkeleton = () => (
  <Fragment>
    <Wrapper style={{ padding: '20px 20px 10px' }}>
      <Row>
        <Col xs={12} md={6}>
          <ContentLoader
            speed={2}
            primaryColor="#f3f3f3"
            style={{ width: '100%', height: '100%' }}
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </Col>
        <Col xs={12} md={6}>
          <InfoWrapper>
            <ContentLoader
              speed={2}
              primaryColor="#f3f3f3"
              style={{ width: '40%', height: 28 }}
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
            </ContentLoader>
            <Divider />
            <ContentLoader
              speed={2}
              primaryColor="#f3f3f3"
              style={{ width: '20%', height: 15 }}
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
            </ContentLoader>
            <Divider />
          </InfoWrapper>
        </Col>
      </Row>
    </Wrapper>
    <Wrapper style={{ padding: 20 }}>
      <Content>
        <ContentTitle>
          <FormattedMessage id='product.detail' />
        </ContentTitle>
        <ContentLoader
          speed={2}
          primaryColor="#f3f3f3"
          style={{ width: '100%', height: 15, marginBottom: 15 }}
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          primaryColor="#f3f3f3"
          style={{ width: '100%', height: 15, marginBottom: 15 }}
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          primaryColor="#f3f3f3"
          style={{ width: '40%', height: 15 }}
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
      </Content>
    </Wrapper>
    <Wrapper style={{ padding: '20px 20px 0px' }}>
      <ContentTitle><FormattedMessage id='product.related' /></ContentTitle>
      <ProductsSkeleton />
    </Wrapper>
  </Fragment>
);

export default DetailProductSkeleton;
