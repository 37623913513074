import React, { Component } from 'react';
import {
  Input, Button, Table, Form, Modal, Divider,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import excerptHtml from 'excerpt-html';

import {
  getContactFormList,
  createContactForm,
  deleteContactForm,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';

class ContactForm extends Component {
  state = {
    showModal: false,
    modalType: 'Chi tiết',
    selectedRowKeys: [],
    editorContent: '',
  }

  componentDidMount() {
    this.props.getContactFormList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createContactForm, updateContactForm, deleteContactForm } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (modalType === 'Xoá') {
          deleteContactForm(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Xoá');
  }

  handleView = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Chi tiết');
  }

  render() {
    const { data, form: { getFieldDecorator } } = this.props;
    const { showModal, modalType, selectedRowKeys } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <Layout path='/admin/contact-form'>
        <Modal
          width='70%'
          title={`${modalType} lời nhắn liên hệ`}
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xong</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Họ"
            >
              {getFieldDecorator('firstName')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Tên'
            >
              {getFieldDecorator('lastName')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Email'
            >
              {getFieldDecorator('email')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Số điện thoại'
            >
              {getFieldDecorator('phone')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Lời nhắn'
            >
              {getFieldDecorator('content')(
                <Input.TextArea rows={5} disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          title='Danh sách lời nhắn liên hệ'
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Tên người gửi',
              key: 'creator',
              render: record => <span>{`${record.firstName} ${record.lastName}`}</span>,
            }, {
              title: 'Số điện thoại',
              dataIndex: 'phone',
              key: 'phone',
              render: text => <a href={`tel:${text}`}>{text}</a>,
            }, {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              render: text => <a href={`mailto:${text}`}>{text}</a>,
            }, {
              title: 'Ngày đăng',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: text => <span>{moment(text).fromNow()}</span>,
            }, {
              title: 'Nội dung',
              dataIndex: 'content',
              key: 'content',
              render: text => <span>{excerptHtml(text, { pruneLength: 50 })}</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 120,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleView(record)}>Xem</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={data}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.contactForm.list,
}), {
  getContactFormList,
  createContactForm,
  deleteContactForm,
})(Form.create()(ContactForm));
