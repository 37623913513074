import { useEffect, useState } from "react";

import {sizes} from './media';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useWindowMedia() {
  const {width} = useWindowSize()

  return Object.keys(sizes).reduce((acc, cur) => ({
    ...acc,
    [cur]: sizes[cur] >= width,
  }), {})
}

export function useIsWidthLarger(inputSize) {
  const {width} = useWindowSize()

  if (!inputSize) return null;

  if (typeof inputSize === 'number') return inputSize <= width

  return inputSize.map(size => size <= width)
}
