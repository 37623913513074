import {
  GET_ORDER_LIST_REQUEST, GET_ORDER_LIST_SUCCESS, GET_ORDER_LIST_FAILURE,
  GET_MY_ORDER_LIST_REQUEST, GET_MY_ORDER_LIST_SUCCESS, GET_MY_ORDER_LIST_FAILURE,
  GET_ORDER_DETAIL_REQUEST, GET_ORDER_DETAIL_SUCCESS, GET_ORDER_DETAIL_FAILURE,
  UPDATE_STATUS_REQUEST, UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAILURE,
  CREATE_ORDER_SUCCESS,
  DELETE_ORDER_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  requesting: false,
  updating: false,
  detailFetching: false,
  detail: null,
  list: [],
  total: 0,
  myList: [],
  myTotal: 0,
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        list: action.payload.orders,
        total: action.payload.total,
      };
    case GET_ORDER_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case GET_MY_ORDER_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_MY_ORDER_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        myList: action.payload.orders,
        myTotal: action.payload.total,
      };
    case GET_MY_ORDER_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case UPDATE_STATUS_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        updating: false,
        detail: action.payload,
        list: state.list.map(item => (item._id === action.payload._id
          ? { ...item, currentStatus: action.payload.currentStatus }
          : item)),
      };
    case UPDATE_STATUS_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        detailFetching: true,
      };
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        detailFetching: false,
        detail: action.payload,
      };
    case GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        detailFetching: false,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => (item._id !== action.payload._id)),
      };
    default:
      return state;
  }
};

export default reducers;