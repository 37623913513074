import moment from 'moment';

export const TOGGLE_MENU = 'toggle-menu';
export const CHANGE_LOCALE = 'change-locale';

export const toggleMenu = () => ({ type: TOGGLE_MENU });

export const changeLocale = locale => {
  moment.locale(locale);
  return { type: CHANGE_LOCALE, payload: locale };
};
