import { message } from 'antd';
import { query, ENDPOINTS } from '../utils/service';

export const GET_ORDER_LIST_SUCCESS = 'get-order-list-success';
export const GET_ORDER_LIST_FAILURE = 'get-order-list-failure';
export const GET_ORDER_LIST_REQUEST = 'get-order-list-request';
export const GET_MY_ORDER_LIST_SUCCESS = 'get-my-order-list-success';
export const GET_MY_ORDER_LIST_FAILURE = 'get-my-order-list-failure';
export const GET_MY_ORDER_LIST_REQUEST = 'get-my-order-list-request';
export const GET_ORDER_DETAIL_SUCCESS = 'get-order-detail-success';
export const GET_ORDER_DETAIL_FAILURE = 'get-order-detail-failure';
export const GET_ORDER_DETAIL_REQUEST = 'get-order-detail-request';
export const CREATE_ORDER_SUCCESS = 'create-order-success';
export const CREATE_ORDER_FAILURE = 'create-order-failure';
export const CREATE_ORDER_REQUEST = 'create-order-request';
export const UPDATE_STATUS_SUCCESS = 'update-status-success';
export const UPDATE_STATUS_FAILURE = 'update-status-failure';
export const UPDATE_STATUS_REQUEST = 'update-status-request';
export const DELETE_ORDER_SUCCESS = 'delete-order-success';
export const DELETE_ORDER_FAILURE = 'delete-order-failure';
export const DELETE_ORDER_REQUEST = 'delete-order-request';

export function getOrderList() {
  return async dispatch => {
    dispatch({ type: GET_ORDER_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getOrderList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ORDER_LIST_FAILURE });
      }
    } catch (error) {
      dispatch({ type: GET_ORDER_LIST_FAILURE });
    }
  };
}

export function getMyOrderList() {
  return async dispatch => {
    dispatch({ type: GET_MY_ORDER_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getMyOrderList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_MY_ORDER_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_MY_ORDER_LIST_FAILURE });
      }
    } catch (error) {
      dispatch({ type: GET_MY_ORDER_LIST_FAILURE });
    }
  };
}

export function getOrderDetail(id) {
  return async dispatch => {
    dispatch({ type: GET_ORDER_DETAIL_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getOrderDetail + id,
      });
      if (result.status === 200) {
        dispatch({ type: GET_ORDER_DETAIL_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ORDER_DETAIL_FAILURE });
      }
    } catch (error) {
      dispatch({ type: GET_ORDER_DETAIL_FAILURE });
    }
  };
}

export function createOrder(data, callback) {
  return async dispatch => {
    dispatch({ type: CREATE_ORDER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.createOrder,
        data,
        method: 'POST',
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: result.data });
        callback.success();
      } else {
        dispatch({ type: CREATE_ORDER_FAILURE });
        callback.failure();
      }
    } catch (error) {
      dispatch({ type: CREATE_ORDER_FAILURE });
      console.log(error);
      // callback.failure();
    }
  };
}

export function deleteOrder(id) {
  return async dispatch => {
    dispatch({ type: DELETE_ORDER_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.deleteOrder + id,
        method: 'DELETE',
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_ORDER_SUCCESS, payload: result.data });
        message.success('Xoá đơn hàng thành công');
      } else {
        dispatch({ type: DELETE_ORDER_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_ORDER_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateStatus(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_STATUS_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.updateStatus + id,
        method: 'PATCH',
        data,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_STATUS_SUCCESS, payload: result.data });
        message.success('Cập nhật trạng thái thành công');
      } else {
        dispatch({ type: UPDATE_STATUS_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_STATUS_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
