import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_ARTICLE_LIST_REQUEST = 'get-article-list-request';
export const GET_ARTICLE_LIST_SUCCESS = 'get-article-list-success';
export const GET_ARTICLE_LIST_FAILURE = 'get-article-list-failure';
export const CREATE_ARTICLE_REQUEST = 'create-article-request';
export const CREATE_ARTICLE_SUCCESS = 'create-article-success';
export const CREATE_ARTICLE_FAILURE = 'create-article-failure';
export const UPDATE_ARTICLE_REQUEST = 'update-article-request';
export const UPDATE_ARTICLE_SUCCESS = 'update-article-success';
export const UPDATE_ARTICLE_FAILURE = 'update-article-failure';
export const DELETE_ARTICLE_REQUEST = 'delete-article-request';
export const DELETE_ARTICLE_SUCCESS = 'delete-article-success';
export const DELETE_ARTICLE_FAILURE = 'delete-article-failure';

export const GET_ARTICLE_CATEGORY_LIST_REQUEST = 'get-article-category-list-request';
export const GET_ARTICLE_CATEGORY_LIST_SUCCESS = 'get-article-category-list-success';
export const GET_ARTICLE_CATEGORY_LIST_FAILURE = 'get-article-category-list-failure';
export const CREATE_ARTICLE_CATEGORY_REQUEST = 'create-article-category-request';
export const CREATE_ARTICLE_CATEGORY_SUCCESS = 'create-article-category-success';
export const CREATE_ARTICLE_CATEGORY_FAILURE = 'create-article-category-failure';
export const UPDATE_ARTICLE_CATEGORY_REQUEST = 'update-article-category-request';
export const UPDATE_ARTICLE_CATEGORY_SUCCESS = 'update-article-category-success';
export const UPDATE_ARTICLE_CATEGORY_FAILURE = 'update-article-category-failure';
export const DELETE_ARTICLE_CATEGORY_REQUEST = 'delete-article-category-request';
export const DELETE_ARTICLE_CATEGORY_SUCCESS = 'delete-article-category-success';
export const DELETE_ARTICLE_CATEGORY_FAILURE = 'delete-article-category-failure';

export function getArticleList() {
  return async dispatch => {
    dispatch({ type: GET_ARTICLE_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getArticleList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_ARTICLE_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ARTICLE_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_ARTICLE_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createArticle(data) {
  return async dispatch => {
    dispatch({ type: CREATE_ARTICLE_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createArticle,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_ARTICLE_SUCCESS, payload: result.data });
        message.success('Tạo bài viết thành công');
      } else {
        dispatch({ type: CREATE_ARTICLE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_ARTICLE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateArticle(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_ARTICLE_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateArticle + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_ARTICLE_SUCCESS, payload: result.data });
        message.success('Cập nhật bài viết thành công');
      } else {
        dispatch({ type: UPDATE_ARTICLE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_ARTICLE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteArticle(id) {
  return async dispatch => {
    dispatch({ type: DELETE_ARTICLE_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteArticle + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_ARTICLE_SUCCESS, payload: result.data });
        message.success('Xoá bài viết thành công');
      } else {
        dispatch({ type: DELETE_ARTICLE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_ARTICLE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getArticleCategoryList() {
  return async dispatch => {
    dispatch({ type: GET_ARTICLE_CATEGORY_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getArticleCategoryList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_ARTICLE_CATEGORY_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ARTICLE_CATEGORY_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_ARTICLE_CATEGORY_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createArticleCategory(data) {
  return async dispatch => {
    dispatch({ type: CREATE_ARTICLE_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createArticleCategory,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_ARTICLE_CATEGORY_SUCCESS, payload: result.data });
        message.success('Tạo danh mục bài viết thành công');
      } else {
        dispatch({ type: CREATE_ARTICLE_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_ARTICLE_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateArticleCategory(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_ARTICLE_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateArticleCategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_ARTICLE_CATEGORY_SUCCESS, payload: result.data });
        message.success('Cập nhật danh mục bài viết thành công');
      } else {
        dispatch({ type: UPDATE_ARTICLE_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_ARTICLE_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteArticleCategory(id) {
  return async dispatch => {
    dispatch({ type: DELETE_ARTICLE_CATEGORY_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteArticleCategory + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_ARTICLE_CATEGORY_SUCCESS, payload: result.data });
        message.success('Xoá danh mục bài viết thành công');
      } else {
        dispatch({ type: DELETE_ARTICLE_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_ARTICLE_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
