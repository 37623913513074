import React from 'react';
import { MessengerChat } from "react-messenger-chat-plugin";

const Messenger = () => {
  return (
    <MessengerChat
      pageId="927283310803626"
      language="vi_VN"
      themeColor={"#16A755"}
      bottomSpacing={30}
      debugMode={true}
    />
  )
}

export default Messenger;
