import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate, Link } from '@reach/router';
import {
  Form, Icon, Input, Button, Layout,
} from 'antd';

import { registerUser } from '../../actions';
import { Logo } from '../../layouts/admin';

class Register extends Component {
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.registerUser(values, () => navigate('/user'));
      }
    });
  }

  render() {
    const { form: { getFieldDecorator }, isLogged } = this.props;

    if (isLogged) {
      navigate('/user/dashboard');
    }

    return (
      <Layout style={{ minHeight: '100vh', paddingTop: '18vh' }}>
        <Layout.Content style={{ minWidth: 300, maxWidth: 500, margin: '0 auto' }}>
          <Logo style={{ marginBottom: 50 }} />
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('fullname', {
                rules: [{ required: true, message: 'Vui lòng điền họ và tên của bạn!' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Họ và tên" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Vui lòng điền tên tài khoản!' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Tên tài khoản" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Vui lòng điền email!' }],
              })(
                <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Địa chỉ email" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: 'Vui lòng điền số điện thoại!' }],
              })(
                <Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Số điện thoại" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Vui lòng điền mật khẩu!' }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Mật khẩu" />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
              Đăng ký
              </Button>
              Hoặc <Link to="/user">đăng nhập</Link>
            </Form.Item>
          </Form>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>
          Thien Ha Corp © 2018 | Developed by <a href='https://innoteq.vn' target='__blank'>Innoteq Ltd</a>
        </Layout.Footer>
      </Layout>
    );
  }
}

export default connect(state => ({
  isLogged: state.user.isLogged,
  userInfo: state.user.userInfo,
}), {
  registerUser,
})(Form.create()(Register));
