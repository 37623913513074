import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Layout from '../layouts/layout';
import Wrapper from '../components/wrapper';
import ProductCard from '../components/product-card';
import ProductsSkeleton from '../components/products-skeleton';
import { getProductByCategory } from '../actions';
import ProductCol from '../components/product-col';

const SectionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
`;

class CategoryPage extends Component {
  componentDidMount() {
    this.props.getProductByCategory(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.props.getProductByCategory(nextProps.id);
    }
  }

  render() {
    const { data, loading, error, locale } = this.props;

    return (
      <Layout locale={locale}>
        <Wrapper style={{ minHeight: '50vh' }}>
          <SectionTitle>
            {(!data || !data.length)
              ? <FormattedMessage id='category' />
              : locale === 'en' && data[0].category.engName ? data[0].category.engName : data[0].category.name}
          </SectionTitle>
          {error && <p><FormattedMessage id='error' /></p>}
          <Grid fluid>
            {!loading ? <Row>
              {data && data.length ? data.map(product => (
                <ProductCol key={product._id}>
                  <ProductCard locale={locale} data={product} />
                </ProductCol>
              )) : <p><FormattedMessage id='noData' /></p>}
            </Row> : <ProductsSkeleton large />}
          </Grid>
        </Wrapper>
      </Layout>
    );
  }
}

export default connect(state => ({
  // data: state.page.product,
  data: state.page.productCategory,
  loading: state.page.fetchProductCategory,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getProductByCategory,
})(CategoryPage);
