import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Modal, Input, Form, Button, message } from 'antd';

import { updateUser } from '../../actions';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

class UserInfo extends Component {
  state = {
    showUpdateInfoModal: false,
    updateInfoFields: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
    },
    showChangePwModal: false,
    changePwFields: {
      password: '',
      confirm: '',
    },
  }

  componentDidMount = () => {
    const { userInfo } = this.props;
    if (userInfo) {
      this.setState({
        updateInfoFields: {
          fullname: userInfo.fullname || '',
          email: userInfo.email || '',
          phone: userInfo.phone || '',
          address: userInfo.address || '',
        },
      });
    }
  }

  hideUpdateInfoModal = () => {
    const { userInfo } = this.props;
    if (userInfo) {
      this.setState({
        showUpdateInfoModal: false,
        updateInfoFields: {
          fullname: userInfo.fullname || '',
          email: userInfo.email || '',
          phone: userInfo.phone || '',
          address: userInfo.address || '',
        },
      });
    }
  }

  hideChangePwModal = () => this.setState({
    showChangePwModal: false,
    changePwFields: {
      password: '',
      confirm: '',
    },
  })

  handleUpdateInfo = () => {
    const { updateInfoFields } = this.state;
    const { updateUser, userInfo } = this.props; // eslint-disable-line
    if (!updateInfoFields.fullname.length || !updateInfoFields.email.length || !updateInfoFields.phone.length) {
      message.error('Vui lòng nhập đầy đủ thông tin');
    } else {
      updateUser(userInfo._id, updateInfoFields);
      this.hideUpdateInfoModal();
    }
  }

  handleChangePw = () => {
    const { changePwFields } = this.state;
    const { updateUser, userInfo } = this.props; // eslint-disable-line
    if (!changePwFields.password.length || !changePwFields.confirm.length) {
      message.error('Vui lòng nhập đủ thông tin');
    } else if (changePwFields.password !== changePwFields.confirm) {
      message.error('Mật khẩu xác nhận không khớp');
    } else {
      updateUser(userInfo._id, { password: changePwFields.password });
      this.hideChangePwModal();
    }
  }

  render() {
    const { userInfo } = this.props;
    const { showUpdateInfoModal, updateInfoFields, showChangePwModal, changePwFields } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div>
        <Modal
          title='Thay đổi mật khẩu'
          visible={showChangePwModal}
          onOk={this.handleChangePw}
          onCancel={this.hideChangePwModal}
          footer={[
            <Button key="back" onClick={this.hideChangePwModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleChangePw}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleChangePw}>
            <Form.Item
              {...formItemLayout}
              label="Mật khẩu mới (*)"
            >
              <Input
                value={changePwFields.password}
                onChange={e => this.setState({ changePwFields: { ...changePwFields, password: e.target.value } })}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Nhập lại lần nữa (*)"
            >
              <Input
                value={changePwFields.confirm}
                onChange={e => this.setState({ changePwFields: { ...changePwFields, confirm: e.target.value } })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title='Chỉnh sửa thông tin'
          visible={showUpdateInfoModal}
          onOk={this.handleUpdateInfo}
          onCancel={this.hideUpdateInfoModal}
          footer={[
            <Button key="back" onClick={this.hideUpdateInfoModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleUpdateInfo}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleUpdateInfo}>
            <Form.Item
              {...formItemLayout}
              label="Họ và tên (*)"
            >
              <Input
                value={updateInfoFields.fullname}
                onChange={e => this.setState({ updateInfoFields: { ...updateInfoFields, fullname: e.target.value } })}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Địa chỉ email (*)"
            >
              <Input
                value={updateInfoFields.email}
                onChange={e => this.setState({ updateInfoFields: { ...updateInfoFields, email: e.target.value } })}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Số điện thoại (*)"
            >
              <Input
                value={updateInfoFields.phone}
                onChange={e => this.setState({ updateInfoFields: { ...updateInfoFields, phone: e.target.value } })}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Địa chỉ nhận hàng"
            >
              <Input
                value={updateInfoFields.address}
                onChange={e => this.setState({ updateInfoFields: { ...updateInfoFields, address: e.target.value } })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <h2>Tài Khoản</h2>
        <Flex style={{ marginTop: 30 }}>
          <h4>Thông tin cá nhân</h4>
          <a role='button' onClick={() => this.setState({ showUpdateInfoModal: true })}>Sửa</a>
        </Flex>
        <Flex style={{ marginTop: 10 }}>
          <p><Icon type="user" /> Họ tên</p>
          {userInfo && <strong>{userInfo.fullname}</strong>}
        </Flex>
        <Flex>
          <p><Icon type="mail" /> Địa chỉ email</p>
          {userInfo && <strong>{userInfo.email}</strong>}
        </Flex>
        <Flex>
          <p><Icon type="phone" /> Số điện thoại</p>
          {userInfo && <strong>{userInfo.phone || 'chưa cập nhật'}</strong>}
        </Flex>
        <Flex>
          <p><Icon type="environment" /> Địa chỉ nhận hàng</p>
          {userInfo && <strong>{userInfo.address || 'chưa cập nhật'}</strong>}
        </Flex>
        <Flex style={{ marginTop: 15 }}>
          <h4>Mật khẩu</h4>
          <a role='button' onClick={() => this.setState({ showChangePwModal: true })}>Đổi mật khẩu</a>
        </Flex>
      </div>
    );
  }
}

export default connect(state => ({
  userInfo: state.user.userInfo,
}), {
  updateUser,
})(UserInfo);
