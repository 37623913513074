import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table, Divider, Input, Button, Modal, Form, Cascader,
} from 'antd';
// import LocationPicker from 'react-location-picker';
import Helmet from 'react-helmet';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Layout, { Paper } from '../../layouts/admin';
import { getDistributionList, createDistribution, updateDistribution, deleteDistribution } from '../../actions';
import provinces from '../../dist/provinces.json';
import districts from '../../dist/districts.json';

const locationData = Object.keys(provinces).map(key => ({
  value: provinces[key].name_with_type,
  label: provinces[key].name_with_type,
  children: Object.keys(districts)
    .filter(item => districts[item].parent_code === provinces[key].code)
    .map(item => ({
      value: districts[item].name_with_type,
      label: districts[item].name_with_type,
    })),
}));

const INITIAL_FIELDS = {
  _id: '',
  name: '',
  location: {
    district: '',
    province: '',
    address: '',
  },
  coordinate: { lat: 0, lng: 0 },
};

const findLatLng = (address, geocoder) => new Promise((resolve, reject) => {
  geocoder.geocode({ address }, (results, status) => {
    if (status === 'OK') {
      resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
    } else {
      reject(new Error(`Couldnt't find the location ${address}`));
    }
  });
});

const functionDebounced = AwesomeDebouncePromise((address, geocoder) => findLatLng(address, geocoder), 1000);

class Distribution extends Component {
  state = {
    selectedRowKeys: [],
    showModal: false,
    modalType: 'Tạo mới',
    fields: INITIAL_FIELDS,
    // pickedAddress: '',
  }

  componentDidMount() {
    this.props.getDistributionList();
  }

  componentWillUnmount() {
    this.setState = () => {};
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  handleModalSubmit = (e) => {
    e.preventDefault();
    const { modalType, fields } = this.state;
    const { createDistribution, updateDistribution, deleteDistribution } = this.props; // eslint-disable-line
    if (modalType === 'Tạo mới') {
      delete fields._id; // eslint-disable-line
      createDistribution(fields);
    } else if (modalType === 'Chỉnh sửa') {
      const id = fields._id;
      delete fields._id; // eslint-disable-line
      updateDistribution(id, fields);
    } else if (modalType === 'Xoá') {
      deleteDistribution(fields._id);
    }
    this.setState({ showModal: false, fields: INITIAL_FIELDS });
  }

  handleModalCancel = () => {
    this.setState({ showModal: false, fields: INITIAL_FIELDS });
  }

  handleEdit = record => {
    this.setState({ fields: record });
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.setState({ fields: record });
    this.showModal('Xoá');
  }

  handleCascader = values => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        location: { ...fields.location, province: values[0], district: values[1] },
      },
    });
  }

  handleChangeAddress = async e => {
    const { fields } = this.state;
    this.setState({ fields: { ...fields, location: { ...fields.location, address: e.target.value } } });
    try {
      const geocoder = new window.google.maps.Geocoder();
      const address = `${fields.location.address}, ${fields.location.district}, ${fields.location.province}, Việt Nam`;
      const result = await functionDebounced(address, geocoder);
      console.log(result);
      this.setState({ fields: { ...fields, coordinate: { lat: result[0], lng: result[1] } } });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { list } = this.props;
    const {
      selectedRowKeys,
      modalType,
      showModal,
      fields,
      // pickedAddress,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Layout path='/admin/config-distribution'>
        <Helmet>
          <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9wFUzb5FpEhwmo-3LRQWgPDI5NPIZNTk&v=3.exp&libraries=geometry,drawing,places" />
        </Helmet>
        <Modal
          title={`${modalType} hệ thống phân phối`}
          visible={showModal}
          onOk={this.handleModalSubmit}
          width='50%'
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleModalSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            <Input value={fields._id} type="hidden" />
            <Form.Item
              {...formItemLayout}
              label="Tên hệ thống"
            >
              <Input
                value={fields.name}
                onChange={e => this.setState({ fields: { ...fields, name: e.target.value } })}
                disabled={modalType === 'Xoá'}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Địa chỉ'
            >
              <Input.Group compact>
                <Cascader
                  style={{ width: '100%' }}
                  options={locationData}
                  onChange={this.handleCascader}
                  disabled={modalType === 'Xoá'}
                  defaultValue={[fields.location.province, fields.location.district]}
                  placeholder='Tỉnh, thành phố / Huyện, thị xã'
                  showSearch={(inputValue, path) =>
                    (path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1))}
                />
              </Input.Group>
              <Input
                value={fields.location.address}
                onChange={this.handleChangeAddress}
                placeholder='Số nhà, tên đường, xã phường...'
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label='Toạ độ'
            >
              {/* <LocationPicker
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '300px' }} />}
                defaultPosition={fields.coordinate}
                radius={-1}
                zoom={13}
                onChange={({ position, address }) => console.log(position, address) ||
                  this.setState({ fields: { ...fields, coordinate: position }, pickedAddress: address })}
              />
              <p>{pickedAddress}</p> */}
              <Input.Group compact>
                <Input
                  style={{ width: '50%' }}
                  value={fields.coordinate.lat}
                  onChange={e => this.setState({ fields: { ...fields, coordinate: { ...fields.coordinate, lat: e.target.value } } })}
                />
                <Input
                  style={{ width: '50%' }}
                  value={fields.coordinate.lng}
                  onChange={e => this.setState({ fields: { ...fields, coordinate: { ...fields.coordinate, lng: e.target.value } } })}
                />
              </Input.Group>
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          title='Danh sách hệ thống phân phối'
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Tên',
              dataIndex: 'name',
              key: 'name',
            }, {
              title: 'Địa chỉ',
              dataIndex: 'location',
              key: 'location',
              render: record => <span>{record.address}, {record.district}, {record.province}</span>,
            }, {
              title: 'Toạ độ',
              dataIndex: 'coordinate',
              key: 'coordinate',
              render: record => <span>{record.lat}, {record.lng}</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  list: state.distribution.list,
}), {
  getDistributionList,
  createDistribution,
  updateDistribution,
  deleteDistribution,
})(Form.create()(Distribution));
