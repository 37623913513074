import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_LAYOUT_REQUEST = 'get-layout-request';
export const GET_LAYOUT_SUCCESS = 'get-layout-success';
export const GET_LAYOUT_FAILURE = 'get-layout-failure';

export function getLayout() {
  return async dispatch => {
    dispatch({ type: GET_LAYOUT_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getLayout,
      });
      if (result.status === 200) {
        dispatch({ type: GET_LAYOUT_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_LAYOUT_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_LAYOUT_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
