import { query, ENDPOINTS } from '../utils/service';

export const GET_DISTRIBUTION_LIST_SUCCESS = 'get-distribution-list-success';
export const GET_DISTRIBUTION_LIST_FAILURE = 'get-distribution-list-failure';
export const GET_DISTRIBUTION_LIST_REQUEST = 'get-distribution-list-request';
export const CREATE_DISTRIBUTION_SUCCESS = 'create-distribution-success';
export const CREATE_DISTRIBUTION_FAILURE = 'create-distribution-failure';
export const CREATE_DISTRIBUTION_REQUEST = 'create-distribution-request';
export const UPDATE_DISTRIBUTION_SUCCESS = 'update-distribution-success';
export const UPDATE_DISTRIBUTION_FAILURE = 'update-distribution-failure';
export const UPDATE_DISTRIBUTION_REQUEST = 'update-distribution-request';
export const DELETE_DISTRIBUTION_SUCCESS = 'delete-distribution-success';
export const DELETE_DISTRIBUTION_FAILURE = 'delete-distribution-failure';
export const DELETE_DISTRIBUTION_REQUEST = 'delete-distribution-request';

export function getDistributionList() {
  return async dispatch => {
    dispatch({ type: GET_DISTRIBUTION_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getDistributionList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_DISTRIBUTION_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_DISTRIBUTION_LIST_FAILURE });
      }
    } catch (error) {
      dispatch({ type: GET_DISTRIBUTION_LIST_FAILURE });
    }
  };
}

export function createDistribution(data) {
  return async dispatch => {
    dispatch({ type: CREATE_DISTRIBUTION_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.createDistribution,
        data,
        method: 'POST',
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_DISTRIBUTION_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: CREATE_DISTRIBUTION_FAILURE });
      }
    } catch (error) {
      dispatch({ type: CREATE_DISTRIBUTION_FAILURE });
    }
  };
}

export function updateDistribution(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_DISTRIBUTION_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.updateDistribution + id,
        data,
        method: 'PATCH',
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_DISTRIBUTION_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: UPDATE_DISTRIBUTION_FAILURE });
      }
    } catch (error) {
      dispatch({ type: UPDATE_DISTRIBUTION_FAILURE });
    }
  };
}

export function deleteDistribution(id) {
  return async dispatch => {
    dispatch({ type: DELETE_DISTRIBUTION_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.deleteDistribution + id,
        method: 'DELETE',
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_DISTRIBUTION_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: DELETE_DISTRIBUTION_FAILURE });
      }
    } catch (error) {
      dispatch({ type: DELETE_DISTRIBUTION_FAILURE });
    }
  };
}
