import React from 'react'
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Col } from 'react-flexbox-grid';

import { useIsWidthLarger } from '../utils/useWindowSize';

const Banner = styled.img`
  height: 680px;
`

const ProductLeftBanner = ({src, href, style}) => {
  const [isXL] = useIsWidthLarger([1200])

  if (!isXL) return null

  return (
    <Col xl={2} style={{overflow: 'hidden', position: 'sticky', top: 120, marginBottom: 8, paddingLeft: 0}}>
      <Link to={href}>
        <Banner style={style} src={src} alt='left-banner' />
      </Link>
    </Col>
  )
}

export default ProductLeftBanner;
