import { message } from 'antd';
import { query, ENDPOINTS } from '../utils/service';

export const GET_CONTACT_FORM_LIST_REQUEST = 'get-contact-form-list-request';
export const GET_CONTACT_FORM_LIST_SUCCESS = 'get-contact-form-list-success';
export const GET_CONTACT_FORM_LIST_FAILURE = 'get-contact-form-list-failure';

export const CREATE_CONTACT_FORM_REQUEST = 'create-contact-form-request';
export const CREATE_CONTACT_FORM_SUCCESS = 'create-contact-form-success';
export const CREATE_CONTACT_FORM_FAILURE = 'create-contact-form-failure';

export const DELETE_CONTACT_FORM_REQUEST = 'delete-contact-form-request';
export const DELETE_CONTACT_FORM_SUCCESS = 'delete-contact-form-success';
export const DELETE_CONTACT_FORM_FAILURE = 'delete-contact-form-failure';

export function getContactFormList() {
  return async dispatch => {
    dispatch({ type: GET_CONTACT_FORM_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getContactFormList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_CONTACT_FORM_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_CONTACT_FORM_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_CONTACT_FORM_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createContactForm(data, callback) {
  return async dispatch => {
    dispatch({ type: CREATE_CONTACT_FORM_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.createContactForm,
        data,
        method: 'POST',
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_CONTACT_FORM_SUCCESS, payload: result.data });
        callback();
        message.success('Đã gửi lời nhắn thành công');
      } else {
        dispatch({ type: CREATE_CONTACT_FORM_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_CONTACT_FORM_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteContactForm(id) {
  return async dispatch => {
    dispatch({ type: DELETE_CONTACT_FORM_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.deleteContactForm + id,
        method: 'DELETE',
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_CONTACT_FORM_SUCCESS, payload: result.data });
        message.success('Đã xoá lời nhắn thành công');
      } else {
        dispatch({ type: DELETE_CONTACT_FORM_FAILURE });
      }
    } catch (error) {
      dispatch({ type: DELETE_CONTACT_FORM_FAILURE });
    }
  };
}
