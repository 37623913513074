import {
  GET_HOME_FAILURE,
  GET_HOME_REQUEST,
  GET_HOME_SUCCESS,
  GET_PRODUCT_PAGE_FAILURE,
  GET_PRODUCT_PAGE_REQUEST,
  GET_PRODUCT_PAGE_SUCCESS,
  GET_BEST_SELLER_PAGE_FAILURE,
  GET_BEST_SELLER_PAGE_REQUEST,
  GET_BEST_SELLER_PAGE_SUCCESS,
  GET_FEATURED_PAGE_FAILURE,
  GET_FEATURED_PAGE_REQUEST,
  GET_FEATURED_PAGE_SUCCESS,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_ARTICLE_DETAIL_FAILURE,
  GET_ARTICLE_DETAIL_REQUEST,
  GET_ARTICLE_DETAIL_SUCCESS,
  GET_ARTICLE_PAGE_FAILURE,
  GET_ARTICLE_PAGE_REQUEST,
  GET_ARTICLE_PAGE_SUCCESS,
  GET_ADVISORY_PAGE_FAILURE,
  GET_ADVISORY_PAGE_REQUEST,
  GET_ADVISORY_PAGE_SUCCESS,
  GET_PRODUCT_BY_CATEGORY_FAILURE,
  GET_PRODUCT_BY_CATEGORY_REQUEST,
  GET_PRODUCT_BY_CATEGORY_SUCCESS,
  GET_PRODUCT_BY_SECTION_FAILURE,
  GET_PRODUCT_BY_SECTION_REQUEST,
  GET_PRODUCT_BY_SECTION_SUCCESS,
  GET_PRODUCT_BY_SUBCATEGORY_FAILURE,
  GET_PRODUCT_BY_SUBCATEGORY_REQUEST,
  GET_PRODUCT_BY_SUBCATEGORY_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  loading: false,
  home: {},
  error: null,
  product: {},
  article: [],
  articleTotal: 0,
  advisory: [],
  advisoryTotal: 0,
  productCategory: [],
  productSubcategory: [],
  fetchProductCategory: false,
  fetchProductSubcategory: false,
  detailProduct: null,
  detailArticle: null,
  bestSellerProducts: [],
  featuredProducts: [],
  total: 0,
  section: null,
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload,
        loading: false,
      };
    case GET_HOME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRODUCT_BY_CATEGORY_REQUEST:
      return {
        ...state,
        fetchProductCategory: true,
        error: null,
      };
    case GET_PRODUCT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload.products,
        fetchProductCategory: false,
      };
    case GET_PRODUCT_BY_CATEGORY_FAILURE:
      return {
        ...state,
        fetchProductCategory: false,
        error: action.payload,
      };
    case GET_PRODUCT_BY_SECTION_REQUEST:
      return {
        ...state,
        productCategory: [],
        fetchProductCategory: true,
        error: null,
      };
    case GET_PRODUCT_BY_SECTION_SUCCESS:
      return {
        ...state,
        productCategory: action.payload.products,
        section: action.payload.section,
        fetchProductCategory: false,
      };
    case GET_PRODUCT_BY_SECTION_FAILURE:
      return {
        ...state,
        fetchProductCategory: false,
        error: action.payload,
      };
    case GET_PRODUCT_BY_SUBCATEGORY_REQUEST:
      return {
        ...state,
        fetchProductSubcategory: true,
        error: null,
      };
    case GET_PRODUCT_BY_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        productSubcategory: action.payload.products,
        fetchProductSubcategory: false,
      };
    case GET_PRODUCT_BY_SUBCATEGORY_FAILURE:
      return {
        ...state,
        fetchProductSubcategory: false,
        error: action.payload,
      };
    case GET_PRODUCT_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_PAGE_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case GET_PRODUCT_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FEATURED_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FEATURED_PAGE_SUCCESS:
      return {
        ...state,
        featuredProducts: action.payload.products,
        total: action.payload.total,
        loading: false,
      };
    case GET_FEATURED_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BEST_SELLER_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BEST_SELLER_PAGE_SUCCESS:
      return {
        ...state,
        bestSellerProducts: action.payload.products,
        total: action.payload.total,
        loading: false,
      };
    case GET_BEST_SELLER_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ARTICLE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ARTICLE_PAGE_SUCCESS:
      return {
        ...state,
        article: action.payload.articles,
        articleTotal: action.payload.total,
        loading: false,
      };
    case GET_ARTICLE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ADVISORY_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ADVISORY_PAGE_SUCCESS:
      return {
        ...state,
        advisory: action.payload.articles,
        advisoryTotal: action.payload.total,
        loading: false,
      };
    case GET_ADVISORY_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        detailProduct: { ...action.payload.product, relatedProducts: action.payload.relatedProducts },
        loading: false,
      };
    case GET_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ARTICLE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ARTICLE_DETAIL_SUCCESS:
      return {
        ...state,
        detailArticle: { ...action.payload.article, relatedArticles: action.payload.relatedArticles },
        loading: false,
      };
    case GET_ARTICLE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;