import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Modal from 'react-responsive-modal';

import { media } from '../utils/media';

const Figure = styled.figure`
  width: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: ${props => props.backgroundPosition};
  background-image: ${props => props.backgroundImage};
  ${media.phablet`
    background-position: 0% 0%;
  `};
  :hover {
    img {
      opacity: 0;
    }
    ${media.phablet`
      img {
        opacity: 1;
      }
    `};
  }
  img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
`;

const MobileModalImage = styled.div`
  width: 100wh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;

class ZoomImage extends Component {
  state = { backgroundPosition: '0% 0%', showModal: false, selectedImage: null }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    this.setState({ backgroundPosition: `${x}% ${y}%` });
  }

  render() {
    const { data, onError } = this.props;
    const { backgroundPosition, showModal, selectedImage } = this.state;

    return (
      <Fragment>
        <Figure
          backgroundPosition={backgroundPosition}
          backgroundImage={`url("${data.original}")`}
          onMouseMove={this.handleMouseMove}
          onClick={() => this.setState({ showModal: true, selectedImage: data.original })}
        >
          <img src={data.original} alt={data.originalAlt} onError={onError} />
        </Figure>
        <Modal
          open={showModal}
          onClose={() => this.setState({ showModal: false, selectedImage: null })}
          styles={{
            overlay: {
              padding: 0,
            }
          }}
          classNames={{ overlay: 'zoom-modal' }}
          center
        >
          <MobileModalImage>
            <img alt='product' src={selectedImage} />
          </MobileModalImage>
        </Modal>
      </Fragment>
    );
  }
}

export default ZoomImage;
