import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import ContactMap from '../components/contact-map';
import { createContactForm } from '../actions';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
`;

const SectionTitle = styled.h1`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px 0px 20px 0px;
  color: #134E8F;
`;

const FormWrapper = styled.div`
  padding: 20px 45px 10px;
  p {
    font-size: 14px;
    color: #838383;
    text-align: center;
    padding-top: 10px;
    padding: 20px 0 0;
  }
  strong {
    color: #444c59;
  }
`;

const FormElement = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
  margin-bottom: 20px;
  float: left;
  ::before {
    clear: both;
    width: 100%;
    content: '';
    display: table;
  }
  ::after {
    clear: both;
    width: 100%;
    content: '';
    display: table;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 0 0;
    box-sizing: border-box;
    outline: 0!important;
    appearance: none;
    color: #282828;
    font-size: 14px;
    border-radius: 2px;
    padding: 17px 10px 5px;
    border: 1px solid #90A3C2;
  }
  .placeholder {
    pointer-events: none;
    color: #90A3C2;
    position: absolute;
    top: 0;
    max-width: 100%;
    z-index: 1;
    transition: .3s;
    transform-origin: 0 0;
    font-size: 14px;
    left: 10px;
    line-height: 46px;
    font-weight: 400;
  }
  input:focus ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  input:valid ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    background: 0 0;
    box-sizing: border-box;
    outline: 0!important;
    appearance: none;
    color: #282828;
    font-size: 14px;
    border-radius: 2px;
    padding: 17px 10px 5px;
    border: 1px solid #90A3C2;
  }
  textarea:focus ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
  textarea:valid ~ .placeholder {
    transform: scale(.72)!important;
    line-height: 20px!important;
    margin-top: 4px;
  }
`;

const ElementLeft = styled(FormElement)`
  float: left;
  width: 48%;
`;

const ElementRight = styled(FormElement)`
  float: right;
  width: 48%;
`;

const Button = styled.div`
  padding: 12px 0;
  width: 100%;
  text-align: center;
  color: #fff;
  background: #134E8F;
  :hover {
    background: #0b3a70;
  }
  border-radius: 2px;
  font-size: 16px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Info = styled.div`
  margin-top: 20px;
  h3 {
    font-size: 20px;
    color: #0b3a70;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'contact.bread' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class ContactPage extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    content: '',
  }

  handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, email, phone, content } = this.state;
    if (firstName.length && lastName.length && email.length && phone.length && content.length) {
      this.props.createContactForm(this.state, () => this.setState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        content: '',
      }));
    } else {
      message.error('Vui lòng nhập đầy đủ thông tin');
    }
  }

  handleChange = (type, value) => this.setState({ [type]: value });

  render() {
    const { firstName, lastName, email, phone, content } = this.state;
    const { locale } = this.props;
    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper>
            <Grid fluid>
              <Row>
                <Col md={12} xl={6}>
                  <ContactMap />
                  <Info>
                    <h3><FormattedMessage id='contact.companyName' /></h3>
                    <p><FormattedMessage id='contact.companyAddress' /></p>
                  </Info>
                </Col>
                <Col md={12} xl={6}>
                  <SectionTitle><FormattedMessage id='contact.title' /></SectionTitle>
                  <FormWrapper>
                    <form onSubmit={this.handleSubmit}>
                      <ElementLeft>
                        <input required value={firstName} onChange={e => this.handleChange('firstName', e.target.value)} />
                        <div className='placeholder'><FormattedMessage id='contact.firstName' /></div>
                      </ElementLeft>
                      <ElementRight>
                        <input required value={lastName} onChange={e => this.handleChange('lastName', e.target.value)} />
                        <div className='placeholder'><FormattedMessage id='contact.lastName' /></div>
                      </ElementRight>
                      <FormElement>
                        <input required value={phone} onChange={e => this.handleChange('phone', e.target.value)} />
                        <div className='placeholder'><FormattedMessage id='contact.phone' /></div>
                      </FormElement>
                      <FormElement>
                        <input required value={email} onChange={e => this.handleChange('email', e.target.value)} />
                        <div className='placeholder'><FormattedMessage id='contact.email' /></div>
                      </FormElement>
                      <FormElement style={{ height: 100 }}>
                        <textarea required value={content} onChange={e => this.handleChange('content', e.target.value)} />
                        <div className='placeholder'><FormattedMessage id='contact.content' /></div>
                      </FormElement>
                      <Button onClick={this.handleSubmit}>
                        <FormattedMessage id='contact.sendRequest' />
                      </Button>
                    </form>
                  </FormWrapper>
                </Col>
              </Row>
            </Grid>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  locale: state.setting.locale,
}), {
  createContactForm,
})(ContactPage);
