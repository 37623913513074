export const ADD_TO_CART = 'add-to-cart';
export const REMOVE_FROM_CART = 'remove-from-cart';
export const CLEAN_CART = 'clean-cart';

export function addToCart(data) {
  return {
    type: ADD_TO_CART,
    payload: data,
  };
}

export function removeFromCart(idx) {
  return {
    type: REMOVE_FROM_CART,
    payload: idx,
  };
}

export function cleanCart() {
  return {
    type: CLEAN_CART,
  };
}
