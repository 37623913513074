import React, { Component } from 'react';
import {
  Input, Button, Table, Divider, Form, Modal, Select, Icon,
} from 'antd';
import { connect } from 'react-redux';

import {
  getContactList,
  createContact,
  updateContact,
  deleteContact,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';

class ConfigContact extends Component {
  state = {
    showModal: false,
    modalType: 'Tạo mới',
    selectedRowKeys: [],
  }

  componentDidMount() {
    this.props.getContactList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType } = this.state;
    const { form, createContact, updateContact, deleteContact } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      if (!err) {
        if (modalType === 'Tạo mới') {
          createContact(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateContact(id, values);
        } else if (modalType === 'Xoá') {
          deleteContact(values._id);
        }
        this.setState({ showModal: false });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.showModal('Xoá');
  }

  render() {
    const { list, form: { getFieldDecorator } } = this.props;
    const { showModal, modalType, selectedRowKeys } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    return (
      <Layout path='/admin/config-contact'>
        <Modal
          style={{ maxHeight: '80vh' }}
          title={`${modalType} thông tin liên hệ`}
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề liên hệ"
            >
              {getFieldDecorator('name', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tiêu đề thông tin liên hệ!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề liên hệ (Tiếng Anh)"
            >
              {getFieldDecorator('engName')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Giá trị"
            >
              {getFieldDecorator('value', {
                rules: [{
                  required: true, message: 'Vui lòng nhập giá trị thông tin liên hệ!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Icon"
            >
              {getFieldDecorator('icon', {
                rules: [{
                  required: true, message: 'Vui lòng chọn icon thông tin liên hệ!',
                }],
              })(
                <Select disabled={modalType === 'Xoá'}>
                  <Select.Option value='phone'>
                    <Icon type='phone' />
                  </Select.Option>
                  <Select.Option value='mail'>
                    <Icon type='mail' />
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          title='Danh sách thông tin liên hệ'
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Tiêu đề',
              dataIndex: 'name',
              key: 'name',
            }, {
              title: 'Giá trị',
              dataIndex: 'value',
              key: 'value',
              render: (text, record) => <a target='__blank' href={record.icon === 'phone' ? `tel:${text}` : `mailto:${text}`}>{text}</a>,
            }, {
              title: 'Icon',
              dataIndex: 'icon',
              key: 'icon',
              render: text => <Icon type={text} />,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 110,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  list: state.contact.list,
}), {
  getContactList,
  createContact,
  updateContact,
  deleteContact,
})(Form.create()(ConfigContact));
