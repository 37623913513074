import React, { Fragment } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import ContentLoader from 'react-content-loader';

import { media } from '../utils/media';
import { CDN_URL } from '../utils/service';

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  ${media.thone`
    display: none;
  `};
  .slick-list {
    overflow: visible !important;
  }
`;

const WrapperMobile = styled.div`
  display: none;
  width: 100%;
  overflow: hidden;
  ${media.thone`
    display: block;
  `};
  .slick-list {
    /* overflow: visible !important; */
  }
`;

const Item = styled.img`
  /* background-image: url(${props => encodeURIComponent(props.src)});
  background-repeat: no-repeat;
  background-size: cover; */
  height: 100%;
  cursor: pointer;
  /* object-fit: contain;
  ${media.giant`
    height: 100%;
  `}; */
  width: 100%;
`;

const ItemSkeleton = styled.div`
  /* background-image: url(${props => encodeURIComponent(props.src)});
  background-repeat: no-repeat;
  background-size: cover; */
  height: 46vh;
  ${media.desktop`
    height: 34vh;
  `};
  ${media.tablet`
    height: 30vh;
  `};
  ${media.phablet`
    height: 28vh;
  `};
  ${media.thone`
    height: 40vh;
  `};
  width: 100%;
`;

const HomeSlider = ({ data, mobile, loading }) => (
  <Fragment>
    <Wrapper>
      <Slider
        autoplay
        autoplaySpeed={4000}
        swipeToSlide
        dots
        arrows={false}
        style={{ overflow: 'visible' }}
      >
        {loading ? <ItemSkeleton>
          <ContentLoader
            height='100vh'
            width='100vw'
            speed={2}
            primaryColor="#f3f3f3"
            style={{ width: '100%', height: '100%' }}
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </ItemSkeleton> : data.map(item => (
          <Item onClick={() => window.open(item.link)} key={item.featuredImage} src={`${CDN_URL}/${item.featuredImage}`} />
        ))}
      </Slider>
    </Wrapper>
    <WrapperMobile>
      <Slider
        autoplay
        autoplaySpeed={4000}
        swipeToSlide
        dots
        arrows={false}
        style={{ overflow: 'visible' }}
      >
        {mobile.map(item => (
          <Item onClick={() => window.open(item.link)} key={item.featuredImage} src={`${CDN_URL}/${item.featuredImage}`} />
        ))}
      </Slider>
    </WrapperMobile>
  </Fragment>
);

export default HomeSlider;