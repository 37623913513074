import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Icon, Drawer, Tooltip, Dropdown, Menu } from 'antd';
import { Location } from '@reach/router';
import { connect } from 'react-redux';
import { usePostHog } from 'posthog-js/react'

import Wrapper from './wrapper';
import vietnamFlag from '../images/VN.png';
import englishFlag from '../images/GB.png';
import { media } from '../utils/media';
import { logout, changeLocale } from '../actions';
import Link from '../components/link';

const profileMenu = (handleLogout, isAdmin) => (
  <Menu>
    <Menu.Item key="0">
      <Icon type="user" />
      <span role='button' onClick={() => navigate('/user/dashboard')}>Tài khoản</span>
    </Menu.Item>
    {isAdmin && <Menu.Item key="1">
      <Icon type="setting" />
      <span role='button' onClick={() => navigate('/admin')}>Trang quản trị</span>
    </Menu.Item>}
    <Menu.Divider />
    <Menu.Item key="2">
      <Icon type="logout" />
      <span role='button' onClick={handleLogout}>Đăng xuất</span>
    </Menu.Item>
  </Menu>
);

const Container = styled.div`
  background: #04852F;
  overflow: hidden;
`;

const Item = styled.div`
  display: inline-block;
  padding: ${props => (props.right ? '10px 3px' : '9px 6px')};
  min-width: ${props => (props.right ? '0px' : '64px')};
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  color: white;
  float: ${props => (props.right ? 'right' : 'left')};
  text-align: ${props => (props.right ? 'right' : 'left')};
  ${media.phablet`
    font-size: 13px;
  `};
  a {
    color: white;
    ::after {
      background-color: white;
      bottom: -4px;
    }
  }
`;

const DrawerItem = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  /* color: white; */
  font-size: 14px;
  font-weight: bold;
  padding: 10px 0px;
  ${media.phablet`
    font-size: 13px;
  `};
  a {
    color: #04852E;
    ::after {
      background-color: white;
      bottom: -4px;
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 20px;
  padding: 4px 0px;
  color: white;
  ${media.tablet`
    display: inline-block;
  `};
`;

const Flag = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  border: ${props => (props.selected ? '2px solid white' : 'none')};
  width: 30px;
`;

const NavItems = styled.div`
  display: block;
  ${media.tablet`
    display: none;
  `};
`;

const Navigation = ({
  changeLocale,
  logout,
  menu,
  locale,
  isLogged,
  userInfo,
}) => {
  const [state, setState] = useState({showDrawer: false})
  const posthog = usePostHog()

  useEffect(() => {
    if (userInfo) {
        // Identify sends an event, so you want may want to limit how often you call it
        posthog?.identify(userInfo._id, {
            email: userInfo.email,
        })
    }
}, [posthog, userInfo])

  const changeLanguage = (locale) => {
    changeLocale(locale);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  }

  return (
    <Fragment>
      <Container>
        <Wrapper wrapperStyle={{ backgroundColor: 'transparent' }}>
          <Hamburger onClick={() => setState({ showDrawer: true })}>
            <Icon type="menu-unfold" />
          </Hamburger>
          <NavItems>
            {menu && menu.length > 0 && menu.map(item => (
              <Item key={item._id}>
                <Link to={item.url} activeClassName='active'>{locale === 'en' && item.engTitle ? item.engTitle : item.title}</Link>
              </Item>
            ))}
          </NavItems>
          <Location>
            {({ location }) => (
              <Fragment>
                <Item right onClick={() => changeLanguage('en')}>
                  <Tooltip title='English'>
                    <Flag selected={locale === 'en'} src={englishFlag} />
                  </Tooltip>
                </Item>
                <Item right onClick={() => changeLanguage('vi')}>
                  <Tooltip title='Tiếng Việt'>
                    <Flag selected={locale === 'vi'} src={vietnamFlag} />
                  </Tooltip>
                </Item>
              </Fragment>
            )}
          </Location>
          {isLogged
            ? (
              <Item right style={{ marginRight: 10 }}>
                <Dropdown overlay={profileMenu(handleLogout, userInfo.role === 2)} trigger={['click']}>
                  <a href='#'>{userInfo.fullname}</a>
                </Dropdown>
              </Item>
            )
            : (
              <Item right style={{ marginRight: 10 }}>
                <Link to='/user'>Đăng nhập</Link>
              </Item>
            )}
        </Wrapper>
      </Container>
      <Drawer
        title="Thiên Hà Corporation"
        placement='left'
        closable
        onClose={() => setState({ showDrawer: false })}
        visible={state.showDrawer}
      >
        {menu && menu.length > 0 && menu.map(item => (
          <DrawerItem key={item._id}>
            <Link to={item.url} activeClassName='active'>{locale === 'en' && item.engTitle ? item.engTitle : item.title}</Link>
          </DrawerItem>
        ))}
      </Drawer>
    </Fragment>
  );
}

export default connect(state => ({
  isLogged: state.user.isLogged,
  userInfo: state.user.userInfo,
  locale: state.setting.locale,
}), {
  logout,
  changeLocale,
})(Navigation);
