import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import ContentLoader from 'react-content-loader';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import ArticleCard from '../components/article-card';
import ArticlesSkeleton from '../components/articles-skeleton';
import { getArticleDetail } from '../actions';
// import { API_URL } from '../utils/service';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
`;

const DetailWrapper = styled.div`
  padding: 0px 15px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #0b3a70;
  margin-bottom: 20px;
`;

// const ArticleImage = styled.div`
//   height: 40vh;
//   width: 100%;
//   background-image: url("${props => props.src}");
//   background-size: cover;
//   background-repeat: no-repeat;
//   margin-bottom: 20px;
//   background-position: center;
// `;

const ArticleMeta = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #282828;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const ArticleContent = styled.div`
  font-size: 15px;
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 18px;
  }
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'news.title' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class DetailArticle extends Component {
  componentDidMount() {
    this.props.getArticleDetail(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.location !== prevProps.location) window.scrollTo(0,0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.props.getArticleDetail(nextProps.id);
    }
  }

  render() {
    const { data, loading, error, locale } = this.props;

    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper>
            {error && <p><FormattedMessage id='error' /></p>}
            <DetailWrapper>
              {!loading && data ? (
                <Fragment>
                  {/* <ArticleImage src={`${CDN_URL}/${data.featuredImage}`} /> */}
                  <Title>{locale === 'en' && data.engTitle ? data.engTitle : data.title}</Title>
                  <ArticleMeta><strong>{data.category.name}</strong> | {moment(data.createdAt).fromNow()}</ArticleMeta>
                  <ArticleContent dangerouslySetInnerHTML={{ __html: locale === 'en' && data.engContent ? data.engContent : data.content }} />
                </Fragment>
              ) : (
                <Fragment>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '30%', height: 28, marginBottom: 25, display: 'block' }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '15%', height: 15, marginBottom: 15 }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '100%', height: 15, marginBottom: 12 }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '100%', height: 15, marginBottom: 12 }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '100%', height: 15, marginBottom: 12 }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                  <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    style={{ width: '60%', height: 15, marginBottom: 12 }}
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                  </ContentLoader>
                </Fragment>
              )}
            </DetailWrapper>
          </Wrapper>
          <Wrapper>
            <Grid fluid>
              <Title>Tin liên quan</Title>
              {!loading && data ? <Row>
                {data.relatedArticles && data.relatedArticles.length > 0
                  ? data.relatedArticles.map(item => (
                    <Col key={item.title} xs={12} md={6} xl={4}>
                      <ArticleCard locale={locale} data={item} />
                    </Col>
                  ))
                  : <span>Không có dữ liệu</span>}
              </Row> : <ArticlesSkeleton small />}
            </Grid>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.detailArticle,
  error: state.page.error,
  loading: state.page.loading,
  locale: state.setting.locale,
}), {
  getArticleDetail,
})(DetailArticle);
