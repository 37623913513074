import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { navigate, Link } from '@reach/router';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import Layout from '../layouts/layout';
import Slider from '../components/home-slider';
import Wrapper from '../components/wrapper';
import Partners from '../components/partners';
import Certificate from '../components/home-certificate';
import ProductCard from '../components/product-card';
import HomeCustomer from '../components/home-customer';
import Loading from '../components/loading';
import ProductsSkeleton from '../components/products-skeleton';
import ArticleCard from '../components/article-card';
import { media } from '../utils/media';
import { useIsWidthLarger } from '../utils/useWindowSize';
import { youtubeParser } from './admin/config-components/youtube';
import ProductCol from '../components/product-col';
import ProductLeftBanner from '../components/product-left-banner';

import { getHome } from '../actions';
import { CDN_URL } from '../utils/service';

const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 40px 0px 20px 0px;
  color: #134E8F;
  ${media.phablet`
    font-size: 20px;
  `};
`;

const SectionSubtitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  padding: 10px 0px 20px 0px;
  color: #606060;
  ${media.phablet`
    font-size: 18px;
  `};
`;

const MoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Banner = styled.img``

const IndexPage = ({ getHome, data, loading, error, locale }) => {
  useEffect(() => {
    getHome()
  }, [getHome])

  const [isXL] = useIsWidthLarger([1200])

  if (error) return <p>{error}</p>;

  return (
    <Layout showSocial locale={locale}>
      <Slider
        loading={loading}
        data={data.logos ? data.logos.filter(item => item.type === 2) : []}
        mobile={data.logos ? data.logos.filter(item => item.type === 5) : []}
      />
      <Wrapper style={{ minHeight: '100vh' }}>
        {!loading && data.homeSections ? data.homeSections.map(section => (
          <Fragment key={section.key}>
            <SectionTitle>{section.label}</SectionTitle>
            {section.items ? section.items.map(item => (
              <Fragment key={item.key}>
                <SectionSubtitle>{item.label}</SectionSubtitle>
                {item.banner && <Link to={item.banner.link || `/section/${item.key.replace('.', '-')}`} style={{marginBottom: 8}}>
                  <Banner src={`${CDN_URL}/${item.banner.featuredImage}`} />
                </Link>}
                <div>
                  {item.bannerLeft && <ProductLeftBanner
                    src={`${CDN_URL}/${item.bannerLeft.featuredImage}`}
                    href={item.bannerLeft.link || `/section/${item.key.replace('.', '-')}`}
                  />}
                  <Row style={{paddingLeft: 4, paddingRight: 4, marginTop: item.bannerLeft && isXL ? -688 : 0}}>
                    {
                      item.products.map((product, index) => {
                        return (
                          <React.Fragment key={product._id}>
                            {item.bannerLeft && isXL && (index === 0 || index === 5 || index === 10) && <ProductCol />}
                            <ProductCol key={product.name}>
                              <ProductCard locale={locale} data={product} />
                            </ProductCol>
                          </React.Fragment>
                        )
                      })
                    }
                  </Row>
                </div>
                {item.limit === item.products.length && (
                  <MoreWrapper>
                    <Button type='primary' onClick={() => navigate(`/section/${item.key.replace('.', '-')}`)}><FormattedMessage id='more' /></Button>
                  </MoreWrapper>
                )}
                {item.key === '1.1' && (
                  <Fragment>
                    <SectionTitle><FormattedMessage id='home.customer' /></SectionTitle>
                    <div style={{ marginBottom: 30 }}>
                      <Partners data={data.logos ? data.logos.filter(item => item.type === 4) : []} />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )) : (
              <Fragment>
                {section.banner && <Link to={section.banner.link || `/section/${section.key.replace('.', '-')}`} style={{marginBottom: 8}}>
                  <Banner src={`${CDN_URL}/${section.banner.featuredImage}`} />
                </Link>}
                <div>
                  {section.bannerLeft && <ProductLeftBanner
                    src={`${CDN_URL}/${section.bannerLeft.featuredImage}`}
                    href={section.bannerLeft.link || `/section/${section.key.replace('.', '-')}`}
                    style={{height: 608}}
                  />}
                  <Row style={{paddingLeft: 4, paddingRight: 4, marginTop: section.bannerLeft && isXL ? -616 : 0}}>
                    {
                      section.products.map((product, index) => {
                        return (
                          <React.Fragment key={product._id}>
                            {section.bannerLeft && (index === 0 || index === 5 || index === 10) && isXL && <ProductCol />}
                            <ProductCol key={product.name}>
                              <ProductCard locale={locale} data={product} />
                            </ProductCol>
                          </React.Fragment>
                        )
                      })
                    }
                  </Row>
                </div>
                {section.limit === section.products.length && (
                  <MoreWrapper>
                    <Button type='primary' onClick={() => navigate(`/section/${section.key.replace('.', '-')}`)}><FormattedMessage id='more' /></Button>
                  </MoreWrapper>
                )}
              </Fragment>
            )}
          </Fragment>
        )) : <ProductsSkeleton large />}
      </Wrapper>
      {loading || !data ? <Loading /> : (
        <Fragment>
          <Wrapper>
            <Row style={{ padding: 0, marginTop: 40 }}>
              {data.news && data.news.length > 0 && (
                <Col lg={6} md={12}>
                  <SectionTitle><FormattedMessage id='news.title' /></SectionTitle>
                  <Row>
                    {data.news.map(item => (
                      <Col key={item._id} xs={12} md={6}>
                        <ArticleCard locale={locale} data={item} />
                      </Col>
                    ))}
                  </Row>
                  <MoreWrapper style={{ marginTop: 0 }}>
                    <Button type='primary' onClick={() => navigate('/news')}><FormattedMessage id='more' /></Button>
                  </MoreWrapper>
                </Col>
              )}
              {data.advisories && data.advisories.length > 0 && (
                <Col lg={6} md={12}>
                  <SectionTitle><FormattedMessage id='advisory.title' /></SectionTitle>
                  <Row>
                    {data.advisories.map(item => (
                      <Col key={item._id} xs={12} md={6}>
                        <ArticleCard locale={locale} data={item} />
                      </Col>
                    ))}
                    <MoreWrapper style={{ marginTop: 0 }}>
                      <Button type='primary' onClick={() => navigate('/advisory')}><FormattedMessage id='more' /></Button>
                    </MoreWrapper>
                  </Row>
                </Col>
              )}
            </Row>
          </Wrapper>
          <HomeCustomer locale={locale} data={data.reviews || []} />
          <Wrapper>
            <Row style={{ padding: 0, marginTop: 40 }}>
              <Col lg={9}>
                <SectionTitle><FormattedMessage id='home.award' /></SectionTitle>
                <div style={{ padding: '0px 20px' }}>
                  <Certificate data={data.logos ? data.logos.filter(item => item.type === 3) : []} />
                </div>
              </Col>
              <Col lg={3}>
                {
                  data.clip && data.clip.url
                    ? <YouTube opts={{ width: '100%', height: '330px' }} videoId={youtubeParser(data.clip.url)} />
                    : <YouTube opts={{ width: '100%', height: '330px' }} videoId="7DOafkAY0HY" />
                }
              </Col>
            </Row>
          </Wrapper>
        </Fragment>
      )}
    </Layout>
  )
}

export default connect(state => ({
  data: state.page.home,
  loading: state.page.loading,
  error: state.page.error,
  locale: state.setting.locale,
}), {
  getHome,
})(IndexPage);