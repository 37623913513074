import React, { Fragment } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';

import { CDN_URL } from '../utils/service';
import { media } from '../utils/media';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
`;

const SlideImage = styled.div`
  width: 100%;
  height: 350px;
  background-color: #229BD5;
  background-image: url(${props => `"${CDN_URL}/${props.image}"`});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  filter: brightness(0.65);
  transition: all 0.3s;
  ${Wrapper}:hover & {
    filter: brightness(0.80);
  }
`;

const Inner = styled.div`
  padding: 30px 0px 60px;
  width: 100%;
  margin: 0px auto;
  max-width: 800px;
  outline: none;
  color: #fff;
  text-align: center;
  h1 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 26px;
    text-transform: uppercase;
    ${media.phablet`
      font-size: 23px;
      margin-bottom: 15px;
    `};
  }
  h2 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    ${media.phablet`
      font-size: 12px;
    `};
  }
  h5 {
    font-size: 16px;
    color: #fff;
    ${media.phablet`
      font-size: 14px;
    `};
  }
  p {
    padding: 20px 0px;
    font-size: 18px;
    ${media.phablet`
      font-size: 16px;
      padding: 15px 0px;
    `};
  }
  ::after {
    display: none;
  }
`;

const HomeCustomer = ({ data, locale }) => (
  <Wrapper>
    <Slider
      autoplay
      autoplaySpeed={4000}
      swipeToSlide
      dots
      fade
      arrows={false}
    >
      {data.map(item => (
        <Fragment key={item._id}>
          <SlideImage image={item.background} />
          <Wrapper>
            <Inner>
              <h1><FormattedMessage id='review.title' /></h1>
              <h2><FormattedMessage id='review.subTitle' /></h2>
              <p>"{locale === 'en' && item.engContent ? item.engContent : item.content}"</p>
              <h5>{item.writer}</h5>
            </Inner>
          </Wrapper>
        </Fragment>
      ))}
    </Slider>
  </Wrapper>
);

export default HomeCustomer;
