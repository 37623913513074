import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { injectIntl } from 'react-intl';
import ContentLoader from 'react-content-loader';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import StorySkeleton from '../components/story-skeleton';
import { getStoryList } from '../actions';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  font-size: 14px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 25px 20px;
  background: white;
  margin-bottom: 15px;
  font-size: 14px;
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'story.bread' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class Story extends Component {
  state = {
    selected: '0',
    width: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props.getStoryList();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { list, loading, locale } = this.props;
    const {
      selected,
      // width,
    } = this.state;
    // const isXS = width <= 575;
    const selectedStory = list[Number.parseInt(selected, 10)];
    const selectedContent = selectedStory // eslint-disable-line
      ? locale === 'en' && selectedStory && selectedStory.engContent
        ? selectedStory.engContent
        : selectedStory.content
      : '';
    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper style={{ backgroundColor: 'transparent', padding: 0 }}>
            <Grid fluid>
              <Row>
                {/* <Col xs={12} sm={4} xl={3}>
                  <Sticky stickyStyle={{ marginTop: 55 }} topOffset={-55}>
                    <Menu
                      defaultSelectedKeys={['0']}
                      onClick={e => this.setState({ selected: e.key })}
                      mode={isXS ? 'horizontal' : 'inline'}
                    >
                      {list.length > 0 && list.map((item, idx) => (
                        <Menu.Item key={idx.toString()}>{locale === 'en' && item.engName ? item.engName : item.name}</Menu.Item>
                      ))}
                    </Menu>
                  </Sticky>
                </Col> */}
                <Col xs={12}>
                  <Menu
                    defaultSelectedKeys={['0']}
                    onClick={e => this.setState({ selected: e.key })}
                    mode='horizontal'
                  >
                    {!loading && list.length > 0 ? list.map((item, idx) => (
                      <Menu.Item key={idx.toString()}>{locale === 'en' && item.engName ? item.engName : item.name}</Menu.Item>
                    )) : [0, 1, 2, 3, 4].map(id => (
                      <Menu.Item key={id}>
                        <ContentLoader
                          speed={2}
                          primaryColor="#f3f3f3"
                          style={{ width: 100, height: 17 }}
                          secondaryColor="#ecebeb"
                        >
                          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                        </ContentLoader>
                      </Menu.Item>
                    ))}
                  </Menu>
                  {!loading && list.length > 0 ? <ContentWrapper
                    dangerouslySetInnerHTML={{ __html: selectedContent }}
                  /> : <StorySkeleton />}
                </Col>
              </Row>
            </Grid>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  list: state.story.list,
  loading: state.story.loading,
  locale: state.setting.locale,
}), {
  getStoryList,
})(Story);
