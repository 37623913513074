import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_STORY_LIST_REQUEST = 'get-story-list-request';
export const GET_STORY_LIST_SUCCESS = 'get-story-list-success';
export const GET_STORY_LIST_FAILURE = 'get-story-list-failure';
export const CREATE_STORY_REQUEST = 'create-story-request';
export const CREATE_STORY_SUCCESS = 'create-story-success';
export const CREATE_STORY_FAILURE = 'create-story-failure';
export const UPDATE_STORY_REQUEST = 'update-story-request';
export const UPDATE_STORY_SUCCESS = 'update-story-success';
export const UPDATE_STORY_FAILURE = 'update-story-failure';
export const DELETE_STORY_REQUEST = 'delete-story-request';
export const DELETE_STORY_SUCCESS = 'delete-story-success';
export const DELETE_STORY_FAILURE = 'delete-story-failure';

export function getStoryList() {
  return async dispatch => {
    dispatch({ type: GET_STORY_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getStoryList,
      });
      if (result.status === 200) {
        dispatch({ type: GET_STORY_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_STORY_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_STORY_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function createStory(data) {
  return async dispatch => {
    dispatch({ type: CREATE_STORY_REQUEST });
    try {
      const result = await query({
        method: 'POST',
        data,
        endpoint: ENDPOINTS.createStory,
      });
      if (result.status === 201) {
        dispatch({ type: CREATE_STORY_SUCCESS, payload: result.data });
        message.success('Tạo câu chuyện thành công');
      } else {
        dispatch({ type: CREATE_STORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: CREATE_STORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function updateStory(id, data) {
  return async dispatch => {
    dispatch({ type: UPDATE_STORY_REQUEST });
    try {
      const result = await query({
        method: 'PATCH',
        data,
        endpoint: ENDPOINTS.updateStory + id,
      });
      if (result.status === 200) {
        dispatch({ type: UPDATE_STORY_SUCCESS, payload: result.data });
        message.success('Cập nhật câu chuyện thành công');
      } else {
        dispatch({ type: UPDATE_STORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPDATE_STORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteStory(id) {
  return async dispatch => {
    dispatch({ type: DELETE_STORY_REQUEST });
    try {
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteStory + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_STORY_SUCCESS, payload: result.data });
        message.success('Xoá câu chuyện thành công');
      } else {
        dispatch({ type: DELETE_STORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_STORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
