import React, { Component, Fragment } from 'react';
import {
  Input, Button, Table, Divider, Form, Modal, Icon,
} from 'antd';
import { connect } from 'react-redux';

import {
  getProductMenuList,
  createProductMenu,
  updateProductMenu,
  deleteProductMenu,
} from '../../../actions';
import { Paper } from '../../../layouts/admin';

class ProductMenu extends Component {
  state = {
    showModal: false,
    modalType: 'Tạo mới',
    showChildrenModal: false,
    childrenModalType: 'Tạo mới',
    showMedia: false,
    children: [],
    selectedChildren: {},
  }

  componentDidMount() {
    this.props.getProductMenuList();
  }

  showModal = modalType => this.setState({
    showModal: true,
    modalType,
  })

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { modalType, children } = this.state;
    const { form, createProductMenu, updateProductMenu, deleteProductMenu } = this.props; // eslint-disable-line
    form.validateFields((err, values) => {
      values.children = children; // eslint-disable-line
      if (!err) {
        if (modalType === 'Tạo mới') {
          createProductMenu(values);
        } else if (modalType === 'Chỉnh sửa') {
          const id = values._id;
          delete values._id; // eslint-disable-line
          updateProductMenu(id, values);
        } else if (modalType === 'Xoá') {
          deleteProductMenu(values._id);
        }
        this.setState({ showModal: false, children: [] });
        form.resetFields();
      } else {
        console.log(err);
      }
    });
  }

  handleEdit = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ children: record.children });
    this.showModal('Chỉnh sửa');
  }

  handleDelete = record => {
    this.props.form.setFieldsValue(record);
    this.setState({ children: record.children });
    this.showModal('Xoá');
  }

  handleSubmitChildren = () => {
    const { childrenModalType, children, selectedChildren } = this.state;

    if (childrenModalType === 'Tạo mới') {
      this.setState({
        children: [...children, selectedChildren],
        selectedChildren: {},
        showChildrenModal: false,
      });
    } else if (childrenModalType === 'Chỉnh sửa') {
      this.setState({
        children: children.map(c => (c._id === selectedChildren._id ? selectedChildren : c)),
        selectedChildren: {},
        showChildrenModal: false,
      });
    } else {
      this.setState({
        children: children.filter(c => c._id !== selectedChildren._id),
        selectedChildren: {},
        showChildrenModal: false,
      });
    }
  }

  render() {
    const { list, form: { getFieldDecorator } } = this.props;
    const {
      showModal,
      modalType,
      showChildrenModal,
      selectedChildren,
      childrenModalType,
      children,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Modal
          title={`${modalType} danh mục con`}
          visible={showChildrenModal}
          centered
          okOk={this.handleSubmitChildren}
          onCancel={() => this.setState({ selectedChildren: {}, showChildrenModal: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ selectedChildren: {}, showChildrenModal: false })}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmitChildren}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmitChildren}>
            <Form.Item {...formItemLayout} label='Tiêu đề (*)'>
              <Input
                disabled={childrenModalType === 'Xoá'}
                value={selectedChildren.title}
                onChange={e => this.setState({ selectedChildren: { ...selectedChildren, title: e.target.value } })}
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label='Tiêu đề (Tiếng Anh)'>
              <Input
                disabled={childrenModalType === 'Xoá'}
                value={selectedChildren.engTitle}
                onChange={e => this.setState({ selectedChildren: { ...selectedChildren, engTitle: e.target.value } })}
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label='Liên kết (*)'>
              <Input
                disabled={childrenModalType === 'Xoá'}
                value={selectedChildren.url}
                onChange={e => this.setState({ selectedChildren: { ...selectedChildren, url: e.target.value } })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={`${modalType} trình đơn`}
          visible={showModal}
          centered
          onOk={this.handleSubmit}
          width='50%'
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>Xác nhận</Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('_id')(
              <Input type="hidden" />
            )}
            <Form.Item
              {...formItemLayout}
              label="Tên danh mục"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: true, message: 'Vui lòng nhập tên danh mục!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên danh mục (Tiếng Anh)"
            >
              {getFieldDecorator('engTitle')(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết (URL)"
            >
              {getFieldDecorator('url', {
                rules: [{
                  required: true, message: 'Vui lòng nhập liên kết!',
                }],
              })(
                <Input disabled={modalType === 'Xoá'} />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Danh mục con"
            >
              <Table
                columns={[
                  {
                    title: 'Tên mục con',
                    dataIndex: 'title',
                    key: 'title',
                  }, {
                    title: 'Liên kết',
                    dataIndex: 'url',
                    key: 'url',
                  }, {
                    title: 'Hành động',
                    key: 'action',
                    render: (text, record) => modalType !== 'Xoá' && (
                      <span>
                        <a
                          role='button'
                          onClick={() => this.setState({ showChildrenModal: true, selectedChildren: record, childrenModalType: 'Chỉnh sửa' })}
                        >Sửa</a>
                        <Divider type="vertical" />
                        <a
                          role='button'
                          onClick={() => this.setState({ showChildrenModal: true, selectedChildren: record, childrenModalType: 'Xoá' })}
                        >Xoá</a>
                      </span>
                    ),
                  },
                ]}
                dataSource={children}
                pagination={false}
                size='small'
                bordered={false}
              />
              {modalType !== 'Xoá' && <Button type="dashed" onClick={() => this.setState({ showChildrenModal: true, childrenModalType: 'Tạo mới' })}>
                <Icon type="plus" /> Thêm mục
              </Button>}
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: 'calc(100vh - 230px)' }}
          extra={<div>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
            <Button onClick={() => this.showModal('Tạo mới')} type='primary'>Tạo mới</Button>
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            childrenColumnName='false'
            columns={[{
              title: 'Tên hiển thị',
              dataIndex: 'title',
              key: 'title',
              render: (text, record) => <a href={record.url} target='__blank'>{text}</a>,
              width: 120,
            }, {
              title: 'Liên kết URL',
              dataIndex: 'url',
              key: 'url',
            }, {
              title: 'Danh mục con',
              dataIndex: 'children',
              key: 'children',
              render: (text, record) => record.children && record.children.length > 0 // eslint-disable-line
                ? record.children.map(item => <span key={item.title}>{item.title} ; </span>)
                : <span>Không có</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 120,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleEdit(record)}>Sửa</a>
                  <Divider type="vertical" />
                  <a role='button' onClick={() => this.handleDelete(record)}>Xoá</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(state => ({
  list: state.home.productMenuList,
}), {
  getProductMenuList,
  createProductMenu,
  updateProductMenu,
  deleteProductMenu,
})(Form.create()(ProductMenu));
