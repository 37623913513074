import { ADD_TO_CART, CLEAN_CART, REMOVE_FROM_CART } from '../actions';

const INITIAL_STATE = {
  list: [],
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        list: [
          ...state.list,
          action.payload,
        ],
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        list: state.list.filter((item, idx) => idx !== action.payload),
      };
    case CLEAN_CART:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducers;