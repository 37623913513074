import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  width: 100%;
  padding: 25px 20px;
  background: white;
  margin-bottom: 15px;
  font-size: 14px;
`;

const StorySkeleton = () => (
  <ContentWrapper>
    <div style={{ height: 15, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '100%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '100%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '40%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15, marginBottom: 26 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '70%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 160, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '100%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '100%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15, marginBottom: 12 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '100%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div style={{ height: 15 }}>
      <ContentLoader
        speed={2}
        primaryColor="#f3f3f3"
        style={{ width: '50%', height: '100%' }}
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    </div>
  </ContentWrapper>
);

export default StorySkeleton;
