import {
  GET_DISTRIBUTION_LIST_REQUEST, GET_DISTRIBUTION_LIST_SUCCESS, GET_DISTRIBUTION_LIST_FAILURE,
  CREATE_DISTRIBUTION_SUCCESS,
  UPDATE_DISTRIBUTION_SUCCESS,
  DELETE_DISTRIBUTION_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  requesting: false,
  list: [],
  total: 0,
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DISTRIBUTION_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_DISTRIBUTION_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        list: action.payload.systems,
        total: action.payload.total,
      };
    case GET_DISTRIBUTION_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case CREATE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case UPDATE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => (item._id !== action.payload._id)),
      };
    default:
      return state;
  }
};

export default reducers;