import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_FILE_LIST_REQUEST = 'get-file-list-request';
export const GET_FILE_LIST_SUCCESS = 'get-file-list-success';
export const GET_FILE_LIST_FAILURE = 'get-file-list-failure';
export const UPLOAD_FILE_REQUEST = 'upload-file-request';
export const UPLOAD_FILE_SUCCESS = 'upload-file-success';
export const UPLOAD_FILE_FAILURE = 'upload-file-failure';
export const DELETE_FILE_REQUEST = 'delete-file-request';
export const DELETE_FILE_SUCCESS = 'delete-file-success';
export const DELETE_FILE_FAILURE = 'delete-file-failure';

export function getFileList({ skip, search }) {
  return async dispatch => {
    dispatch({ type: GET_FILE_LIST_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getFileList,
        params: {
          limit: 8,
          skip: skip * 8 || 0,
          search,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_FILE_LIST_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_FILE_LIST_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_FILE_LIST_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function uploadFile(file) {
  return async dispatch => {
    dispatch({ type: UPLOAD_FILE_REQUEST });
    try {
      const data = new FormData();
      data.append('file', file);
      const result = await query({
        method: 'POST',
        endpoint: ENDPOINTS.uploadFile,
        headers: { 'content-type': 'multipart/form-data' },
        data,
      });
      if (result.status === 200) {
        setTimeout(() => {
          dispatch({ type: UPLOAD_FILE_SUCCESS, payload: result.data });
          message.success('Tải lên thành công');
        }, 2000)
      } else {
        dispatch({ type: UPLOAD_FILE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function deleteFile(id) {
  return async dispatch => {
    dispatch({ type: DELETE_FILE_REQUEST });
    try {
      console.log(`delete${id}`);
      const result = await query({
        method: 'DELETE',
        endpoint: ENDPOINTS.deleteFile + id,
      });
      if (result.status === 200) {
        dispatch({ type: DELETE_FILE_SUCCESS, payload: result.data });
        message.success('Xoá tệp thành công');
      } else {
        dispatch({ type: DELETE_FILE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: DELETE_FILE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
