import {
  GET_TOPMENU_LIST_SUCCESS,
  CREATE_TOPMENU_SUCCESS,
  UPDATE_TOPMENU_SUCCESS,
  DELETE_TOPMENU_SUCCESS,
  GET_FOOTERMENU_LIST_SUCCESS,
  CREATE_FOOTERMENU_SUCCESS,
  UPDATE_FOOTERMENU_SUCCESS,
  DELETE_FOOTERMENU_SUCCESS,
  GET_SOCIAL_LINK_LIST_SUCCESS,
  CREATE_SOCIAL_LINK_SUCCESS,
  UPDATE_SOCIAL_LINK_SUCCESS,
  DELETE_SOCIAL_LINK_SUCCESS,
  GET_PRODUCTMENU_LIST_SUCCESS,
  CREATE_PRODUCTMENU_SUCCESS,
  UPDATE_PRODUCTMENU_SUCCESS,
  DELETE_PRODUCTMENU_SUCCESS,
  GET_LOGO_LIST_SUCCESS,
  CREATE_LOGO_SUCCESS,
  UPDATE_LOGO_SUCCESS,
  DELETE_LOGO_SUCCESS,
  GET_REVIEW_LIST_SUCCESS,
  CREATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  GET_YOUTUBE_SUCCESS,
  CREATE_YOUTUBE_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  topMenuList: [],
  footerMenuList: [],
  socialLinks: [],
  productMenuList: [],
  subsidiaryList: [],
  bannerList: [],
  bannerMobileList: [],
  rewardList: [],
  customerList: [],
  reviewList: [],
  youtube: {},
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_YOUTUBE_SUCCESS:
      return {
        ...state,
        youtube: action.payload,
      };
    case CREATE_YOUTUBE_SUCCESS:
      return {
        ...state,
        youtube: action.payload,
      };
    case GET_TOPMENU_LIST_SUCCESS:
      return {
        ...state,
        topMenuList: action.payload.topMenus,
      };
    case CREATE_TOPMENU_SUCCESS:
      return {
        ...state,
        topMenuList: [
          ...state.topMenuList,
          action.payload,
        ],
      };
    case UPDATE_TOPMENU_SUCCESS:
      return {
        ...state,
        topMenuList: state.topMenuList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_TOPMENU_SUCCESS:
      return {
        ...state,
        topMenuList: state.topMenuList.filter(item => item._id !== action.payload._id),
      };
    case GET_SOCIAL_LINK_LIST_SUCCESS:
      return {
        ...state,
        socialLinks: action.payload.socialLinks,
      };
    case CREATE_SOCIAL_LINK_SUCCESS:
      return {
        ...state,
        socialLinks: [
          ...state.socialLinks,
          action.payload,
        ],
      };
    case UPDATE_SOCIAL_LINK_SUCCESS:
      return {
        ...state,
        socialLinks: state.socialLinks.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_SOCIAL_LINK_SUCCESS:
      return {
        ...state,
        socialLinks: state.socialLinks.filter(item => item._id !== action.payload._id),
      };
    case GET_FOOTERMENU_LIST_SUCCESS:
      return {
        ...state,
        footerMenuList: action.payload.footerMenus,
      };
    case CREATE_FOOTERMENU_SUCCESS:
      return {
        ...state,
        footerMenuList: [
          ...state.footerMenuList,
          action.payload,
        ],
      };
    case UPDATE_FOOTERMENU_SUCCESS:
      return {
        ...state,
        footerMenuList: state.footerMenuList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_FOOTERMENU_SUCCESS:
      return {
        ...state,
        footerMenuList: state.footerMenuList.filter(item => item._id !== action.payload._id),
      };
    case GET_PRODUCTMENU_LIST_SUCCESS:
      return {
        ...state,
        productMenuList: action.payload.productMenus,
      };
    case CREATE_PRODUCTMENU_SUCCESS:
      return {
        ...state,
        productMenuList: [
          ...state.productMenuList,
          action.payload,
        ],
      };
    case UPDATE_PRODUCTMENU_SUCCESS:
      return {
        ...state,
        productMenuList: state.productMenuList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_PRODUCTMENU_SUCCESS:
      return {
        ...state,
        productMenuList: state.productMenuList.filter(item => item._id !== action.payload._id),
      };
    case GET_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        reviewList: action.payload.reviews,
      };
    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviewList: [
          ...state.reviewList,
          action.payload,
        ],
      };
    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviewList: state.reviewList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        reviewList: state.reviewList.filter(item => item._id !== action.payload._id),
      };
    case GET_LOGO_LIST_SUCCESS:
      return {
        ...state,
        subsidiaryList: action.payload.logos.filter(item => item.type === 1),
        bannerList: action.payload.logos.filter(item => item.type === 2),
        bannerMobileList: action.payload.logos.filter(item => item.type === 5),
        rewardList: action.payload.logos.filter(item => item.type === 3),
        customerList: action.payload.logos.filter(item => item.type === 4),
      };
    case CREATE_LOGO_SUCCESS:
      return {
        ...state,
        subsidiaryList: action.payload.type === 1 ? [
          ...state.subsidiaryList,
          action.payload,
        ] : state.subsidiaryList,
        bannerList: action.payload.type === 2 ? [
          ...state.bannerList,
          action.payload,
        ] : state.bannerList,
        bannerMobileList: action.payload.type === 2 ? [
          ...state.bannerMobileList,
          action.payload,
        ] : state.bannerMobileList,
        rewardList: action.payload.type === 3 ? [
          ...state.rewardList,
          action.payload,
        ] : state.rewardList,
        customerList: action.payload.type === 4 ? [
          ...state.customerList,
          action.payload,
        ] : state.customerList,
      };
    case UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        subsidiaryList: action.payload.type === 1
          ? state.subsidiaryList.map(item => (item._id === action.payload._id ? action.payload : item))
          : state.subsidiaryList,
        bannerList: action.payload.type === 2
          ? state.bannerList.map(item => (item._id === action.payload._id ? action.payload : item))
          : state.bannerList,
        bannerMobileList: action.payload.type === 2
          ? state.bannerMobileList.map(item => (item._id === action.payload._id ? action.payload : item))
          : state.bannerMobileList,
        rewardList: action.payload.type === 3
          ? state.rewardList.map(item => (item._id === action.payload._id ? action.payload : item))
          : state.rewardList,
        customerList: action.payload.type === 4
          ? state.customerList.map(item => (item._id === action.payload._id ? action.payload : item))
          : state.customerList,
      };
    case DELETE_LOGO_SUCCESS:
      return {
        ...state,
        subsidiaryList: action.payload.type === 1
          ? state.subsidiaryList.filter(item => item._id !== action.payload._id)
          : state.subsidiaryList,
        bannerList: action.payload.type === 2
          ? state.bannerList.filter(item => item._id !== action.payload._id)
          : state.bannerList,
        bannerMobileList: action.payload.type === 2
          ? state.bannerMobileList.filter(item => item._id !== action.payload._id)
          : state.bannerMobileList,
        rewardList: action.payload.type === 3
          ? state.rewardList.filter(item => item._id !== action.payload._id)
          : state.rewardList,
        customerList: action.payload.type === 4
          ? state.customerList.filter(item => item._id !== action.payload._id)
          : state.customerList,
      };
    default:
      return state;
  }
};

export default reducers;